import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const updateSupportType = async (pipeSupportTypeId, type, file) => {
  const formData = new FormData();
  formData.append('info', new Blob([JSON.stringify({type})], {type: 'application/json'}));
  if ( file ) formData.append('assetFile', file);
  
  return await axios.put(`pipe-support-types/${pipeSupportTypeId}`, formData)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateSupportType;