import PropTypes from 'prop-types';

import { Overlay, Wrapper, Title, Content, Actions, Message, Icon } from './styles';

// ----------------------------------------------------------------------

Dialog.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.element
};

// ----------------------------------------------------------------------

export default function Dialog({ title, children, actions, message, type, pt }) {
  return (
    <Overlay>
      <Wrapper>
        <Title>{title}</Title>

        <Content $pt={ pt ? pt : false }>
          { type &&
            <Icon $name={type} />
          }

          { children }
        </Content>

        <Actions $cancel={ actions.props.children.length ? true : false } >
          
          { message &&
            <Message className={message.includes('사용하실 수 있는') ? 'check' : ''}>{ message }</Message>
          }

          <div>
            { actions }
          </div>
        </Actions>

      </Wrapper>
    </Overlay>
  );
}