import { useNavigate } from 'react-router';
//
import { Main, Header, Text, Footer } from './styles';

// ----------------------------------------------------------------------

export default function MainPage() {
  const navigate  = useNavigate();

  return (
    <Main>
      <Header>
        <div />
        <button onClick={() => navigate('/login')}>Login</button>
      </Header>

      <Text>
        배관지지장치의 점검 이력 관리<br />
        <span>최적화된 배관 정비 솔루션 제공</span>
      </Text>

      <Footer>
        사업자등록번호 : 590 87 01857<br />
        대표이사 : 이재빈, 황원식<br />
        주 소 : 충청북도 청주시 흥덕구 직지대로 436번길 76, 1128-1130호 (송정동, 직지스타)<br /><br />
        <span>©STS Engineering Corp. All Rights Reserved.</span>
      </Footer>
    </Main>
  );
}