import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getInspection = (pipeSystemId) => {
  return axios.get(`dashboards/pipe-system/${pipeSystemId}/inspection-tree-list`);
};

const getMeasurement = (pipeSystemId) => {
  return axios.get(`dashboards/pipe-system/${pipeSystemId}/measurement-tree-list`);
};

// ----------------------------------------------------------------------

const getTreeList = async (system) => {
  return await Promise.all([getInspection(system), getMeasurement(system)])
    .then((response) => {
      return {supports: response[0].data.data, points: response[1].data.data};
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getTreeList;