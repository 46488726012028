import { Link } from 'react-router-dom';
// styles
import { Background, Image } from '../styles';

// ----------------------------------------------------------------------

export default function NotFound() {
  return (
    <Background>
      <Image />
      <p>페이지가 없습니다.</p>
      <Link to='/'>메인으로</Link>
    </Background>
  );
}