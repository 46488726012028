import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const deleteSupportType = async (pipeSupportTypeId) => {
  return await axios.delete(`pipe-support-types/${pipeSupportTypeId}`)
    .catch((error) => {
      if ( error.response.data.code === 'ERR-243' ) {
        return Promise.reject(
          new Error(message(error, 'support_type'))
        );
      } else {
        store.dispatch( SET_NOTIFY({
          message: message(error, 'support_type'),
          action: null
        }) );
      }
    });
};

export default deleteSupportType;