import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const updateLine = async (pipeLineId, name) => {
  return await axios.put(`pipe-lines/${pipeLineId}`, {name})
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateLine;