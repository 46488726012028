import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const decode = (disposition) => {
  const fileName = decodeURI(
    disposition
      .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replace(/['"]/g, '')
  );
  return fileName.normalize('NFC');
};

// ----------------------------------------------------------------------

const downloadDrawing = async (pipeSupportId) => {
  return await axios.get(`drawings/pipe-support/${pipeSupportId}/download`, {responseType: 'blob'})
    .then((response) => {
      return {
        url: URL.createObjectURL(response.data),
        name: decode(response.headers['content-disposition'])
      };
    })
    .catch((error) => {
      const status = error.response.status;
      return Promise.reject( new Error( status === 404 ? '404' : '도면을 찾을 수 없습니다.' ) );
    });
};

export default downloadDrawing;