import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getInquiry = async (setData, unitId, type, id, at) => {
  const ordered = at.sort((a, b) => new Date(a.date) - new Date(b.date));
  return await axios.get(`data-inquiry/search/unit/${unitId}/${type}?${type}IdList=${id}`)
    .then((response) => {
      if ( response.data.data.length > 0 ) {
        response.data.data = response.data.data.map((result) => {
          let obj = {};
          for (let i = 0; i < at.length; i++) {
            const data = result[`${type}Results`].filter((item) => item[`${type}At`] === at[i].date);
            if ( type === 'inspection' ) {
              obj[`${at[i].date}_position`] = !data.length ? null : data[0].position;
              obj[`${at[i].date}_remark`] = !data.length ? null : data[0].remark;
            } else {
              obj[`${at[i].date}_xaxis`] = !data.length ? null : data[0].xaxis;
              obj[`${at[i].date}_yaxis`] = !data.length ? null : data[0].yaxis;
              obj[`${at[i].date}_zaxis`] = !data.length ? null : data[0].zaxis;
              obj[`${at[i].date}_remark`] = !data.length ? null : data[0].remark;
            }
          }
          return {...result, ...obj, result: ordered};
        });
      }
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getInquiry;