import axios from '../../utils/axios';
import message from '../../../utils/message';

// ----------------------------------------------------------------------

const checkSupportNumber = async (unitId, number) => {
  return await axios.get(`pipe-supports/unit/${unitId}/number/${number}/exists`)
    .then((response) => {
      return response.data.data.exists;
    })
    .catch((error) => {
      if ( error.response.data.code === 'ERR-251' ) {
        return Promise.reject(
          new Error(message(error, 'support'))
        );
      } else {
        return Promise.reject('나중에 다시 시도해 주세요.');
      }
    });
};

export default checkSupportNumber;