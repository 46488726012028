import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const decode = (disposition) => {
  const fileName = decodeURI(
    disposition
      .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replace(/['"]/g, '')
  );
  return fileName.normalize('NFC');
};

// ----------------------------------------------------------------------

const downloadViewReport = async (reportId) => {
  return await axios.get(`reports/${reportId}/download`, { responseType: 'blob' })
    .then((response) => {
      const url = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = url;
      link.style.display = 'none';

      link.download = decode(response.headers['content-disposition']);

      document.body.appendChild(link);
      link.click();
      link.remove();
      
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'report'),
        action: null
      }) );
    });
};

export default downloadViewReport;