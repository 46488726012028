import { useState, useEffect } from 'react';
// store
import { useSelector } from '../../../store';
// sections
import SearchGroups from '../SearchGroups';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
// @devextreme
import DateBox from 'devextreme-react/date-box';
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, SearchWrapper, Message } from '../../styles';
// apis
import { getLoginLog } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 30, 50];

// ----------------------------------------------------------------------

export default function LoginLogPage() {

  const account = useSelector(state => state.account);
  const admin = account.role === '관리자' ? true : false;

  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate);
  defaultStartDate.setDate( defaultEndDate.getDate() - 7 );

  const [ startDate, setStartDate ] = useState(defaultStartDate);
  const [ endDate, setEndDate ] = useState(defaultEndDate);

  const [ group, setGroup ] = useState({id: '', name: '전체 그룹'});

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ type, setType ] = useState('name');
  const [ keyword, setKeyword ] = useState('');
  
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(30);

  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    ( async () =>
      await getLoginLog(setData, startDate, endDate, search(), page, size)
    )();
    // eslint-disable-next-line
  }, []);

  const search = () => {
    return {
      memberGroupId : admin? group.id : account.memberGroupId,
      name: type==='name'? keyword : '',
      email: type==='email'? keyword : ''
    }
  };

  const handleSearch = () => {
    let difference = Math.abs( startDate.getTime() - endDate.getTime() );
    difference = Math.ceil( difference / (1000 * 60 * 60 * 24) );

    if ( startDate > endDate ) return setMessage('시작일은 종료일 이전이어야 합니다.');
    if ( difference > 90 ) return setMessage('90일 이내로 검색해주세요.');

    setPage(0);
    setMessage(null);
    if ( keyword.length > 0 && keyword.replace(/ /g, '').length === 0 ) return;
    getLoginLog(setData, startDate, endDate, search(), 0, size);
  };

  const handlePage = (value) => {
    setPage(value-1);
    getLoginLog(setData, startDate, endDate, search(), value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getLoginLog(setData, startDate, endDate, search(), 0, Number(event.target.value));
  };

  const cellRender = ({data}) => {
    return data.success ? '성공': '실패'
  };

  return (
    <Container>
      <Content>
        <Breadcrumbs
          heading='로그인 기록'
          links={[
            { name: '사용자 관리' },
            { name: '로그인 기록' }
          ]}
        />

        <Box>
          <SearchWrapper>
            { message &&
              <Message $info $search>{ message }</Message>
            }

            <div>
              { admin && <SearchGroups group={group} setGroup={setGroup} /> }

              <DateBox
                type='date'
                value={startDate}
                onValueChanged={(event) => setStartDate(event.value)}
                displayFormat='yyyy.MM.dd'
                openOnFieldClick={true}
              />
              <DateBox
                type='date'
                value={endDate}
                onValueChanged={(event) => setEndDate(event.value)}
                displayFormat='yyyy.MM.dd'
                openOnFieldClick={true}
              />
              <select defaultValue={type} onChange={(event) => setType(event.target.value)}>
                <option value='name'>이름</option>
                <option value='email'>이메일</option>
              </select>

              <SearchField
                value={keyword}
                onChange={setKeyword}
                onKeyDown={handleSearch}
                onInitialize={handleSearch}
                placeholder='검색어를 입력하세요.'
              />
            </div>
          </SearchWrapper>

          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
          >
            <Column caption='일시' dataField='tryAt' alignment='center' />
            { admin && <Column caption='그룹명' dataField='groupName' alignment='center' /> }
            <Column caption='사용자명' dataField='name' alignment='center' />
            <Column caption='로그인 계정' dataField='requestEmail' alignment='center' />
            <Column caption='IP' dataField='ip' alignment='center' />
            <Column caption='요청' dataField='loginStatus' alignment='center' />
            <Column caption='요청 결과' dataField='success' cellRender={cellRender} alignment='center' />
            <Column caption='비고' dataField='reason' alignment='center' />
          </DataGrid>

          <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

        </Box>
      </Content>
    </Container>
  );
}