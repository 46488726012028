import axios from '../../utils/axios';

import searchDataLists from '../searchDataLists';
import getTreeList from './getTreeList';
import getResult from './getResult';

// ----------------------------------------------------------------------

const getPipeSystem = (unitId) => {
  return axios.get(`dashboards/unit/${unitId}/pipe-system`);
};

// ----------------------------------------------------------------------

const getInitialData = async (unitId, type, startDate, endDate, page) => {
  return await Promise.all([getPipeSystem(unitId), searchDataLists(type, {unitId, startDate, endDate}, page)])
    .then( async (response) => {
      const systems = response[0].data.data;
      const system = systems.length > 0 ? systems[0].pipeSystemId : null;

      const histories = response[1].content;
      response[1].type = type;
      const history = histories.length > 0 ? { id: histories[0][`${type}Id`], name: `${histories[0][`${type}At`]} (${histories[0].pipeStatus})` } : { id: null, name: null };

      let info = { supports: [], points: [] };
      let results = [];

      if ( systems.length > 0 ) {
        await getTreeList(system)
          .then((response) => info = response)
          .catch(() => {});
      }

      if ( history.id ) {
        await getResult(type, history.id)
          .then((response) => results = response)
          .catch(() => {});
      }

      return {
        systems: systems,
        system: system,
        histories: response[1],
        history: history.name,
        info: info,
        results: results
      };
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getInitialData;