// LOGIN
const LOGIN_MESSAGE = {
  'ERR-052': '해당 계정은 비활성화 상태입니다.\n관리자에게 문의해 주세요.',
  'ERR-053': '로그인 가능 횟수를 초과했습니다.\n20분후에 다시 시도해 주세요.',
  'default': '이메일 또는 비밀번호를 다시 한번 확인해 주세요.'
};

// PASSWORD
const PASSWORD_MESSAGE = {
  'ERR-152': '현재 비밀번호를 다시 한번 확인해 주세요.'
};

// ACCOUNT
const ACCOUNT_MESSAGE = {
  'ERR-151': '계정 인증에 실패하였습니다.\n다시 로그인해 주세요.'
};

// DRAWING
const DRAWING_MESSAGE = {
  'ERR-311': '요청하신 도면의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-312': '도면의 파일 이름이 올바르지 않습니다.\n나중에 다시 시도해 주세요.'
};

// REPORT
const REPORT_MESSAGE = {
  'ERR-011': '검색 시작일은 종료일 이전이어야 합니다.',
  'ERR-273': '요청하신 보고서의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-275': '요청하신 보고서의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-283': '최대 검색 기간인 3년 이내로 검색해 주세요.',
  'ERR-277': '보고서의 파일 이름이 올바르지 않습니다.\n나중에 다시 시도해 주세요.'
};

// SUPPORT_INSPECTION
const SUPPORT_INSPECTION_MESSAGE = {
  'ERR-011': '검색 시작일은 종료일 이전이어야 합니다.',
  'ERR-282': '요청하신 배관지지장치 점검 이력의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-283': '최대 검색 기간인 3년 이내로 검색해 주세요.',
  'ERR-284': 'Indicator PIN Type의 점검값 범위는 0 - 10 이내입니다.'
};

// DISPLACEMENT
const DISPLACEMENT_MESSAGE = {
  'ERR-011': '검색 시작일은 종료일 이전이어야 합니다.',
  'ERR-292': '요청하신 변위 측정 이력의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-293': '최대 검색 기간인 3년 이내로 검색해 주세요.'
};

// REPORT_MANAGEMENT
const REPORT_MANAGEMENT_MESSAGE = {
  'ERR-271': '보고서의 확장자를 찾을 수 없습니다.\n다시 한번 확인해 주세요.',
  'ERR-272': '업로드에 일시적인 문제가 발생했습니다.\n잠시 후 다시 시도해 주세요.',
  'ERR-273': '요청하신 보고서의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-274': '일련번호는 9,223,372,036,854,775,807 이내로 입력해 주세요.',
  'ERR-275': '요청하신 보고서의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-277': '보고서의 파일 이름이 올바르지 않습니다.\n다시 한번 확인해 주세요.'
};

// MEMBER
const MEMBER_MESSAGE = {
  'ERR-102': '요청하신 사용자의 사용자 그룹을 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-151': '요청하신 사용자의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-153': '이미 등록되어 있는 이메일입니다.\n다시 한번 확인해 주세요.',
  'ERR-154': '요청하신 사용자와 사용자 그룹이 동일하지 않습니다.\n다시 한번 확인해 주세요.',
  'ERR-155': '사용자 또는 매니저 권한만 설정하실 수 있습니다.\n다시 한번 확인해 주세요.',
  'ERR-156': '회원님의 계정은 직접 삭제하실 수 없습니다.',
  'ERR-157': '접근하실 수 있는 권한이 없습니다.',
  'ERR-158': '회원님의 계정은 직접 수정하실 수 없습니다.',
  'ERR-159': '접근하실 수 있는 권한이 없습니다.',
  'ERR-160': '회원님의 계정은 직접 비활성화하실 수 없습니다.',
  'ERR-161': '접근하실 수 있는 권한이 없습니다.',
  'ERR-162': '회원님의 계정은 직접 활성화하실 수 없습니다.',
  'ERR-163': '접근하실 수 있는 권한이 없습니다.',
  'ERR-164': '회원님의 계정은 직접 차단 해제하실 수 없습니다.',
  'ERR-165': '접근하실 수 있는 권한이 없습니다.',
  'ERR-166': '회원님 계정의 임시 비밀번호는 직접 발급하실 수 없습니다.',
  'ERR-167': '접근하실 수 있는 권한이 없습니다.'
};

// MEMBER_GROUP
const MEMBER_GROUP_MESSAGE = {
  'ERR-101': '이미 등록되어 있는 사용자 그룹명입니다.\n다시 한번 확인해 주세요.',
  'ERR-102': '요청하신 사용자의 그룹의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-103': '회원님의 소속 사용자 그룹은 직접 삭제하실 수 없습니다.'
};

// MEMBER_GROUP_PLANT
const MEMBER_GROUP_PLANT_MESSAGE = {
  'ERR-102': '요청하신 사용자의 그룹의 정보를 찾을 수 없습니다.\n다시 한번 확인해 주세요.',
  'ERR-181': '요청하신 사용자 그룹의 발전소 권한 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-182': '이미 등록되어 있는 사용자 그룹 발전소 권한입니다.\n다시 한번 확인해 주세요.',
  'ERR-202': '이미 등록되어 있는 사용자 그룹 발전소 권한입니다.\n다시 한번 확인해 주세요.'
};

// PLANT
const PLANT_MESSAGE = {
  'ERR-201': '이미 등록되어 있는 발전소입니다.\n다시 한번 확인해 주세요.',
  'ERR-202': '요청하신 발전소의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-211': '이미 등록되어 있는 호기입니다.\n다시 한번 확인해 주세요.'
};

// PIPE
const PIPE_MESSAGE = {
  'ERR-221': '이미 등록되어 있는 배관 시스템 이름입니다.\n다시 한번 확인해 주세요.',
  'ERR-222': '요청하신 배관 시스템의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-231': '이미 등록되어 있는 배관 라인 이름입니다.\n다시 한번 확인해 주세요.',
  'ERR-232': '요청하신 배관 라인의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.'
};

// SUPPORT_TYPE
const SUPPORT_TYPE_MESSAGE = {
  'ERR-251': '일련번호는 9,223,372,036,854,775,807 이내로 입력해 주세요.',
  'ERR-241': '이미 등록되어 있는 배관지지장치 타입입니다.\n다시 한번 확인해 주세요.',
  'ERR-242': '요청하신 배관지지장치 타입의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-243': '현재 배관지지장치에서 사용중인 타입이므로 삭제할 수 없습니다.'
};

// SUPPORT
const SUPPORT_MESSAGE = {
  'ERR-227': '요청하신 배관 시스템의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-233': '요청하신 배관 라인의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-242': '요청하신 배관지지장치 타입의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-251': '일련번호는 9,223,372,036,854,775,807 내에서 입력해 주세요.',
  'ERR-252': '이미 등록되어 있는 배관지지장치 일련번호입니다.\n다시 한번 확인해 주세요.'
};

// DISPLACEMENT_POINT
const DISPLACEMENT_POINT_MESSAGE = {
  'ERR-223': 'unity3d 파일을 선택해 주세요.',
  'ERR-224': '업로드에 일시적인 문제가 발생했습니다.\n잠시 후 다시 시도해 주세요.',
  'ERR-227': '요청하신 배관 시스템의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-233': '요청하신 배관 라인의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-261': '일련번호는 9,223,372,036,854,775,807 내에서 입력해 주세요.',
  'ERR-262': '이미 등록되어 있는 변위 측정 위치 일련번호입니다.\n다시 한번 확인해 주세요.',
  'ERR-263': '요청하신 변위 측정 위치의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-264': '이미 등록된 배관지지장치입니다.'
};

const STATUS_403_MESSAGE = {
  'default': '로그인 유효 시간이 만료되었습니다.\n다시 로그인해 주세요.'
}

// COMMON
const COMMON_MESSAGE = {
  'ERR-001': '요청하신 페이지에 접근하실 수 있는 권한이 없습니다.',
  'ERR-002': '계정 인증에 실패하였습니다.\n다시 로그인해 주세요.',
  'ERR-016': '파일 이름은 100자 이내로 입력해 주세요.',
  'ERR-017': '요청하신 호기에 접근하실 수 있는 권한이 없습니다.',
  'ERR-032': '로그인 유효 시간이 만료되었습니다.\n다시 로그인해 주세요.',
  'ERR-051': '계정 인증에 실패하였습니다.\n다시 로그인해 주세요.',
  'ERR-052': '계정 인증에 실패하였습니다.\n다시 로그인해 주세요.',
  'ERR-055': '계정 인증에 실패하였습니다.\n다시 로그인해 주세요.',
  'ERR-212': '요청하신 호기의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-255': '요청하신 배관지지장치의 정보를 찾을 수 없습니다.\n나중에 다시 시도해 주세요.',
  'ERR-996': '다운로드에 일시적인 문제가 발생했습니다.\n잠시 후 다시 시도해 주세요.',
  'default': '서비스를 이용하는 데 문제가 있습니다.\n문제가 지속되면 나중에 다시 시도해 주세요.'
};

const MESSAGE = {
  'LOGIN': LOGIN_MESSAGE,
  'PASSWORD': PASSWORD_MESSAGE,
  'ACCOUNT': ACCOUNT_MESSAGE,
  'DRAWING': DRAWING_MESSAGE,
  'REPORT': REPORT_MESSAGE,
  'SUPPORT_INSPECTION': SUPPORT_INSPECTION_MESSAGE,
  'DISPLACEMENT': DISPLACEMENT_MESSAGE,
  'REPORT_MANAGEMENT': REPORT_MANAGEMENT_MESSAGE,
  'MEMBER': MEMBER_MESSAGE,
  'MEMBER_GROUP': MEMBER_GROUP_MESSAGE,
  'MEMBER_GROUP_PLANT': MEMBER_GROUP_PLANT_MESSAGE,
  'PLANT': PLANT_MESSAGE,
  'PIPE': PIPE_MESSAGE,
  'SUPPORT_TYPE': SUPPORT_TYPE_MESSAGE,
  'SUPPORT': SUPPORT_MESSAGE,
  'DISPLACEMENT_POINT': DISPLACEMENT_POINT_MESSAGE,
  'COMMON': COMMON_MESSAGE,
  'STATUS_403': STATUS_403_MESSAGE,
};

// ----------------------------------------------------------------------

const message = (error, request) => {
  const code = error.response?.data.code;
  const type = request ? request : 'common';
  const answer = MESSAGE[type.toUpperCase()];
  const codes = Object.keys(answer);

  return codes.includes(code) ? answer[code] : codes.includes('default') ? answer.default : MESSAGE.COMMON.default;
};

export default message;