import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// store
import { useSelector } from '../../../store';
// components
import SearchField from '../../../components/search-field';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { TreeWrapper, DrawingIcon, MoreButton, ScrollBar } from '../../styles';
// apis
import { getDrawingList, downloadDrawing } from '../../../apis';

// ----------------------------------------------------------------------

export default function TreeList({ setDrawing, active, setActive }) {

  const location = useLocation();
  const unit = useSelector(state => state.unit);

  const [ state, setState ] = useState(location.state);

  const [ original, setOriginal ] = useState({ content: [], number: 0, last: true, empty: true });
  const [ data, setData ] = useState({ content: [], number: 0, last: true, empty: true });
  const { content: rows } = data;

  const [ markNumber, setMarkNumber ] = useState('');

  const [ selected, setSelected ] = useState(null);
  const download = useRef({});

  useEffect(() => {
    if ( unit ) {
      setMarkNumber('');
      handleInitializeEvents();
      setState(null);
    }

    return () => {
      const revoke = Object.entries(download.current);
      if ( revoke.length > 0 ) {
        revoke.forEach((item) => URL.revokeObjectURL(item[1].url));
        download.current = {};
      }
    };
    // eslint-disable-next-line
  }, [unit]);

  const handleSearch = async (more) => {
    if ( markNumber.length > 0 && markNumber.replace(/ /g, '').length === 0 ) return;
    await getDrawingList(unit, markNumber, more? data.number + 1 : 0)
      .then((response) => {
        if ( !response.first ) {
          const array = [ ...data.content, ...response.content ];
          response.content = array;
          setOriginal(response);
        }
        setData(response);
      })
      .catch(() => {
        setData({ content: [], number: 0, last: true, empty: true });
        setOriginal({ content: [], number: 0, last: true, empty: true });
      });
  };

  const handleInitialize = () => setData(original);

  const handleAction = async (id, drawing) => {
    const downloadArray = Object.keys(download.current);
    if ( !downloadArray.includes(String(id)) && drawing ) {
      await downloadDrawing(id)
        .then((response) => {
          setDrawing({
            data: rows.filter((item) => item.pipeSupportId === id)[0],
            download: response
          });
          download.current = { ...download.current, [id]: response };
        })
        .catch(({message}) => {
          if (message === '404') return handleInitializeEvents();
          setDrawing({
            data: rows.filter((item) => item.pipeSupportId === id)[0],
            download: null
          });
        });
    } else if ( downloadArray.includes(String(id)) ) {
      setDrawing({
        data: rows.filter((item) => item.pipeSupportId === id)[0],
        download: download.current[id]
      });
    } else {
      setDrawing({
        data: rows.filter((item) => item.pipeSupportId === id)[0],
        download: null
      });
    }
  };

  const handleInitializeEvents = async () => {
    await getDrawingList(unit, '', 0)
      .then(async (response) => {
        setOriginal(response);
        setData(response);
        if ( response.content.length > 0 ) {
          const content = state ? response.content.find((item) => item.pipeSupportId === state.id) : response.content[0];
          setSelected(state ? state.id : content.pipeSupportId);
          if ( content.drawingFile ) {
            await downloadDrawing(content.pipeSupportId)
              .then((info) => {
                setDrawing({
                  data: content,
                  download: info
                });
                download.current = { [content.pipeSupportId]: info };
              })
              .catch(() => {
                setDrawing({
                  data: content,
                  download: null
                });
              });
          } else {
            setDrawing({
              data: content,
              download: null
            });
          }
        }
      })
      .catch(() => {
        setOriginal({ content: [], number: 0, last: true, empty: true });
        setData({ content: [], number: 0, last: true, empty: true });
      })
  };

  const handleDownload = async (id) => {
    const downloadArray = Object.keys(download.current);
    let file = {url: null, name: null};
    if ( !downloadArray.includes(String(id)) ) {
      await downloadDrawing(id)
        .then((response) => {
          download.current = { ...download.current, [id]: response };
          file = response;
        })
        .catch(({message}) => {
          if (message === '404') return handleInitializeEvents();
        });
    } else if ( downloadArray.includes(String(id)) ) {
      file = download.current[id];
    }

    if ( file.url ) {
      const link = document.createElement('a');
      link.href = file.url;
      link.style.display = 'none';
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(file.url);
    }
  };

  return (
    <TreeWrapper>

      <ScrollBar>
        <div>
          <SearchField
            value={markNumber}
            onChange={setMarkNumber}
            onKeyDown={handleSearch}
            onInitialize={handleInitialize}
            disabled={ unit ? false : true }
            placeholder='Mark Number를 입력하세요.'
          />

          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
            dataRowRender={({data}) => <TableRow row={data} selected={selected} setSelected={setSelected} handleAction={handleAction} handleDownload={handleDownload} />}
          >
            <Column caption='No.' dataField='number' />
            <Column caption='SYSTEM' dataField='pipeSystemName' />
            <Column caption='MARK NUMBER' dataField='markNumber' />
            <Column />
          </DataGrid>

          { !data.last &&
            <MoreButton>
              <button disabled={unit ? false : true} onClick={() => handleSearch(true)}>더보기</button>
            </MoreButton>
          }
        </div>
      </ScrollBar>
      
      <div>
        <button onClick={() => setActive(!active)} />
      </div>
      
    </TreeWrapper>
  );
}

function TableRow({ row, selected, setSelected, handleAction, handleDownload }) {
  return (
    <tr
      className={selected === row.pipeSupportId ? 'active' : ''}
      onClick={() => setSelected(row.pipeSupportId)}
      onDoubleClick={() => handleAction(row.pipeSupportId, row.drawingFile)}
    >
      <td>{row.number}</td>
      <td>{row.pipeSystemName}</td>
      <td>{row.markNumber}</td>
      <td>
        {row.drawingFile &&
          <DrawingIcon onClick={() => handleDownload(row.pipeSupportId)} />
        }
      </td>
    </tr>
  );
}