import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// components
import SearchField from '../../components/search-field';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// apis
import { searchMemberGroups } from '../../apis';

// ----------------------------------------------------------------------

export default function SearchGroups({ group, setGroup }){

  const form  = useLocation().pathname === '/member/create';

  const [ visible, setVisible ] = useState(false);

  const [ original, setOriginal ] = useState({ content: [], number: 0, last: true });
  const [ groups, setGroups ] = useState({ content: [], number: 0, last: true });
  const [ searchGroup, setSearchGroup ] = useState('');

  useEffect(() => {
    searchMemberGroups(searchGroup, 0)
      .then((response) => {
        setOriginal(response);
        setGroups(response);
      })
      .catch(() => {
        setOriginal({ content: [], number: 0, last: true });
        setGroups({ content: [], number: 0, last: true });
      })
    // eslint-disable-next-line
  }, []);

  const handleSearchGroups = (more) => {
    searchMemberGroups(searchGroup, more? groups.number + 1 : 0)
      .then((response) => {
        if ( !response.first ) {
          const memberGroups = [ ...groups.content, ...response.content ];
          response.content = memberGroups;
          setOriginal(response);
        }
        setGroups(response);
      })
      .catch(() => {
        setGroups({ content: [], number: 0, last: true });
      });
  };

  const handleInitialize = () => setGroups(original);
  
  return (
    <>
      <button id='groups' onClick={() => setVisible(true)} className='selected' type='button' >{ group.name }</button>

      <Popover
        target='#groups'
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        wrapperAttr={{ class: 'search-popover' }}
      >
        <div>
          <SearchField
            value={searchGroup}
            onChange={setSearchGroup}
            onKeyDown={handleSearchGroups}
            onInitialize={handleInitialize}
          />
        </div>

        { !form &&
          <button
            onClick={() => {
              setVisible(false);
              setGroup({id: '', name: '전체 그룹'});
            }}
          >
            전체 그룹
          </button>
        }
        { groups.content.map((item) => (
          <button
            key={item.id}
            onClick={() => {
              setVisible(false);
              setGroup({id: item.id, name: item.name});
            }}
          >
            {item.name}
          </button>
        )) }
        { !groups.last && <button onClick={() => handleSearchGroups(true)}>더보기</button>}
      </Popover>
    </>
  );
}