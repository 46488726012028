import { useState, useEffect } from 'react';
// components
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// apis
import { getPointSupport } from '../../../apis';

// ----------------------------------------------------------------------

export default function SearchSupports({ unitId, state, setState, type, system, line, disabled, support, setSupport }){

  const [ visible, setVisible ] = useState(false);

  const [ supports, setSupports ] = useState({ content: [], totalPages: 0, empty: true });
  const { content: rows, totalPages: pages } = supports;

  const [ markNumber, setMarkNumber ] = useState('');
  const [ page, setPage ] = useState(0);

  useEffect(() => {
    if ( line ) {
      setMarkNumber('');
      setPage(0);
      ( async () =>
        await getPointSupport(setSupports, unitId, system, line, '', 0)
          .catch(() => {
            setSupports({ content: [], totalPages: 0, empty: true });
          })
      )();
    }
    // eslint-disable-next-line
  }, [line]);

  useEffect(() => {
    setSupport({
      id: state?.pipeSupportId ? state.pipeSupportId : type === '등록' ? null : 0,
      markNumber: state?.markNumber ? state.markNumber : type === '등록' ? '배관지지장치를 선택해주세요.' : '선택 없음'
    });
    if ( state ) setState({ ...state, pipeSupportId: null });
    // eslint-disable-next-line
  }, [rows]);

  const handlePage = (value) => {
    setPage(value-1);
    getPointSupport(setSupports, unitId, system, line, markNumber, value-1);
  };

  const handleSearch = async () => {
    setPage(0);
    if ( markNumber.length > 0 && markNumber.replace(/ /g, '').length === 0 ) return;
    await getPointSupport(setSupports, unitId, system, line, markNumber, 0);
  };

  return (
    <>
      <p style={{marginBottom: 0, paddingBottom: '3px', fontSize: '13.5px'}}>Support</p>
      <button id='support' className='selected' disabled={disabled} onClick={() => setVisible(true)} type='button' >
        { support.markNumber && support.markNumber }
      </button>
      <Popover
        target='#support'
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        wrapperAttr={{ class: 'search-popover paging' }}
      >
        <div>
          <SearchField
            value={markNumber}
            onChange={setMarkNumber}
            onKeyDown={handleSearch}
            onInitialize={handleSearch}
          />
        </div>

        { type === '수정' &&
          <button
            onClick={() => {
              setVisible(false);
              setSupport({id: 0, markNumber: '선택없음' });
            }}
          >
            선택없음
          </button>
        }
        { supports.empty ? (
          <p>목록이 없습니다.</p>
        ) : (
          <>
            { rows.map((item) => (
              <button
                key={item.pipeSupportId}
                onClick={() => {
                  setVisible(false);
                  setSupport({id: item.pipeSupportId, markNumber: item.markNumber });
                }}
              >
                {item.number}. {item.markNumber}
              </button>
            )) }
          </>
        )}

        <Pagination pages={pages} page={page} handlePage={handlePage} />
      </Popover>
    </>
  );
}