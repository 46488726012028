import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import { loggedin, token, account, unit, notify, loading } from './slices';

// ----------------------------------------------------------------------

export { useSelector } from 'react-redux';

export { SET_LOGGEDIN, SET_TOKEN, DELETE_TOKEN, SET_ACCOUNT, DELETE_ACCOUNT, SET_UNIT, DELETE_UNIT, SET_NOTIFY, DELETE_NOTIFY, SET_LOADING, DELETE_LOADING } from './slices';

// ----------------------------------------------------------------------

const config = { key: 'root', storage, whitelist: ['loggedin'] };

const reducer = combineReducers({
  loggedin: loggedin.reducer,
  token: token.reducer,
  account: account.reducer,
  unit: unit.reducer,
  notify: notify.reducer,
  loading: loading.reducer
});

const store = configureStore({
  reducer: persistReducer(config, reducer),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

// ----------------------------------------------------------------------

export const persistor = persistStore(store);

export default store;