import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getRegisteredPlant = async (setData, memberGroupId, name, page, size) => {
  return await axios.get(`member-group-plants/search-page/${memberGroupId}/registered?name=${encodeURIComponent(name)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getRegisteredPlant;