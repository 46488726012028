import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const createMember = async (memberGroupId, email, password, role, name, affiliation) => {
  return await axios.post('members', {memberGroupId, email, password, role, name, affiliation})
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'member'),
        action: null
      }) );
    });
};

export default createMember;