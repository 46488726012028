import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const getDisplacementResult = async (setData, setResults, displacementMeasurementId) => {
  return await axios.get(`displacement-measurement-result-managements/search/measurement/${displacementMeasurementId}`)
    .then((response) => {
      setData(response.data.data);
      if ( response.data.data.length > 0 ) {
        const obj = response.data.data.reduce((accumulator, value) => {
          return {...accumulator, [value.displacementPointId]: {xAxis: value.xaxis, yAxis: value.yaxis, zAxis: value.zaxis, remark: value.remark}};
        }, {});
        setResults(obj);
      }
    })
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'displacement'),
        action: -1
      }) );
    });
};

export default getDisplacementResult;