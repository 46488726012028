import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// sections
import ResultTableRow from './ResultTableRow';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, ResultTop, ResultBottom } from '../../styles';
// apis
import { getSupportInspectionResult, createSupportInspectionResult } from '../../../apis';

// ----------------------------------------------------------------------

export default function SupportInspectionResultPage() {

  const state = useLocation().state;
  const inspectionId = state?.pipeSupportInspectionId;
  const text = state?.type === 'create' ? '등록' : '수정';
  const navigate  = useNavigate();

  const [ rows, setRows ] = useState([]);
  const [ results, setResults ] = useState({});

  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    if ( !state ) return navigate(-1);
    ( async () =>
      await getSupportInspectionResult(setRows, setResults, inspectionId)
    )();
    // eslint-disable-next-line
  }, [state]);

  const handleCreate = async () => {
    const result = Object.entries(results);
    const inspectionResults = result.map((item) => {
      return {pipeSupportId: item[0], position: item[1].position, remark: item[1].remark}
    });
    await createSupportInspectionResult(inspectionId, inspectionResults)
      .then(() => navigate(-1));
  };

  return (
    state &&
      <Container>
        <Content>

          <Breadcrumbs
            heading='배관지지장치 점검 결과'
            links={[
              { name: '데이터 관리' },
              { name: '배관지지장치 점검', link: '/data/support-inspection' },
              { name: '배관지지장치 점검 결과' }
            ]}
          />

          <Box $admin>
            <ResultTop>
              <p>{`${state.inspectionCompany} - ${state.pipeStatus} (${state.inspectionAt})`}</p>
            </ResultTop>
    
            <DataGrid
              dataSource={rows}
              noDataText='배관지지장치를 등록해 주세요.'
              columnAutoWidth={true}
              paging={{enabled: false}}
              sorting={{mode: 'none'}}
              dataRowRender={({data}) => <ResultTableRow row={data} results={results} setResults={setResults} />}
            >
              <Column caption='No.' dataField='number' alignment='center' />
              <Column caption='System' dataField='pipeSystemName' alignment='center' />
              <Column caption='Line' dataField='pipeLineName' alignment='center' />
              <Column caption='Mark Number' dataField='markNumber' alignment='center' />
              <Column caption='Model Size' dataField='modelSize' alignment='center' />
              <Column caption='Design Hot Load' dataField='designHotLoad' alignment='center' />
              <Column caption='Design Cold Load' dataField='designColdLoad' alignment='center' />
              <Column caption='Total Travel' dataField='totalTravel' alignment='center' />
              <Column caption='Hot Position' dataField='hotPosition' alignment='center' />
              <Column caption='Cold Position' dataField='coldPosition' alignment='center' />
              <Column caption='Movement' dataField='movement' alignment='center' />
              <Column caption='Indicator Type' dataField='indicatorType' alignment='center' />
              <Column caption='Position' dataField='positionResult' alignment='center' />
              <Column caption='Remark' dataField='remark' alignment='center' />
            </DataGrid>
    
            <ResultBottom>
              <button disabled={inspectionId ? false : true} onClick={() => setVisible(true)}>적용</button>
              <button onClick={() => navigate(-1)}>취소</button>
            </ResultBottom>

            { visible &&
              <Dialog
                actions={
                  <>
                    <button onClick={() => handleCreate()}>
                      { text }
                    </button>
                    <button onClick={() => setVisible(false)}>
                      취소
                    </button>
                  </>
                }
              >
                <p>점검 결과를<br />{ text }하시겠습니까?</p>
              </Dialog>
            }
          </Box>
        </Content>
      </Container>
  );
}