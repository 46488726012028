import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const checkLineName = async (pipeSystemId, name) => {
  return await axios.get(`pipe-lines/pipe-system/${pipeSystemId}/name/exists?name=${encodeURIComponent(name)}`)
    .then((response) => {
      return response.data.data.exists;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default checkLineName;