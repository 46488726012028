import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const updateReport = async (reportId, title, file) => {
  const formData = new FormData();
  formData.append('info', new Blob([JSON.stringify({title})], {type: 'application/json'}));
  if ( file ) formData.append('reportFile', file);
  
  return await axios.put(`report-managements/${reportId}`, formData)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateReport;