import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getInitializeAnalysisResult = async (setResult, type, id, startDate, endDate) => {
  
  startDate.setDate(1);
  const start = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
  const last = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
  const end = new Date(last.getTime() - (last.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

  const request = type === 'inspection' ?
    `data-analyses/initialize/pipe-support/${id}/inspection?start=${start}&end=${end}`
    : `data-analyses/initialize/displacement-point/${id}/measurement?start=${start}&end=${end}`;

  return await axios.get(request)
    .then((response) => {
      setResult(response.data.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getInitializeAnalysisResult;