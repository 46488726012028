import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { Wrapper, Label, Input, Message, Icon } from './styles';

// ----------------------------------------------------------------------

TextField.propTypes = {
  name: PropTypes.string, 
  control: PropTypes.object,
  error: PropTypes.bool
};

// ----------------------------------------------------------------------

export default function TextField({ name, control, label, $exception, icon, ...props }) {
  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control
  });

  let messages = false;
  if ( error && !$exception && error.type !== 'required' && error.type !== 'typeError' ) messages = true;

  return (
    <Wrapper>
      { label &&
        <Label>{ label }</Label>
      }
      <Input
        id={name}
        // 예외의 경우 미입력 시에만 에러 설정.
        $error={ error ? $exception && error.type !== 'required' ? false : true : false }
      >
        { icon &&
          <Icon $icon={icon} />
        }
        <input {...field} {...props} />
      </Input>
      
      { messages &&
        <Message>{error.message}</Message>
      }
    </Wrapper>
  );
};