import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const updatePoint = async (displacementPointId, fields) => {
  return await axios.put(`displacement-points/${displacementPointId}`, fields)
    .catch((error) => {
      if ( error.response.data.code === 'ERR-264' ) {
        return Promise.reject(
          new Error(message(error, 'displacement_point'))
        );
      } else {
        store.dispatch( SET_NOTIFY({
          message: message(error, 'support'),
          action: null
        }) );
      }
    });
};

export default updatePoint;