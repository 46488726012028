import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { Wrapper } from './styles';

// ----------------------------------------------------------------------

SearchField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onInitialize: PropTypes.func
};

// ----------------------------------------------------------------------

export default function SearchField({ value, onChange, onKeyDown, onInitialize, ...props }) {

  const [ searched, setSearched ] = useState(null);

  const [ changed, setChanged ] = useState(null);
  const [ events, setEvents ] = useState(null);

  useEffect(() => {
    if ( value.length > 0 && value.replace(/ /g, '').length !== 0 ) return setEvents(true);
    setEvents(null);
  }, [value]);

  useEffect(() => {
    if ( !events && searched ) {
      onInitialize();
      setSearched(null);
    }
    // eslint-disable-next-line
  }, [events]);

  return (
    <Wrapper>
      <input
        type='text'
        value={value}
        onChange={(event) => {
          if ( !changed ) setChanged(true);
          onChange(event.target.value);
        }}
        onKeyDown={(event) => {
          if(event.key === 'Enter') {
            if ( value.length > 0 && value.replace(/ /g, '').length === 0 ) return;
            onKeyDown();
            if ( value.length === 0 ) return setSearched(null);
            setSearched(true);
          }
        }}
        {...props}
      />
      { value.length > 0 &&
        <button className='delete' onClick={() => onChange('')} />
      }
    </Wrapper>
  );
};