import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//components
import TextField from '../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { REGEXP_EMAIL, REGEXP_PASSWORD  } from '../../utils/regexp';
// styles
import { Container, Box, Logo, Message } from '../styles';
// apis
import { login } from '../../apis';

// ----------------------------------------------------------------------

const schema = yup.object().shape({
  email: yup.string()
    .required()
    .matches(REGEXP_EMAIL),
  password: yup.string()
    .required()
    .min(8)
    .max(16)
    .matches(REGEXP_PASSWORD)
});

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate  = useNavigate();

  const {
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const [ message, setMessage ] = useState(null);

  const onError = (error) => {
    if ( error.email?.type !== 'required' || error.password?.type !== 'required' ) setMessage('이메일 또는 비밀번호를 다시 한번 확인해 주세요.');
  };

  const onSubmit = async (data) => {
    await login(data.email, data.password)
      .then((response) => {
        const { passwordExpiration, tempPassword } = response;
        if ( passwordExpiration || tempPassword ) {
          return navigate('/change-password',
            { state: {
              expiration: passwordExpiration,
              temp: tempPassword
            }}
          )
        } else {
          return navigate('/dashboard', {state: {submit: true}});
        }
      })
      .catch((error) => setMessage(error.message));
  };

  return (
    <Container $align>
      <Logo />
      <Box $mb>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <TextField
            name='email'
            type='text'
            control={control}
            autoComplete='email'
            icon='email'
            placeholder='이메일을 입력해 주세요.'
            $exception
          />

          <TextField
            name='password'
            type='password'
            control={control}
            autoComplete='new-password'
            icon='pw'
            placeholder='비밀번호를 입력해 주세요.'
            $exception
          />

          { message &&
            <Message>{message}</Message>
          }

          <button type='submit'>로그인</button>
        </form>
      </Box>
    </Container>
  );
}