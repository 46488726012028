import axios from '../utils/axios';

// ----------------------------------------------------------------------

const searchPlants = async (name, page) => {
  return await axios.get(`plants/search-slice?name=${encodeURIComponent(name)}&page=${page}`)
    .then((response) => {
      return response.data.data;
    })
    .catch(() => {});
};

export default searchPlants;