import { useState, useEffect } from 'react';
// sections
import SearchPlants from '../SearchPlants';
import TableRow from './TableRow';
import PointForm from './PointForm';
import ModelForm from './ModelForm';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, SearchWrapper } from '../../styles';
// apis
import { getDisplacementPoint, createPoint, updatePoint, createPointAsset, deletePoint } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 30, 50];

// ----------------------------------------------------------------------

export default function DisplacementPointPage() {

  const [ unit, setUnit ] = useState({id: null, name: ''});

  const [ disabled, setDisabled ] = useState(true);

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ type, setType ] = useState('system');
  const [ keyword, setKeyword ] = useState('');
  
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(30);

  const [ dialog, setDialog ] = useState({ visible: false });
  const [ pointForm, setPointForm ] = useState({ visible: false });
  const [ modelForm, setModelForm ] = useState(false);

  const search = (reset) => {
    if ( reset ) {
      return { system: '', line: '', markNumber: '' };
    } else {
      return {
        system: type==='system'? keyword : '',
        line: type==='line'? keyword : '',
        markNumber: type==='markNumber'? keyword : ''
      };
    }
  };

  useEffect(() => {
    if ( unit.id ) {
      setPage(0);
      setKeyword('');
      setDisabled(false);
      ( async () =>
        await getDisplacementPoint(setData, unit.id, search(true), 0, size)
      )();
    } else {
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [unit]);

  const handleSearch = async () => {
    setPage(0);
    if ( keyword.length > 0 && keyword.replace(/ /g, '').length === 0 ) return;
    await getDisplacementPoint(setData, unit.id, search(), 0, size);
  };

  const handlePage = (value) => {
    setPage(value-1);
    getDisplacementPoint(setData, unit.id, search(), value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getDisplacementPoint(setData, unit.id, search(), 0, Number(event.target.value));
  };

  const handleAction = async (fields) => {
    if ( pointForm.type === '등록' ) {
      await createPoint(fields)
        .then(() => {
          setPointForm({ visible: false });
          handleReset()
        });
    } else {
      await updatePoint(pointForm.state.displacementPointId, fields)
        .then(() => {
          setPointForm({ visible: false });
          handleReset()
        });
    }
  };

  const handleModel = async (file) => {
    await createPointAsset(file)
      .then(() => {
        setModelForm(false);
        handleReset()
      });
  };

  const handleDelete = async () => {
    await deletePoint(dialog.id)
      .then(() => {
        setDialog({ visible: false });
        handleReset()
      });
  };

  const handleReset = () => {
    setKeyword('');
    setPage(0);
    getDisplacementPoint(setData, unit.id, search(), 0, size);
  };

  return (
    <Container>
      <Content>
        <Breadcrumbs
          heading='변위 측정 위치 관리'
          links={[
            { name: '시스템 관리' },
            { name: '변위 측정 위치 관리' }
          ]}
        />

        <Box $admin>

          <SearchWrapper>
            <div>
              <SearchPlants unit={unit} setUnit={setUnit} />

              <select disabled={disabled} defaultValue={type} onChange={(event) => setType(event.target.value)}>
                <option value='system'>배관 시스템</option>
                <option value='line'>배관 라인</option>
                <option value='markNumber'>배관지지장치 번호</option>
              </select>
              <SearchField
                value={keyword}
                onChange={setKeyword}
                onKeyDown={handleSearch}
                onInitialize={handleSearch}
                disabled={disabled}
                placeholder='검색어를 입력하세요.'
              />
            </div>
              
            <div>
              <button
                className='register'
                disabled={disabled}
                onClick={() => setModelForm(true)}
              >
                모델 등록
              </button>
              <button
                className='register'
                disabled={disabled}
                onClick={() => setPointForm({visible: true, type: '등록'})}
              >
                위치 등록
              </button>
            </div>
          </SearchWrapper>

          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
          >
            <Column caption='NO.' dataField='number' alignment='center' />
            <Column caption='System' dataField='pipeSystemName' alignment='center' />
            <Column caption='Line' dataField='pipeLineName' alignment='center' />
            <Column caption='Mark Number' dataField='markNumber' alignment='center' />
            <Column caption='X-Axis Design' dataField='xaxisDesign' alignment='center' />
            <Column caption='Y-Axis Design' dataField='yaxisDesign' alignment='center' />
            <Column caption='Z-Axis Design' dataField='zaxisDesign' alignment='center' />
            <Column caption='등록일' dataField='createdAt' alignment='center' />
            <Column caption='' dataField='displacementPointId' allowSorting={false}
              cellRender={({data}) => <TableRow row={data} setDialog={setDialog} setPointForm={setPointForm} />}
            />
          </DataGrid>

          <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

          { modelForm &&
            <ModelForm setOpen={setModelForm} handleAction={handleModel} />
          }

          { pointForm.visible &&
            <PointForm form={pointForm} setOpen={setPointForm} unit={unit} handleAction={handleAction} />
          }

          { dialog.visible &&
            <Dialog
              title={`${dialog.type === '삭제' ? '변위 측정 위치 삭제' : '상세 보기'}`}
              actions={
                <>
                  { dialog.type === '삭제' &&
                    <button onClick={() => handleDelete()}>
                      삭제
                    </button>
                  }
                  <button onClick={() => setDialog({visible: false})}>
                    {dialog.type === '삭제' ? '취소' : '확인'}
                  </button>
                </>
              }
            >
              { dialog.type === '삭제' ? (
                <p>선택하신 변위 측정 위치를 삭제하시겠습니까?</p>
              ) : (
                <p>
                  NO. : {dialog.info.number}<br />
                  System : {dialog.info.pipeSystemName}<br />
                  Line : {dialog.info.pipeLineName}<br />
                  Mark Number : {dialog.info.markNumber}<br />
                  X-Axis Design : {dialog.info.xaxisDesign}<br />
                  Y-Axis Design : {dialog.info.yaxisDesign}<br />
                  Z-Axis Design : {dialog.info.zaxisDesign}<br />
                  등록일 : {dialog.info.createdAt}<br />
                  X 좌표 : {dialog.info.xcoordinate}<br />
                  Y 좌표 : {dialog.info.ycoordinate}<br />
                  Z 좌표 : {dialog.info.zcoordinate}<br />
                  X 회전 : {dialog.info.xrotation}<br />
                  Y 회전 : {dialog.info.yrotation}<br />
                  Z 회전 : {dialog.info.zrotation}
                </p>
              )}
            </Dialog>
          }

        </Box>
      </Content>
    </Container>
  );
}