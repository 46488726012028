import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const deleteReport = async (reportId) => {
  return await axios.delete(`report-managements/${reportId}`)
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'report_management'),
        action: null
      }) );
    });
};

export default deleteReport;