import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getPointAssetInfo = async (setAsset) => {
  return await axios.get('displacement-points/asset-info')
    .then((response) => {
      if ( response.data.status === 'success' && response.data.data?.file )setAsset(response.data.data);
    })
    .catch(() => {});
};

export default getPointAssetInfo;