import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const checkSystemName = async (unitId, name) => {
  return await axios.get(`pipe-systems/unit/${unitId}/name/exists?name=${encodeURIComponent(name)}`)
    .then((response) => {
      return response.data.data.exists;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default checkSystemName;