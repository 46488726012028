import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// store
import { useSelector } from '../../../store';
// sections
import SearchGroups from '../SearchGroups';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import TextField from '../../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { REGEXP_EMAIL, REGEXP_PASSWORD  } from '../../../utils/regexp';
// styles
import { Container, Content, Box, Form, Check, Message } from '../../styles';
// apis
import { checkEmail, createMember, updateMember } from '../../../apis';


// ----------------------------------------------------------------------

const roles = { '사용자': 'MEMBER', '매니저': 'MANAGER', '마스터': 'MASTER', '관리자': 'ADMIN' };

const schema = yup.object().shape({
  email: yup.string()
    .required()
    .max(50, '50자 이내로 입력해주세요.')
    .matches(REGEXP_EMAIL, '이메일 형식을 확인해 주세요.'),
  password: yup.string()
    .required()
    .min(8, '8 - 16자 이내로 입력해주세요.')
    .max(16, '8 - 16자 이내로 입력해주세요.')
    .matches(REGEXP_PASSWORD, '비밀번호 형식을 확인해 주세요.'),
  confirm: yup.string()
    .required()
    .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다.'),
  name: yup.string()
    .required()
    .max(20, '20자 이내로 입력해주세요.'),
  affiliation: yup.string()
    .required()
    .max(30, '30자 이내로 입력해주세요.')
});

const updateSchema = yup.object().shape({
  email: yup.string()
    .required()
    .max(50, '50자 이내로 입력해주세요.')
    .matches(REGEXP_EMAIL, '이메일 형식을 확인해 주세요.'),
  name: yup.string()
    .required()
    .max(20, '20자 이내로 입력해주세요.'),
  affiliation: yup.string()
    .required()
    .max(30, '30자 이내로 입력해주세요.')
});

// ----------------------------------------------------------------------

export default function MemberFormPage() {
  const navigate  = useNavigate();

  const create = useLocation().pathname.split('/')[3] === 'create' ? true : false;
  const text = create ? '등록' : '수정';
  const disabled = create ? false : true;
  const state = useLocation().state;

  const account = useSelector(state => state.account);
  const admin = account.role === '관리자' ? true : false;

  const {
    control,
    getValues,
    setError,
    clearErrors,
    handleSubmit
  } = useForm({
    resolver: yupResolver(create ? schema: updateSchema),
    defaultValues: {
      email: state?.email || '',
      password: '',
      confirm: '',
      name: state?.name || '',
      affiliation: state?.affiliation || ''
    }
  });

  useEffect(() => {
    if ( !create && !state ) navigate(-1);
    // eslint-disable-next-line
  }, [create]);
  
  const [ group, setGroup ] = useState( state ? {name: state.groupName} : {id: account.memberGroupId, name: account.memberGroupName} );
  const [ role, setRole ] = useState( state ? roles[state.role] : 'MEMBER' );

  const [ checked, setChecked ] = useState(null);
  const [ checkedEmail, setCheckedEmail ] = useState(null);

  const [ message, setMessage ] = useState(null);

  const handleRole = (event) => setRole(event.target.value);

  const handleChecked = async () => {
    const value = getValues('email');
    if ( value === '' ) {
      setError('email', {type: 'required'});
      setMessage(null);
    } else if ( !REGEXP_EMAIL.test(value) ) {
      setError('email', {type: 'matches', message: '이메일 형식을 확인해 주세요.'});
      setMessage(null);
    } else if ( value.length > 50 ) {
      setError('email', {type: 'max', message: '50자 이내로 입력해주세요.'});
      setMessage(null);
    } else {
      clearErrors('email');
      await checkEmail(value)
        .then((response) => {
          setChecked(response);
          setCheckedEmail(value);
          setMessage(response ? '이미 등록된 이메일입니다.' : '사용하실 수 있는 이메일입니다.');
        })
        .catch(() => {
          setMessage('나중에 다시 시도해 주세요.');
        });
    }
  };
  
  const onSubmit = async (data) => {
    if ( create ) {
      if ( checked === null || checkedEmail !== data.email ) {
        setChecked(null);
        setMessage('중복 체크 후 진행하실 수 있습니다.');
      } else if ( checked === false ) {
        await createMember(group.id, data.email, data.password, role, data.name, data.affiliation)
          .then(() => navigate('/member/member-list', {state: {submit: true}}))
          .catch(() => {});
      }
    } else {
      await updateMember(state.id, role, data.name, data.affiliation)
        .then(() => navigate('/member/member-list', {state: {submit: true}}))
        .catch(() => {});
    }
  };

  return (
    <Container $align>
      <Content>
        <Breadcrumbs
          heading={`사용자 ${text}`}
          links={[
            { name: '사용자 관리' },
            { name: '사용자 목록', link: '/member/member-list' },
            { name: `사용자 ${text}` }
          ]}
        />

        <Form $pages>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>

              { admin &&
                <SearchGroups group={group} setGroup={setGroup} />
              }

              <Check>
                <TextField
                  name='email'
                  label='이메일'
                  type='text'
                  control={control}
                  disabled={disabled}
                />
                <button type='button' onClick={() => handleChecked()} disabled={disabled} >중복 체크</button>
              </Check>
              { message &&
                <Message className={message === '사용하실 수 있는 이메일입니다.' && 'check'}>
                  { message }
                </Message>
              }

              <TextField
                name='name'
                label='이름'
                type='text'
                control={control}
              />

              { create &&
                <>
                  <TextField
                    name='password'
                    label='비밀번호'
                    type='password'
                    control={control}
                  />
                  
                  <TextField
                    name='confirm'
                    label='비밀번호 확인'
                    type='password'
                    control={control}
                  />
                </>
              }

              <div>
                <p className='label'>권한</p>
                <select defaultValue={role} onChange={handleRole}>
                  <option value='MEMBER'>사용자</option>
                  <option value='MANAGER'>매니저</option>
                  { admin && <>
                    <option value='MASTER'>마스터</option>
                    <option value='ADMIN'>관리자</option>
                  </> }
                </select>
              </div>
              
              <TextField
                name='affiliation'
                label='소속'
                type='text'
                control={control}
              />
              
              <button type='submit'>{text}</button>

            </form>

          </Box>
        </Form>

      </Content>
    </Container>
  );
}