import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const checkPointNumber = async (unitId, number) => {
  return await axios.get(`displacement-points/unit/${unitId}/number/${number}/exists`)
    .then((response) => {
      return response.data.data.exists;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default checkPointNumber;