import { useState, useEffect } from 'react';
// components
import SearchField from '../../../components/search-field';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Popover } from 'devextreme-react/popover';
// styles
import { TreeWrapper, MoreButton, ScrollBar } from '../../styles';
// apis
import { getAnalysisList, getInitializeAnalysisResult } from '../../../apis';

// ----------------------------------------------------------------------

export default function TreeList({ state, setState, unit, type, selected, setSelected, setDetailedList, setDetailed, startDate, endDate, setResult, setSearch, handleDoubleClick, setDialog, active, setActive }) {

  const [ original, setOriginal ] = useState({ content: [], number: 0, last: true, empty: true });
  const [ data, setData ] = useState({ content: [], number: 0, last: true, empty: true });
  const { content: rows } = data;

  const [ initialize, setInitialize ] = useState(null);

  const [ markNumber, setMarkNumber ] = useState('');

  useEffect(() => {
    if ( unit ) {
      const id = type === 'inspection'? 'pipeSupportId' : 'displacementPointId';
      setMarkNumber('');

      ( async () =>
        await getAnalysisList(unit, type, '', 0)
          .then((response) => {
            setOriginal(response);
            setData(response);
            if ( response.content.length > 0 ) {
              let initializeId = null;
              if ( state ) {
                setSelected(state.id);
                initializeId = state.id;
              } else {
                setSelected(response.content[0][id]);
                initializeId = response.content[0][id];
              }
              getInitializeAnalysisResult(setInitialize, type, initializeId, startDate, endDate)
              .catch(() => {
                setInitialize(null);
              });
            }
          })
          .catch(() => {
            setOriginal({ content: [], number: 0, last: true, empty: true });
            setData({ content: [], number: 0, last: true, empty: true });
          })
      )();
      setState(null);
    }
    // eslint-disable-next-line
  }, [unit, type]);

  useEffect(() => {
    if ( initialize ) {
      if ( type === 'inspection' ) {
        setResult({inspectionResults: initialize.inspectionResults, movementResults: initialize.movementResults});
      } else {
        setResult(initialize.measurementResults);
      }
      const typeId = type === 'inspection' ? 'pipeSupportId' : 'displacementPointId';
      setDetailed(rows.filter((item) => item[typeId] === selected )[0]);
      setSearch({ type: type, movement: type === 'inspection' ? true : false, id: initialize[`${type}IdList`] });
    }
    // eslint-disable-next-line
  }, [initialize]);

  useEffect(() => {
    setDetailedList(rows);
    // eslint-disable-next-line
  }, [rows]);

  const handleSearch = async (more) => {
    if ( markNumber.length > 0 && markNumber.replace(/ /g, '').length === 0 ) return;
    await getAnalysisList(unit, type, markNumber, more? data.number + 1 : 0)
      .then((response) => {
        if ( !response.first ) {
          const array = [ ...data.content, ...response.content ];
          response.content = array;
          setOriginal(response);
        }
        setData(response);
      })
      .catch(() => {
        setData({ content: [], number: 0, last: true, empty: true });
        setOriginal({ content: [], number: 0, last: true, empty: true });
      });
  };

  const handleInitialize = () => setData(original);

  return (
    <TreeWrapper>
      <ScrollBar>
        <div>
          <SearchField
            value={markNumber}
            onChange={setMarkNumber}
            onKeyDown={handleSearch}
            onInitialize={handleInitialize}
            disabled={ unit ? false : true }
            placeholder='Mark Number를 입력하세요.'
          />

          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
            dataRowRender={({data}) => <TableRow row={data} type={type} selected={selected} setSelected={setSelected} handleDoubleClick={handleDoubleClick} setDialog={setDialog} />}
          >
            <Column caption='No.' dataField='number' alignment='center' />
            <Column caption='SYSTEM' dataField='pipeSystemName' alignment='center' />
            <Column caption='MARK NUMBER' dataField='markNumber' alignment='center' />
            <Column />
          </DataGrid>

          { !data.last &&
            <MoreButton>
              <button onClick={() => handleSearch(true)}>더보기</button>
            </MoreButton>
          }
        </div>
      </ScrollBar>

      <div>
        <button onClick={() => setActive(!active)} />
      </div>
    </TreeWrapper>
  );
}

function TableRow({ row, type, selected, setSelected, handleDoubleClick, setDialog }) {
  const id = type === 'inspection'? 'pipeSupportId' : 'displacementPointId';

  const [ visible, setVisible ] = useState(false);

  return (
    <tr
      className={selected === row[id] ? 'active' : ''}
      onClick={() => setSelected(row[id])}
      onDoubleClick={() => handleDoubleClick()}
    >
      <td>{row.number}</td>
      <td>{row.pipeSystemName}</td>
      <td>{row.markNumber}</td>
      <td>
        <button id={`more${row.number}`} className='more' onClick={() => setVisible(true)} />
        <Popover
          target={`#more${row.number}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button onClick={() => {
            setVisible(false);
            setDialog(row);
          }}>
            상세 보기
          </button>
        </Popover>
      </td>
    </tr>
  );
}