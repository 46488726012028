import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getPointSupport = async (setSupports, unitId, pipeSystemId, pipeLineId, markNumber, page) => {
  return await axios.get(`displacement-points/search-page/unit/${unitId}/pipe-system/${pipeSystemId}/pipe-line/${pipeLineId}/pipe-supports?markNumber=${markNumber}&page=${page}`)
    .then((response) => {
      setSupports(response.data.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getPointSupport;