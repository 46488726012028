import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getSupportInspection = async (setData, unitId, startDate, endDate, pipeStatus, page, size) => {
  
  startDate.setDate(1);
  const start = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
  const last = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
  const end = new Date(last.getTime() - (last.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

  const status = pipeStatus !== 'all' ? `&pipeStatus=${pipeStatus}` : '';

  return await axios.get(`pipe-support-inspection-managements/search-page/${unitId}?start=${start}&end=${end}${status}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getSupportInspection;