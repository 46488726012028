import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getUnregisteredPlant = async (setData, memberGroupId, name, page) => {
  return await axios.get(`member-group-plants/search-page/${memberGroupId}/unregistered?name=${encodeURIComponent(name)}&page=${page}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getUnregisteredPlant;