import { useState, useDeferredValue, useEffect } from 'react';
// utils
import { REGEXP_NUMBER  } from '../../../utils/regexp';
// styles
import { ResultTd } from '../../styles';

// ----------------------------------------------------------------------

export default function ResultTableRow({ row, results, setResults }){

  const [ keyword, setKeyword ] = useState({
    position: row.positionResult !== null ? String(row.positionResult) : '',
    remark: row.remark !== null ? row.remark : ''
  });
  const [ message, setMessage ] = useState({ position: null, remark: null });

  const answer = useDeferredValue(keyword);

  useEffect(() => {
    const position = answer.position.length > 0 ? answer.position : null;
    const remark = answer.remark.length > 0 && answer.remark.replace(/ /g, '').length !== 0 ? answer.remark : null;
    setResults({...results, [row.pipeSupportId]: { position: position, remark: remark }});
    // eslint-disable-next-line
  }, [answer]);

  return (
    <tr>
      <td>{row.number}</td>
      <td>{row.pipeSystemName}</td>
      <td>{row.pipeLineName}</td>
      <td>{row.markNumber}</td>
      <td>{row.modelSize}</td>
      <td>{row.designHotLoad}</td>
      <td>{row.designColdLoad}</td>
      <td>{row.totalTravel}</td>
      <td>{row.hotPosition}</td>
      <td>{row.coldPosition}</td>
      <td>{row.movement}</td>
      <td>{row.indicatorType}</td>
      <ResultTd>
        <input
          type='text'
          value={keyword.position}
          onChange={(event) => {
            const value = event.target.value.replace(REGEXP_NUMBER, '');
            if ( value.split('.').length > 2 ) {
              setMessage({...message, position: '숫자만 입력해 주세요.'});
            } else if ( value.split('.')[1] && value.split('.')[1].length > 1 ) {
              setMessage({...message, position: '소수점은 1자리까지 입력 가능합니다.'});
            } else if ( row.indicatorType === 'PIN' && (Number(value) < 0 || Number(value) > 10 ) ) {
              setMessage({...message, position: 'PIN type 범위는 1-10 입니다.'});
            } else {
              setMessage({...message, position: null});
              setKeyword({...keyword, position: value});
            }
          }}
        />
        { message.position &&
          <p>{ message.position }</p>
        }
      </ResultTd>
      <ResultTd>
        <input
          type='text'
          value={keyword.remark}
          onChange={(event) => {
            if ( event.target.value.length > 30 ) {
              setMessage({...message, remark: '30자 이하로 입력해 주세요.'});
            } else {
              setMessage({...message, remark: null});
              setKeyword({...keyword, remark: event.target.value});
            }
          }}
        />
        { message.remark &&
          <p>{ message.remark }</p>
        }
      </ResultTd>
    </tr>
  );
}