import { useState, useEffect } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import Select from '../../../components/select-box';
import TextField from '../../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// styles
import { Check, File, Message } from '../../styles';
// apis
import { getSupportProp, checkSupportNumber } from '../../../apis';

// ----------------------------------------------------------------------
  
const schema = yup.object().shape({
  pipeSystemId: yup.number()
    .required(),
  pipeLineId: yup.number()
    .required(),
  pipeSupportTypeId: yup.number()
    .required(),
  number: yup.number()
    .required()
    .min(1, '1 - 9,223,372,036,854,775,807 사이로 입력해주세요.')
    .max(9223372036854775807, '1 - 9,223,372,036,854,775,807 사이로 입력해주세요.'),
  markNumber: yup.string()
    .required()
    .max(30, '30자 이내로 입력해주세요.'),
  modelSize: yup.string()
    .required()
    .max(20, '20자 이내로 입력해주세요.'),
  designHotLoad: yup.number()
    .required(),
  designColdLoad: yup.number()
    .required(),
  totalTravel: yup.number()
    .required(),
  hotPosition: yup.number()
    .required(),
  coldPosition: yup.number()
    .required(),
  movement: yup.number()
    .required(),
  indicatorType: yup.string()
    .required(),
  xCoordinate: yup.number()
    .required(),
  yCoordinate: yup.number()
    .required(),
  zCoordinate: yup.number()
    .required(),
  xRotation: yup.number()
    .required(),
  yRotation: yup.number()
    .required(),
  zRotation: yup.number()
    .required()
});

// ----------------------------------------------------------------------

export default function SupportForm({ form, setOpen, unit, handleAction }) {

  const {
    control,
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      pipeSystemId: form.state?.pipeSystemId || '',
      pipeLineId: form.state?.pipeLineId || '',
      pipeSupportTypeId: form.state?.pipeSupportTypeId || '',
      number: form.state?.number || 1,
      markNumber: form.state?.markNumber || '',
      modelSize: form.state?.modelSize || '',
      designHotLoad: form.state?.designHotLoad || 0,
      designColdLoad: form.state?.designColdLoad || 0,
      totalTravel: form.state?.totalTravel || 0,
      hotPosition: form.state?.hotPosition || 0,
      coldPosition: form.state?.coldPosition || 0,
      movement: form.state?.movement || 0,
      indicatorType: form.state?.indicatorType || 'PIN',
      xCoordinate: form.state?.xcoordinate || 0,
      yCoordinate: form.state?.ycoordinate || 0,
      zCoordinate: form.state?.zcoordinate || 0,
      xRotation: form.state?.xrotation || 0,
      yRotation: form.state?.yrotation || 0,
      zRotation: form.state?.zrotation || 0
    }
  });

  const [ prop, setProp ] = useState({ pipeSystem: [], pipeSupportType: [] });
  const [ system, setSystem ] = useState(undefined);
  const [ auto, setAuto ] = useState(true);

  useEffect(() => {
    ( async () =>
      await getSupportProp(setProp, unit.id)
    )();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( prop.pipeSystem.length > 0 ) {
      setSystem(form.state ? form.state.pipeSystemId : prop.pipeSystem[0].id);
      setValue('pipeSystemId', form.state ? form.state.pipeSystemId : prop.pipeSystem[0].id);
    }
    if ( prop.pipeSupportType.length > 0 ) {
      setValue('pipeSupportTypeId', form.state ? form.state.pipeSupportTypeId : prop.pipeSupportType[0].id);
    }
    setValue('number', form.state ? form.state.number : prop.nextNumber);
    // eslint-disable-next-line
  }, [prop]);

  useEffect(() => {
    if ( system ) {
      setValue('pipeLineId', form.state ? form.state.pipeLineId : prop.pipeSystem.find((item) => item.id === Number(system)).pipeLine[0].id);
    }
    // eslint-disable-next-line
  }, [system]);


  const [ checked, setChecked ] = useState(null);
  const [ checkedNumber, setCheckedNumber ] = useState(null);

  const [ message, setMessage ] = useState({file: null, check: null});

  const [ file, setFile ] = useState(null);

  const handleFile = (event) => {
    if ( event.target.files.length > 0 ) {
      const file = event.target.files[0];
      const name = file.name.split('.');
      const extension = name[name.length - 1];
      const maxSize = 100 * 1024 * 1024; // 100MB
      if ( extension !== 'pdf' ) {
        setFile(false);
        return setMessage({...message, file: 'pdf 파일을 선택해 주세요.'});
      }
      if ( file.size > maxSize ) {
        setFile(false);
        return setMessage({...message, file: '100MB 이하의 파일을 선택해 주세요.'});
      }
      setFile(file);
      setMessage({...message, file: null});
    } else {
      setFile(null);
      setMessage({...message, file: null});
    }
  };

  const onSubmit = async (data, event) => {
    if ( event ) {
      // 중복 체크
      await checkSupportNumber(unit.id, data.number)
        .then((response) => {
          setChecked(response);
          setCheckedNumber(data.number);
          setMessage({...message, check: response ? '이미 등록된 일련번호입니다.' : '사용하실 수 있는 일련번호입니다.'});
        })
        .catch((error) => {
          setMessage({...message, check: error.message});
        });
    } else {
      if ( !auto && (checked === null || checkedNumber !== data.number) ) {
        setChecked(null);
        setMessage({...message, check: '중복 체크 후 진행하실 수 있습니다.'});
      } else if ( (auto || checked === false) && file !== false ) {
        const fields = Object.assign({}, data);
        if ( form.type === '등록' ) {
          fields.autoNumber = auto;
          fields.unitId = unit.id;
        } else if ( form.type === '수정' && auto ) {
          fields.number = form.state.number;
        }
        await handleAction(fields, file)
          .catch(() => setMessage(`배관지지장치를 ${form.type}할 수 없습니다.`));
      }
    }
  };

  return (
    <Dialog
      title={`배관지지장치 ${form.type}`}
      actions={
        <>
          <button onClick={() => handleSubmit((data, event) => onSubmit(data, event))()}>
            {form.type}
          </button>
          <button onClick={() => setOpen({ visible: false })}>
            취소
          </button>
        </>
      }
    >
        
      <form onSubmit={handleSubmit(onSubmit)} className='flex-container'>
        <p>{ unit.name }</p>

        <div className='merge'>
          <Check $pages>
            <TextField
              name='number'
              label='Number'
              type='number'
              control={control}
              disabled={auto}
            />
            <button disabled={auto}>중복 체크</button>
          </Check>

          <div>
            { form.type === '등록' ? (
              <>
                <input type='checkbox' id='checkbox' checked={auto} onChange={(event) => setAuto(event.target.checked)} />
                <label htmlFor='checkbox'>
                  <span>Auto</span>
                </label>
              </>
            ) : (
              <>
                <input type='checkbox' id='checkbox' checked={!auto} onChange={(event) => setAuto(!event.target.checked)} />
                <label htmlFor='checkbox'>
                  <span>수정</span>
                </label>
              </>
            )}
          </div>
        </div>
        { message.check &&
          <Message>{ message.check }</Message>
        }

        <div>
          <Select
            name='pipeSystemId'
            label='System'
            control={control}
            value={system}
            onChange={(event) => {
              setSystem(event.target.value);
              setValue('pipeSystemId', event.target.value);
            }}
          >
            { prop.pipeSystem.length > 0 ? (
              prop.pipeSystem.map((system) => (
                <option key={system.id} value={system.id}>{system.name}</option>
              ))
            ) : (
              <option value=''>시스템을 등록해주세요.</option>
            )}
          </Select>

          <Select
            name='pipeLineId'
            label='Line'
            control={control}
          >
            { system ? (
              prop.pipeSystem.find((item) => item.id === Number(system)).pipeLine.map((line) => (
                <option key={line.id} value={line.id}>{line.name}</option>
              ))
            ) : (
              <option value=''>라인을 등록해주세요.</option>
            )}
          </Select>
        </div>

        <div>
          <Select
            name='pipeSupportTypeId'
            label='Type'
            control={control}
          >
            { prop.pipeSupportType.length > 0 ? (
              prop.pipeSupportType.map((type) => (
                <option key={type.id} value={type.id}>{type.type}</option>
              ))
            ) : (
              <option value=''>타입을 등록해주세요.</option>
            )}
          </Select>

          <TextField
            name='markNumber'
            label='Mark Number'
            type='text'
            control={control}
          />
        </div>

        <div>
          <TextField
            name='modelSize'
            label='Model Size'
            type='text'
            control={control}
          />

          <TextField
            name='totalTravel'
            label='Total Travel'
            type='number'
            control={control}
          />
        </div>

        <div>
          <TextField
            name='designHotLoad'
            label='Design Hot Load'
            type='number'
            control={control}
          />

          <TextField
            name='designColdLoad'
            label='Design Cold Load'
            type='number'
            control={control}
          />
        </div>

        <div>
          <TextField
            name='hotPosition'
            label='Hot Position'
            type='number'
            control={control}
          />

          <TextField
            name='coldPosition'
            label='Cold Position'
            type='number'
            control={control}
          />
        </div>

        <div>
          <TextField
            name='movement'
            label='Movement'
            type='number'
            control={control}
          />

          <Select
            name='indicatorType'
            label='Indicator Type'
            control={control}
          >
            <option value='PIN'>PIN</option>
            <option value='mm'>mm</option>
          </Select>
        </div>

        <p className='label'>Position</p>
        <div className='split'>
          <TextField
            name='xCoordinate'
            type='number'
            control={control}
            label='X'
          />

          <TextField
            name='yCoordinate'
            type='number'
            control={control}
            label='Y'
          />

          <TextField
            name='zCoordinate'
            type='number'
            control={control}
            label='Z'
          />
        </div>
        <div className='split'>
          <TextField
            name='xRotation'
            type='number'
            control={control}
            label='Rotation X'
          />

          <TextField
            name='yRotation'
            type='number'
            control={control}
            label='Rotation Y'
          />

          <TextField
            name='zRotation'
            type='number'
            control={control}
            label='Rotation Z'
          />
        </div>

        { form.type === '등록' &&
          <File>
            <div>
              <label htmlFor='file'><div />도면 등록</label>
              <p>{file ? file.name : '등록된 파일이 없습니다.'}</p>
            </div>
            { message.file &&
              <Message>{ message.file }</Message>
            }
            <input id='file' type='file' onChange={handleFile} />
          </File>
        }
      </form>
    </Dialog>
  );
}