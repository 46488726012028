import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const createDisplacement = async (unitId, measurementCompany, pipeStatus, measurementAt) => {
  return await axios.post('displacement-measurement-managements', {unitId, measurementCompany, pipeStatus, measurementAt})
    .then((response) => {
      const location = response.headers.location.split('/');
      return location[location.length - 1];
    })
    .catch((error) => {
      if ( error.response.data.code !== 'ERR-294' ) {
        store.dispatch( SET_NOTIFY({
          message: message(error, 'displacement'),
          action: null
        }) );
      }
      return Promise.reject(error.response.data.code);
    });
};

export default createDisplacement;