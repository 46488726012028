import styled from 'styled-components';

// ----------------------------------------------------------------------

export const File = styled.div({
  input: {
    display: 'none'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 42,
    cursor: 'pointer',
    border: '1px dashed #9AB1C1',
    borderRadius: 8,
    color: '#078DEE',
    div: {
      width: 24,
      height: 24,
      backgroundImage: 'url(/assets/file.png)',
      backgroundSize: '100% auto',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      marginRight: 10
    }
  },
  p: {
    textAlign: 'left',
    paddingTop: 10
  }
});

export const Form = styled.div(
  {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    'button:first-child, select': {
      width: '100%'
    }
  },
  (props) => props.$pages && ({
    [`${Check}`]: {
      button: {
        margin: '22px 0 0 10px'
      }
    },
    'form > *:not(p, button)': {
      marginTop: 20
    },
    'form > *:first-child': {
      marginTop: 0
    },
    'p.label': {
      paddingBottom: 5
    }
  })
);

export const Check = styled.div(
  {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '> div': {
      width: 'calc(100% - 95px)',
    },
    button: {
      width: 85,
      height: 42,
      border: '1px solid #078DEE',
      color: '#078DEE',
      borderRadius: 8,
      marginLeft: 10
    }
  },
  (props) => props.$pages && ({
    button: {
      margin: '22px 0 0 10px'
    }
  })
);