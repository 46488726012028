import { useState, useEffect } from 'react';
// sections
import TableRow from './TableRow';
import Form from './Form';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import { TreeList, Column } from 'devextreme-react/tree-list';
// styles
import { Container, Content, Box, SearchWrapper } from '../../styles';
// apis
import { getPlant, createPlant, updatePlant, deletePlant, createUnit, updateUnit, deleteUnit } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 20];

// ----------------------------------------------------------------------

export default function PlantPage() {

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ name, setName ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(10);

  const [ form, setForm ] = useState({ visible: false });
  const [ dialog, setDialog ] = useState({ visible: false });

  useEffect(() => {
    ( async () =>
      await getPlant(setData, name, page, size)
    )();
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    setPage(0);
    if ( name.length > 0 && name.replace(/ /g, '').length === 0 ) return;
    getPlant(setData, name, 0, size);
  };

  const handlePage = (value) => {
    setPage(value-1);
    getPlant(setData, name, value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getPlant(setData, name, 0, Number(event.target.value));
  };

  const handleAction = async (name) => {
    await action(name)
      .then(() => {
        handleReset();
        setForm({ visible: false });
      })
      .catch(() => {});
  };

  const action = async (name) => {
    if ( form.target === '발전소' && form.type === '등록' ) {
      await createPlant(name);
    } else if ( form.target === '발전소' && form.type === '수정' ) {
      await updatePlant(form.state.id, name);
    } else if ( form.target === '호기' && form.type === '등록' ) {
      await createUnit(form.state.plantId, name);
    } else if ( form.target === '호기' && form.type === '수정' ) {
      await updateUnit(form.state.id, name);
    }
  };

  const handleDelete = async () => {
    if ( dialog.type === '발전소' ) {
      await deletePlant(dialog.id)
        .then(() => handleReset());
    } else {
      await deleteUnit(dialog.id)
        .then(() => handleReset());
    }
  };

  const handleReset = () => {
    setName('');
    setPage(0);
    getPlant(setData, name, 0, size);
  };

  return (
    <Container>
      <Content>
        <Breadcrumbs
          heading='발전소 관리'
          links={[
            { name: '시스템 관리' },
            { name: '발전소 관리' }
          ]}
        />

        <Box $admin>

          <SearchWrapper>
            <SearchField
              value={name}
              onChange={setName}
              onKeyDown={handleSearch}
              onInitialize={handleSearch}
              placeholder='발전소명을 입력하세요.'
            />
              
            <div>
              <button className='register' onClick={() => setForm({visible: true, target: '발전소', type: '등록'})}>등록</button>
            </div>
          </SearchWrapper>

          <TreeList
            dataSource={rows}
            columnAutoWidth={true}
            dataStructure='tree'
            itemsExpr='units'
            paging={{enabled: false}}
            noDataText=''
          >
            <Column caption='발전소' dataField='name' alignment='center' />
            <Column caption='등록일' dataField='createdAt' alignment='center' />
            <Column caption='' dataField='id' allowSorting={false} cellRender={({data}) => <TableRow row={data} setForm={setForm} setDialog={setDialog} />} />
          </TreeList>

          <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

          { form.visible &&
            <Form form={form} setOpen={setForm} handleAction={handleAction} />
          }

          { dialog.visible &&
            <Dialog
              title={`${dialog.type} 삭제`}
              actions={
                <>
                  <button onClick={() => {
                    setDialog({visible: false});
                    handleDelete();
                  }}>
                    삭제
                  </button>
                  <button onClick={() => setDialog({visible: false})}>
                    취소
                  </button>
                </>
              }
              type='confirm'
            >
              <p>선택하신 {dialog.type}에 소속된<br />모든 하위 항목도 삭제됩니다.</p>
              <p>삭제하시겠습니까?</p>
            </Dialog>
          }
        
        </Box>
      </Content>
    </Container>
  );
}