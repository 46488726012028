import axios from '../utils/axios';

// ----------------------------------------------------------------------

const searchDataLists = async (type, {unitId, startDate, endDate}, page) => {
  
  startDate.setDate(1);
  const start = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
  const last = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
  const end = new Date(last.getTime() - (last.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

  return await axios.get(`data-lists/search-slice/unit/${unitId}/${type}?start=${start}&end=${end}&page=${page}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default searchDataLists;