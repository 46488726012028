import axios from 'axios';
import store, { persistor, DELETE_TOKEN, DELETE_ACCOUNT, DELETE_UNIT, SET_NOTIFY, SET_LOADING, DELETE_LOADING } from '../../store';
import message from '../../utils/message';
import validation from '../utils/validation';
import reissue from '../auth-account/reissue';
import searchAccountPlants from '../auth-account/searchAccountPlants';

// ----------------------------------------------------------------------

const deleteInfo = () => {
  store.dispatch( DELETE_TOKEN() );
  store.dispatch( DELETE_ACCOUNT() );
  store.dispatch( DELETE_UNIT() );
  persistor.purge();
};

const instance = axios.create({
  baseURL: '/api/',
  timeout: 5000
});

instance.interceptors.request.use( async (config) => {
  const token = store.getState().token;
  config.headers.Authorization = token ? `Bearer ${token}` : null;
  if ( !validation(token) ) await reissue(config);
  store.dispatch( SET_LOADING() );
  return config;
});

instance.interceptors.response.use(
  (response) => {
    store.dispatch( DELETE_LOADING() );
    return response;
  },
  (error) => {
    store.dispatch( DELETE_LOADING() );

    const config = error.config;
    const status = error.response?.status;
    const code = error.response?.data.code;

    if ( status === 500 ) return window.location.href = '/error';

    if ( config.url === 'accounts/me' || ( status === 403 && code !== 'ERR-017' ) ) {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'status_403'),
        action: '/'
      }) );
      return deleteInfo();
    }

    if ( code === 'ERR-017' ) {
      const path = window.location.pathname.split('/');
      if ( path.length === 2 || path[2] === 'data' ) return searchAccountPlants('', 0);
      Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default instance;