import { useState } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import TextField from '../../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// styles
import { File } from '../../styles';

// ----------------------------------------------------------------------
  
const schema = yup.object().shape({
  title: yup.string()
    .required()
});

// ----------------------------------------------------------------------

export default function Form({ form, setOpen, handleAction }) {

  const {
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: form.state?.title || ''
    }
  });

  const [ file, setFile ] = useState(null);
  const [ message, setMessage ] = useState(null);

  const handleFile = (event) => {
    if ( event.target.files.length > 0 ) {
      const file = event.target.files[0];
      const name = file.name.split('.');
      const maxSize = 100 * 1024 * 1024; // 100MB
      name.pop();
      if ( name.join('.').length > 100 ) {
        setFile(false);
        return setMessage('파일 이름은 100자 이내로 입력해 주세요.');
      }
      if ( file.size > maxSize ) {
        setFile(false);
        return setMessage('100MB 이하의 파일을 선택해 주세요.');
      }
      setFile(file);
      setMessage(null);
    } else {
      setFile(null);
      setMessage(null);
    }
  };

  const onSubmit = async (data) => {
    if ( form.type === '등록' && file === null ) {
      setMessage('파일을 선택해 주세요.');
    } else if ( file !== false ) {
      await handleAction(data.title, file)
        .catch(() => setMessage(`보고서를 ${form.type}할 수 없습니다.`));
    }
  };

  return (
    <Dialog
      title={`보고서 ${form.type}`}
      actions={
        <>
          <button onClick={() => handleSubmit((event) => onSubmit(event))()}>
            { form.type }
          </button>
          <button onClick={() => setOpen({ visible: false })}>
            취소
          </button>
        </>
      }
      message={message}
    >
      <form>
        <TextField
          name='title'
          type='text'
          control={control}
          placeholder='제목을 입력해 주세요.'
        />

        <File>
          <input id='file' type='file' onChange={handleFile} />
          <label htmlFor='file'><div />보고서 등록</label>
          <p>{ file ? file.name : '선택된 보고서가 없습니다.' }</p>
        </File>
      </form>
    </Dialog>
  );
}