import { useState } from 'react';
import { useLocation, useNavigate, Link, Outlet } from 'react-router-dom';
// store
import { useSelector } from '../store';
// sections
import Boundary from './Boundary';
import Nav from './Nav';
import SearchPlants from './SearchPlants';
// components
import Loaders from '../components/loaders';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { Header, Logo, Main, Account } from './styles';
// apis
import { logout } from '../apis';

// ----------------------------------------------------------------------

const forbid = [ 'member', 'system' ];
const role = { '사용자': 0, '매니저': 1, '마스터': 2, '관리자': 3 };

// ----------------------------------------------------------------------

export default function Layout() {
  const pathname = useLocation().pathname.split('/')[1];
  const disabled = useLocation().pathname.split('/')[3] === 'create' ? true : false;
  const navigate  = useNavigate();
  const account = useSelector(state => state.account);
  const loading = useSelector(state => state.loading);
  
  const [ visible, setVisible ] = useState({
    data: false,
    user: false,
    system: false,
    account: false
  });

  const handleLogout = async () => {
    await logout()
      .then(() => navigate('/'))
  };

  return (
    <Boundary>
      { loading &&
        <Loaders />
      }

      <Header>
        <Logo />

        { account &&
          <Nav visible={visible} setVisible={setVisible} role={role[account.role]} path={pathname} />
        }

        <div>
          { account && !forbid.includes(pathname) &&
            <SearchPlants disabled={disabled} />
          }

          { account &&
            <Account id='account' onClick={() => setVisible({...visible, account: true})}><div />{account.name} 님</Account>
          }

          <Popover
            target='#account'
            wrapperAttr={{ class: 'account-popover' }}
            visible={visible.account}
            hideOnOutsideClick={true}
            onHiding={() => setVisible({...visible, account: false})}
          >
            { account &&
              <p>{ account.affiliation }</p>
            }
            <Link to='/account' onClick={() => setVisible({...visible, account: false})}><div />계정 관리</Link>
            <button onClick={handleLogout}><div />로그아웃</button>
          </Popover>
        </div>
      </Header>

      <Main>
        { account &&
          <Outlet />
        }
      </Main>
    </Boundary>
  );
}