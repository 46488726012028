import { useState } from 'react';
// @devextreme
import { Popover } from 'devextreme-react/popover';

// ----------------------------------------------------------------------

export default function TableRow({ row, setDialog }){
  const [ visible, setVisible ] = useState(false);
  
  return (
    <tr>
      <td>{row.plantName}</td>
      <td>{row.unitName}</td>
      <td>{row.createdAt}</td>
      <td>
        <button id={`more${row.memberGroupPlantId}`} className='more' onClick={() => setVisible(true)} />

        <Popover
          target={`#more${row.memberGroupPlantId}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button onClick={() => {
            setDialog({visible: true, id: row.memberGroupPlantId });
            setVisible(false);
          }}>
            삭제
          </button>
        </Popover>
      </td>
    </tr>
  );
}