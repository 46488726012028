const config = {
  common: [
    {
      title: '대시보드', path: '/dashboard'
    }, {
      title: '데이터 분석', path: '/data-analysis'
    }, {
      title: '데이터 조회', path: '/data-inquiry'
    }, {
      title: '배관지지장치 도면', path: '/drawing'
    }, {
      title: '보고서', path: '/report'
    }
  ],
  role: [
    {
      title: '데이터 관리',
      id: 'data',
      children: [
        { title: '배관지지장치 점검', path: '/data/support-inspection' },
        { title: '변위 측정', path: '/data/displacement' },
        { title: '보고서', path: '/data/report' }
      ]
    }, {
      title: '사용자 관리',
      id: 'member',
      children: [
        { title: '사용자 목록', path: '/member/member-list' },
        { title: '로그인 기록', path: '/member/login-log' }
      ]
    }, {
      title: '시스템 관리',
      id: 'system',
      children: [
        { title: '사용자 그룹 관리', path: '/system/member-group' },
        { title: '발전소 관리', path: '/system/plant' },
        { title: '배관 관리', path: '/system/pipe' },
        { title: '배관 지지 장치 타입 관리', path: '/system/support-type' },
        { title: '배관 지지 장치 관리', path: '/system/support' },
        { title: '변위 측정 위치 관리', path: '/system/displacement-point' }
      ]
    }
  ]
};

export default config;