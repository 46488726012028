import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// config
import config from './config';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { Navigation } from './styles';

// ----------------------------------------------------------------------

export default function Nav({ visible, setVisible, role, path }) {
  const [ nav, setNav ] = useState(null);

  useEffect(() => {
    if ( role > 0 ) {
      const menu = [];
      config.role.forEach((item, index) => {
        if ( index < role ) menu.push(item);
      });
      setNav(menu);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Navigation>
      { config.common.map((nav) => (
        <Link key={nav.path} to={nav.path} className={path === nav.path.split('/')[1] ? 'active' : ''} >{nav.title}</Link>
      )) }

      { nav && nav.map((menu) => (
        <NavList key={menu.title} nav={menu} visible={visible} setVisible={setVisible} path={path} />
      )) }
    </Navigation>
  );
}

function NavList({ nav, visible, setVisible, path }) {
  return (
    <>
      <button id={nav.id} onClick={() => setVisible({...visible, [nav.id]: true})} className={path === nav.id ? 'active' : ''} >{nav.title}</button>
      <Popover
        target={`#${nav.id}`}
        visible={visible[nav.id]}
        hideOnOutsideClick={true}
        onHiding={() => setVisible({...visible, [nav.id]: false})}
      >
        { nav.children.map((nav) => (
          <Link
            key={nav.path}
            to={nav.path}
            onClick={() => setVisible({
              data: false,
              user: false,
              system: false,
              account: false
            })}
          >
            {nav.title}
          </Link>
        )) }
      </Popover>
    </>
  );
}