import { useState, useEffect } from 'react';
// sections
import TableRow from './TableRow';
import Form from './Form';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, SearchWrapper } from '../../styles';
// apis
import { getSupportType, createSupportType, updateSupportType, deleteSupportType } from '../../../apis';

// ----------------------------------------------------------------------

export default function SupportTypePage() {

  const [ data, setData ] = useState([]);

  const [ form, setForm ] = useState({ visible: false });
  const [ dialog, setDialog ] = useState({ visible: false });

  useEffect(() => {
    ( async () =>
      await getSupportType(setData)
    )();
  }, []);

  const handleAction = async (type, file) => {
    await action(type, file)
      .then(() => {
        setForm({ visible: false });
        getSupportType(setData)
      });
  };

  const action = async (type, file) => {
    if ( form.type === '등록' ) {
      await createSupportType(type, file);
    } else if ( form.type === '수정' ) {
      await updateSupportType(form.state.id, type, file);
    }
  };

  const handleDelete = async () => {
    await deleteSupportType(dialog.id)
      .then(() => {
        getSupportType(setData);
        setDialog({visible: false});
      })
      .catch((error) => setDialog({...dialog, type: '오류', text: error.message}));
  };

  return (
    <Container>
      <Content>
        <Breadcrumbs
          heading='배관지지장치 타입 관리'
          links={[
            { name: '시스템 관리' },
            { name: '배관지지장치 타입 관리' }
          ]}
        />

        <Box $admin>

          <SearchWrapper>
            <div />
              
            <div>
              <button className='register' onClick={() => setForm({visible: true, type: '등록'})} >등록</button>
            </div>
          </SearchWrapper>

          <DataGrid
            dataSource={data}
            noDataText=''
            paging={{enabled: false}}
            dataRowRender={({data}) => <TableRow row={data} setForm={setForm} setDialog={setDialog} />}
          >
            <Column caption='배관지지장치 타입' dataField='type' alignment='center' />
            <Column caption='등록일' dataField='createdAt' alignment='center' />
            <Column />
          </DataGrid>

          { form.visible &&
            <Form form={form} setOpen={setForm} handleAction={handleAction} />
          }

          { dialog.visible &&
            <Dialog
              title='배관지지장치 타입 삭제'
              actions={
                <>
                  <button onClick={() => handleDelete()}>
                    삭제
                  </button>
                  <button onClick={() => setDialog({visible: false})}>
                    취소
                  </button>
                </>
              }
              type='confirm'
            >
              <p>현재 배관지지장치에서 사용중인 타입은<br />삭제가 되지 않습니다.</p>
              <p>삭제하시겠습니까?</p>
            </Dialog>
          }
        
        </Box>
      </Content>
    </Container>
  );
}