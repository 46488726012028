import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div({
  width: '100%'
});

export const Label = styled.p(
  {
    fontSize: 13.5,
    paddingBottom: 3
  }
);

export const Input = styled.div(
  {
    width: '100%',
    height: 42,
    border: '1px solid #E8E8E8',
    borderRadius: 8,
    display: 'flex',
    overflow: 'hidden',
    input: {
      width: '100%',
      height: '100%',
      border: 'none',
      padding: '0 14px',
      backgroundColor: 'transparent',
      '&::placeholder': {
        color: '#CCCCCC'
      }
    }
  },
  (props) => props.$error && ({
    borderColor: '#FF5630'
  })
);

export const Icon = styled.div(
  {
    width: 36,
    height: '100%',
    backgroundSize: '24px 24px',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat'
  },
  (props) => ({
    backgroundImage: `url(/assets/login/${props.$icon}.png)`,
  })
);

export const Message = styled.p(
  {
    color: '#FF5630',
    fontSize: 13,
    lineHeight: '16px',
    marginTop: 10,
    '&:before': {
      content: '""',
      display: 'inline-block',
      verticalAlign: 'top',
      width: 16,
      height: 16,
      marginRight: 5,
      backgroundImage: 'url(/assets/message_error.png)',
      backgroundSize: '100% auto',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    }
  },
  (props) => props.$info && ({
    color: '#078DEE',
    '&:before': {
      display: 'none'
    }
  })
);