import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const createPointAsset = async (file) => {
  const formData = new FormData();
  formData.append('assetFile', file);
  return await axios.post('displacement-points/asset', formData)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createPointAsset;