// sections
import PasswordForm from './PasswordForm';
// styles
import { Container, Box } from '../styles';

// ----------------------------------------------------------------------

export default function AccountPage() {
  
  return (
    <Container $align>
      <Box $mb>
        <PasswordForm />
      </Box>
    </Container>
  );
}