import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const createSupportInspectionResult = async (inspectionId, inspectionResults) => {
  return await axios.post('pipe-support-inspection-result-managements', {inspectionId, inspectionResults})
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'support_inspection'),
        action: null
      }) );
    });
};

export default createSupportInspectionResult;