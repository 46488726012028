import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const getSupportInspectionResult = async (setData, setResults, pipeSupportInspectionId) => {
  return await axios.get(`pipe-support-inspection-result-managements/search/inspection/${pipeSupportInspectionId}`)
    .then((response) => {
      setData(response.data.data);
      if ( response.data.data.length > 0 ) {
        const obj = response.data.data.reduce((accumulator, value) => {
          return {...accumulator, [value.pipeSupportId]: {position: value.positionResult, remark: value.remark}};
        }, {});
        setResults(obj);
      }
    })
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'support_inspection'),
        action: -1
      }) );
    });
};

export default getSupportInspectionResult;