import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const deleteLine = async (pipeLineId) => {
  return await axios.delete(`pipe-lines/${pipeLineId}`)
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'pipe'),
        action: null
      }) );
    });
};

export default deleteLine;