import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getResult = async (type, id) => {
  return await axios.get(`dashboards/${type}/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getResult;