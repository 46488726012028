import { useEffect } from 'react';

import PropTypes from 'prop-types';
import ReactPagination from 'react-js-pagination';

import { Wrapper, RowsPerPage } from './styles';

// ----------------------------------------------------------------------

Pagination.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  handlePage: PropTypes.func,
  sizes: PropTypes.arrayOf(PropTypes.number),
  size: PropTypes.number,
  handleSize: PropTypes.func
};

// ----------------------------------------------------------------------

export default function Pagination({ pages, page, handlePage, sizes, size=10, handleSize }) {

  useEffect(() => {
    if ( window.scrollY === 0 ) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page, size]);

  return (
    <Wrapper $center={!sizes && true}>
      { sizes &&
        <RowsPerPage>
          <p>페이지 당 목록</p>
          <select defaultValue={size} onChange={handleSize}>
            { sizes.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </RowsPerPage>
      }

      <ReactPagination
        activePage={page + 1}
        itemsCountPerPage={size}
        totalItemsCount={size * pages}
        pageRangeDisplayed={5}
        onChange={handlePage}
        itemClassFirst='arrow rotate'
        itemClassPrev='arrow double rotate'
        itemClassNext='arrow'
        itemClassLast='arrow double'
        prevPageText=''
        firstPageText=''
        lastPageText=''
        nextPageText=''
      />
    </Wrapper>
  );
}