import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const createLine = async (pipeSystemId, name) => {
  return await axios.post('pipe-lines', {pipeSystemId, name})
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createLine;