import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getPlant = async (setData, name, page, size) => {
  return await axios.get(`plants/search-page?name=${encodeURIComponent(name)}&page=${page}&size=${size}`)
    .then((response) => {
      response.data.data.content = response.data.data.content.map((item) => {
        if ( item.units ) item.units = item.units.map((unit) => ({...unit, name: unit.unitName, plantId: item.plantId, plantName: item.plantName}));
        return { ...item, name: item.plantName };
      });
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getPlant;