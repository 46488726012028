import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import store, { SET_LOADING } from '../../../store';
// components
import SearchField from '../../../components/search-field';
// @devextreme
import DateBox from 'devextreme-react/date-box';
import { Popover } from 'devextreme-react/popover';
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { TreeWrapper, ScrollBar } from '../../styles';
// apis
import { getInitialData, searchDataLists, getTreeList, getResult } from '../../../apis';

// ----------------------------------------------------------------------

  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate);
  defaultStartDate.setFullYear( defaultEndDate.getFullYear() - 1 );

  const defaultValues = {
    systems: [],
    system: null,
    histories: { content: [], number: 0, last: true, empty: true, type: null },
    history: null,
    info: {supports: [], points: []},
    results: [],
    result: null
  };

// ----------------------------------------------------------------------

export default function TreeList({ unit, instance, onSystemInfo, onResults, selected, setSelected, details, setDetails, setDialog, hides, setHides, handleRequest, active, setActive }) {

  const [ startDate, setStartDate ] = useState(defaultStartDate);
  const [ endDate, setEndDate ] = useState(defaultEndDate);

  const [ type, setType ] = useState('inspection');

  const [ systems, setSystems ] = useState(defaultValues.systems);
  const [ system, setSystem ] = useState(defaultValues.system);

  const [ histories, setHistories ] = useState(defaultValues.histories);
  const [ history, setHistory ] = useState(defaultValues.history);
  const [ visible, setVisible ] = useState(false);
  
  const [ info, setInfo ] = useState(defaultValues.info);
  const [ tree, setTree ] = useState(defaultValues.info);
  const [ markNumber, setMarkNumber ] = useState('');
  const [ results, setResults ] = useState(defaultValues.results);

  useEffect(() => {
    store.dispatch( SET_LOADING() );
    if ( unit && instance ) {
      ( async () =>
        await getInitialData(unit, type, startDate, endDate, 0, setSystems)
          .then((response) => {
            const { systems, system, histories, history, info, results } = response;
            setSystems(systems);
            setSystem(system);
            setHistories(histories);
            setHistory(history);
            setInfo(info);
            setTree(info);
            setResults(results);
            const { info: tree, result } = formatInfo(system, info, type, results);
            onSystemInfo(tree, result);
          })
          .catch(() => {
            const { systems, system, histories, history, info, results } = defaultValues;
            setSystems(systems);
            setSystem(system);
            setHistories({...histories, type: type});
            setHistory(history);
            setInfo(info);
            setTree(info);
            setResults(results);
            const { info: tree, result } = formatInfo(system, info, type, results);
            onSystemInfo(tree, result);
          })
          .finally(() => {
            
          })
      )();
    }
    // eslint-disable-next-line
  }, [unit, instance]);

  useEffect(() => {
    if ( details ) {
      const type = histories.type === 'inspection' ? 'supports' : 'points';
      const id = `${histories.type === 'inspection' ? 'pipeSupport' : 'displacementPoint'}Id`;
      const answer = info[type].filter((item) => { return item[id] === Number(details) });
      const result = results.filter((item) => { return item[id] === Number(details) });

      setDialog({ visible: true, details: { ...answer[0], ...result[0] } });
    }
    // eslint-disable-next-line
  }, [details]);

  useEffect(() => {
    setHides([]);
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    setMarkNumber('');
    // eslint-disable-next-line
  }, [histories, history]);

  const handleHistories = async (more) => {
    await searchDataLists(type, {unitId: unit, startDate, endDate}, more? histories.number + 1 : 0)
      .then((response) => {
        if ( !response.first ) {
          const array = [ ...histories.content, ...response.content ];
          response.content = array;
        }
        response.type = type;
        setHistories(response);

        if ( response.first ) {
          let name = defaultValues.history;

          if ( response.content.length > 0 ) {
            name = `${response.content[0][`${type}At`]} (${response.content[0].pipeStatus})`;
            handleResults(response.content[0][`${type}Id`]);
          } else {
            setResults(defaultValues.results);
          }
          setHistory(name);
        }
      })
      .catch(() => {
        setHistories({...defaultValues.histories, type: type});
        setHistory(defaultValues.history);
      });
  };

  const handleSystem = async (id) => {
    await getTreeList(id)
      .then((response) => {
        setInfo(response);
        setTree(response);
        const { info, result } = formatInfo(id, response, histories.type, results);
        onSystemInfo(info, result);
      })
      .catch(() => {
        setInfo(defaultValues.info);
        setTree(defaultValues.info);
        const { info, result } = formatInfo(id, defaultValues.info, histories.type, results);
        onSystemInfo(info, result);
      });
  };

  const handleSearch = () => {
    if ( markNumber.length > 0 && markNumber.replace(/ /g, '').length === 0 ) return;

    const type = histories.type === 'inspection' ? 'supports' : 'points';
    let response = info;
    if ( (markNumber.length !== 0 || markNumber.replace(/ /g, '').length !== 0) && info[type].length > 0 ) {
      const answer = info[type].filter((item) => item.markNumber && item.markNumber.includes(markNumber));
      response = {...response, [type]: answer};
    }
    setTree(response);
    const { info: systemInfo, result } = formatInfo(system, response, histories.type, results);
    onSystemInfo(systemInfo, result);
  };

  const handleResults = async (id) => {
    await getResult(type, id)
      .then((response) => {
        setResults(response);
        const result = formatResult(tree, type, response);
        onResults(result);
      })
      .catch(() => {
        setResults([]);
        const result = formatResult(tree, type, []);
        onResults(result);
      });
  };

  const formatInfo = (system, tree, types, results) => {
    const offset = 0;
    const supports = tree.supports.length > 0 ? tree.supports.map((item) => {
      return `${item.pipeSupportId}_${item.xcoordinate},${item.ycoordinate},${item.zcoordinate}_${item.xrotation},${item.yrotation},${item.zrotation}_${item.number}_${item.pipeSupportTypeId}_${item.indicatorType}_${offset}`;
    }).join(':') : '';
    const points = tree.points.length > 0 ? tree.points.map((item) => {
      return `${item.displacementPointId}_${item.xcoordinate},${item.ycoordinate},${item.zcoordinate}_${item.xrotation},${item.yrotation},${item.zrotation}_${item.number}_${item.xaxisDesign},${item.yaxisDesign},${item.zaxisDesign}_${offset}`;
    }).join(':') : '';
    const info = `${system ? system : ''}|${supports}|${points}`;
    const result = formatResult(tree, types, results);

    return { info: info, result: result };
  };

  const formatResult = (tree, types, results) => {
    const type = types === 'inspection' ? 'supports' : 'points';
    let result = `${types === 'inspection' ? 0: 1}|`;
    if ( tree[type].length > 0 && results.length > 0  ) {
      const id = `${types === 'inspection' ? 'pipeSupport' : 'displacementPoint'}Id`;
      const answer = results.filter((item) => tree[type].map((info) => { return info[id] }).includes(item[id]));
      const value = answer.map((item) => {
        if ( types === 'inspection' ) {
          return `${item.pipeSupportId}_${item.position}`;
        } else {
          return `${item.displacementPointId}_${item.xaxis},${item.yaxis},${item.zaxis}`;
        }
      }).join(':');
      result = `${types === 'inspection' ? 0: 1}|${value}`;
    }
    return result;
  };

  return (
    <TreeWrapper>

      <ScrollBar>
        <div>

          <div>
            <DateBox
              type='date'
              value={startDate}
              onValueChanged={(event) => setStartDate(event.value)}
              displayFormat='yyyy.MM'
              calendarOptions={{
                maxZoomLevel: 'year',
                minZoomLevel: 'century'
              }}
              openOnFieldClick={true}
            />
            <p>~</p>
            <DateBox
              type='date'
              value={endDate}
              onValueChanged={(event) => setEndDate(event.value)}
              displayFormat='yyyy.MM'
              calendarOptions={{
                maxZoomLevel: 'year',
                minZoomLevel: 'century'
              }}
              openOnFieldClick={true}
            />
          </div>

          <select defaultValue={type} onChange={(event) => setType(event.target.value)}>
            <option value='inspection'>점검 결과</option>
            <option value='measurement'>측정 결과</option>
          </select>

          <button disabled={unit ? false : true} onClick={() => handleHistories()}>검색</button>

          <button id='history' onClick={() => setVisible(true)} type='button' className='selected' >
            { histories.empty ? '목록이 없습니다.' : history }
          </button>
          
          <Popover
            target='#history'
            visible={visible}
            hideOnOutsideClick={true}
            onHiding={() => setVisible(false)}
          >
            { histories.empty ? (
              <p>목록이 없습니다.</p>
            ) : (
              <>
                { histories.content.map((item) => (
                  <button
                    key={item.number}
                    onClick={() => {
                      setVisible(false);
                      setHistory(`${item[`${histories.type}At`]} (${item.pipeStatus})`);
                      handleResults(item[`${histories.type}Id`]);
                    }}
                  >
                    {`${item[`${histories.type}At`]} (${item.pipeStatus})`}
                  </button>
                )) }
                { !histories.last && <button onClick={() => handleHistories(true)}>더보기</button>}
              </>
            )}
          </Popover>

          <select
            disabled={unit ? false : true}
            defaultValue={system}
            onChange={(event) => {
              setSystem(event.target.value);
              handleSystem(event.target.value);
            }}
          >
            { systems.length > 0 ? systems.map((item) => (
              <option value={item.pipeSystemId} key={item.pipeSystemId}>{ item.name }</option>
            )): (
              <option>목록이 없습니다.</option>
            )}
          </select>

          <SearchField
            value={markNumber}
            onChange={setMarkNumber}
            onKeyDown={handleSearch}
            onInitialize={handleSearch}
            disabled={ system ? false : true }
            placeholder='Mark Number를 입력하세요.'
          />

          <DataGrid
            dataSource={histories.type !== null ? histories.type === 'inspection' ? tree.supports : tree.points : tree.supports}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
            dataRowRender={({data}) =>
              <TableRow
                row={data}
                type={histories.type}
                selected={selected}
                setSelected={setSelected}
                hides={hides}
                setHides={setHides}
                handleRequest={handleRequest}
                setDetails={setDetails}
              />
            }
          >
            <Column caption='No.' dataField='number' alignment='center' />
            <Column caption='SYSTEM' dataField='pipeSystemName' alignment='center' />
            <Column caption='MARK NUMBER' dataField='markNumber' alignment='center' />
            <Column />
          </DataGrid>

        </div>
      </ScrollBar>

      <div>
        <button onClick={() => setActive(!active)} />
      </div>

    </TreeWrapper>
  );
}

function TableRow({ row, type, selected, setSelected, hides, setHides, handleRequest, setDetails }) {
  const navigate  = useNavigate();

  const id = type === 'inspection' ? 'pipeSupportId' : 'displacementPointId';
  const request = type === 'inspection' ? 'PipingSupport' : 'DisplacementPoint';
  
  const [ visible, setVisible ] = useState(false);

  const handleChecked = (checked) => {
    if ( checked ) {
      handleRequest('RequestHideModel', `${type === 'inspection' ? 0 : 1}|${row[id]}`);
      setHides([...hides, row[id]]);
    } else if ( !checked && hides.includes(row[id]) ) {
      handleRequest(`RequestVisible${request}`, row[id]);
      setHides(hides.filter((item) => item !== row[id]));
    }
  };

  return (
    <tr
      id={`more${row.number}`}
      className={Number(selected) === row[id] ? 'active' : ''}
      onClick={() => {
        setSelected(row[id]);
        handleRequest(`RequestSelect${request}FromReact`, row[id]);
      }}
      onDoubleClick={() => handleRequest(`RequestFocus${request}`, row[id])}
      onContextMenu={(event) => {
        event.preventDefault();
        setVisible(true);
      }}
    >
      <td>{row.number}</td>
      <td>{row.pipeSystemName}</td>
      <td>{row.markNumber}</td>
      <td>
        <input type='checkbox' id={`checkbox${row.number}`} checked={hides.includes(row[id])} onChange={(event) => handleChecked(event.target.checked)} />
        <label htmlFor={`checkbox${row.number}`} />

        <Popover
          target={`#more${row.number}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button onClick={() => setDetails(row[id])}>
            상세 보기
          </button>
          <button onClick={() => navigate('/data-analysis', {state: {type: type, id: row[id]}})}>
            데이터 분석
          </button>
          { type === 'inspection' &&
            <button onClick={() => navigate('/drawing', {state: { id: row[id] }})}>
              도면
            </button>
          }
        </Popover>
      </td>
    </tr>
  );
}