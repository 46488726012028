import axios from 'axios';
import store, { persistor, DELETE_TOKEN, DELETE_ACCOUNT, DELETE_UNIT, SET_TOKEN } from '../../store';

// ----------------------------------------------------------------------

const reissue = async (config) => {
  return await axios.post('/api/auth/reissue')
    .then((response) => {
      const token = response.data.data.accessToken;
      config.headers.Authorization = `Bearer ${token}`;
      store.dispatch( SET_TOKEN(token) );
    })
    .catch(() => {
      store.dispatch( DELETE_TOKEN() );
      store.dispatch( DELETE_ACCOUNT() );
      store.dispatch( DELETE_UNIT() );
      persistor.purge();
      return window.location.href = '/';
    });
};

export default reissue;