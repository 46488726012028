import { useState } from 'react';
// @devextreme
import { Popover } from 'devextreme-react/popover';

// ----------------------------------------------------------------------

export default function TableRow({ row, setDialog, setForm }){

  const unit = row.unitId ? true : false;
  const type = !unit ? '발전소' : '호기';
  const id = !unit ? 'plantId' : 'unitId';

  const [ visible, setVisible ] = useState(false);

  return (
    <>
      <button id={`more${row.id}`} className='more' onClick={() => setVisible(true)} />

      <Popover
        target={`#more${row.id}`}
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        position='left'
      >
        <button onClick={() => {
          setVisible(false);
          setForm({
            visible: true,
            target: type,
            type: '수정',
            state: type === '발전소'? {id: row[id], name: row.name} : {id: row[id], name: row.name, plantId: row.plantId, plantName: row.plantName}
        });
        }}>
          수정
        </button>
        <button onClick={() => {
          setVisible(false);
          setDialog({visible: true, id: row[id], type: type });
        }}>
          삭제
        </button>
        { !unit &&
          <button onClick={() => {
            setVisible(false);
            setForm({
              visible: true,
              target: '호기',
              type: '등록',
              state: {plantId: row.plantId, plantName: row.plantName}
            });
          }}>
            호기 등록
          </button>
        }
      </Popover>
    </>
  );
}