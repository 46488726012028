import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getPipe = async (setData, unitId) => {
  return await axios.get(`pipe-systems/search/${unitId}`)
    .then((response) => {
      response.data.data = response.data.data.map((item) => {
        if ( item.pipeLines ) item.pipeLines = item.pipeLines.map((line) => ({...line, name: line.pipeLineName, systemId: item.pipeSystemId, systemName: item.pipeSystemName}));
        return { ...item, name: item.pipeSystemName };
      });
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getPipe;