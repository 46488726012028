import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const createSupport = async (fields, file) => {
  const formData = new FormData();
  formData.append('info', new Blob([JSON.stringify(fields)], {type: 'application/json'}));
  if ( file ) formData.append('drawingFile', file);

  return await axios.post('pipe-supports', formData)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createSupport;