import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Wrapper } from './styles';

// ----------------------------------------------------------------------

Breadcrumbs.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.array
};

// ----------------------------------------------------------------------

export default function Breadcrumbs({ heading, links }) {

  const breadcrumbs = links.map((item) => {
    if ( item.link ) {
      return <li key={item.name}><Link to={item.link}>{ item.name }</Link></li>;
    } else {
      return <li key={item.name}><p>{ item.name }</p></li>;
    }
  })

  return (
    <Wrapper>
      <p>
        { heading }
      </p>

      <ul>
        { breadcrumbs }
      </ul>
    </Wrapper>
  );
}