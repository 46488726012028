import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getAnalysisList = async (unitId, type, markNumber, page) => {
  return await axios.get(`data-analyses/search-slice/unit/${unitId}/${type}-tree-list?markNumber=${encodeURIComponent(markNumber)}&page=${page}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getAnalysisList;