import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// apis
import { getTempPassword } from '../../../apis';

// ----------------------------------------------------------------------

export default function TableRow({ row, account, setDialog, setTempPassword }){
  const navigate  = useNavigate();

  const admin = account.role === '관리자' ? true : false;

  // 본인 계정이거나 마스터 권한으로 마스터, 관리자의 계정은 접근 불가
  const disabled = account.email === row.email || ( account.role === '마스터' && (row.role === '마스터' || row.role === '관리자') ) ? true : false;

  const [ visible, setVisible ] = useState(false);
  
  return (
    <tr>
      { admin &&
        <td>{ row.groupName }</td>
      }
      <td>{row.name}</td>
      <td>{row.email}</td>
      <td>{row.affiliation}</td>
      <td>{row.role}</td>
      <td>{row.denied ? '비활성화' : '활성화'}</td>
      <td>{row.locked ? '차단' : '미차단'}</td>
      <td>{row.createdAt}</td>
      <td>
        <button id={`more${row.id}`} className='more' onClick={() => setVisible(true)} />

        <Popover
          target={`#more${row.id}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          
          <button
            disabled={disabled}
            onClick={() => navigate('update', {state: row})}
          >
            수정
          </button>

          <button
            disabled={disabled}
            onClick={() => {
              setVisible(false);
              setDialog({visible: true, id: row.id, type: '삭제' });
            }}
          >
            삭제
          </button>

          <button
            disabled={disabled}
            onClick={() => {
              setVisible(false);
              setDialog({visible: true, id: row.id, type: row.denied ? '활성화' : '비활성화' });
            }}
          >
            계정 {row.denied ? '활성화' : '비활성화'}
          </button>

          <button
            disabled={disabled}
            onClick={() => {
              setVisible(false);
              getTempPassword(setTempPassword, row.id);
            }}
          >
            임시 비밀번호 발급
          </button>

          { row.locked &&
            <button
              disabled={disabled}
              onClick={() => {
                setVisible(false);
                setDialog({visible: true, id: row.id, type: '차단 해제' });
              }}
            >
              차단 해제
            </button>
          }

        </Popover>
      </td>
    </tr>
  );
}