import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getAnalysisResult = async (setResult, type, id, resultIdList, movementResult) => {
  const request = type === 'inspection' ?
    `data-analyses/search/pipe-support/${id}/inspection?inspectionIdList=${resultIdList}&movementResult=${movementResult}`
    : `data-analyses/search/displacement-point/${id}/measurement?measurementIdList=${resultIdList}`;

  return await axios.get(request)
    .then((response) => {
      setResult(response.data.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getAnalysisResult;