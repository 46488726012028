import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Overlay = styled.div(
  {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
  }
);

export const Wrapper = styled.div(
  {
    width: 437,
    maxHeight: 'calc(100% - 20px)',
    backgroundColor: '#ffffff',
    borderRadius: 20,
    padding: 30,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '12px',
      height: '12px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(99, 115, 129, 0.3)',
      borderRadius: '6px',
      backgroundClip: 'padding-box',
      border: '4px solid transparent'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    }
  },
  (props) => props.$detailed && ({
    width: 589,
    [`${Content}`]: {
      padding: '20px 0',
      border: '1px solid #DDDDDD',
      borderRadius: 30,
      '> div': {
        display: 'flex',
        margin: '0 25px',
        borderBottom: '1px solid #EEEEEE',
        div: {
          width: '50%',
        },
        'p': {
          textAlign: 'left',
          fontSize: 15,
          fontWeight: 'bold',
          marginBottom: 15,
          span: {
            fontSize: 13,
            fontWeight: 'normal',
            color: '#9AB1C1'
          }
        },
        'p:first-child': {
          marginTop: 15
        }
      },
      '> div:first-child': {
        margin: 0,
        padding: '0 25px',
        borderColor: '#AAAAAA',
        'p': {
          marginTop: 0
        },
        'p:first-child': {
          fontSize: 18
        }
      },
      '> div:last-child': {
        borderBottom: 'none'
      },
      '> div:not(.fixed):last-child p': {
        marginBottom: 0
      }
    },
    [`${Actions}`]: {
      borderTop: 'none'
    }
  })
);

export const Title = styled.p(
  {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 25
  }
);

export const Content = styled.div(
  {
    '& > *:not(form)': {
      textAlign: 'center'
    },
    '> p:nth-child(2)': {
      fontSize: 16,
      color: '#666666',
      paddingBottom: 10
    },
    '> p:last-child': {
      fontSize: 18,
      color: '#212121',
      paddingBottom: 25
    },
    '.selected': {
      width: '100%',
    },
    'form > *:not(.label)': {
      marginBottom: 10
    },
    'form > *:last-child': {
      marginBottom: 0
    },
    '.dx-calendar': {
      width: '100%'
    },
    '.dialog-data-grid': {
      marginTop: 20
    },
    '.flex-container': {
      '> p:first-child': {
        fontSize: 17,
        fontWeight: 'bold',
        paddingBottom: 15,
        borderBottom: '1px solid #AAAAAA',
      },
      '> div:not(:last-child), .split': {
        display: 'flex',
        alignItems: 'flex-end'
      },
      '.merge > div:first-child': {
        width: '100%'
      },
      '.merge > div:last-child': {
        width: 70,
        marginLeft: 10
      },
      '> div:not(.merge, :last-child), .split': {
        justifyContent: 'space-between'
      },
      '> div:not(.merge, :last-child, .split) > div': {
        width: '49.5%'
      },
      '.split > div': {
        width: '32.7%',
        'p:first-child': {
          fontSize: 12,
          color: '#666666'
        }
      }
    }
  },
  (props) => props.$pt && ({
    paddingTop: props.$pt
  }),
  (props) => props.$password && ({
    '> p:nth-child(2)': {
      fontSize: 18,
      color: '#00A76F',
      paddingBottom: 0
    },
    'div.password': {
      width: '100%',
      backgroundColor: '#F5F5F5',
      borderRadius: 8,
      fontSize: 18,
      fontWeight: 'bold',
      height: 50,
      margin: '30px 0 25px',
      lineHeight: '50px'
    },
    '> p:last-child': {
      fontSize: 14,
      color: '#666666',
      lineHeight: '180%'
    }
  })
);

export const Icon = styled.div(
  {
    width: 48,
    height: 48,
    backgroundSize: '100% auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    margin: '20px auto',
  },
  (props) => ({
    backgroundImage: `url(/assets/dialog/${props.$name}.png)`,
  })
);

export const Actions = styled.div(
  {
    width: '100%',
    borderTop: '1px solid #EEEEEE',
    marginTop: 30,
    paddingTop: 20,
    div: {
      textAlign: 'center'
    },
    'button': {
      width: 100,
      height: 42,
      borderRadius: 8,
      backgroundColor: '#078DEE',
      color: '#FFFFFF',
      fontSize: 16
    }
  },
  (props) => props.$cancel && ({
    'button:last-child': {
      backgroundColor: '#EBF5FD',
      color: '#078DEE',
      marginLeft: 10
    }
  })
);

export const Message = styled.p(
  {
    color: '#FF5630',
    fontSize: 13,
    lineHeight: '18px',
    marginBottom: 10,
    '&:not(.check):before': {
      content: '""',
      display: 'inline-block',
      verticalAlign: 'top',
      width: 16,
      height: 18,
      marginRight: 5,
      backgroundImage: 'url(/assets/message_error.png)',
      backgroundSize: '16px 16px',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    },
    '&.check': {
      color: '#078DEE'
    }
  }
);