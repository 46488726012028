import { useState, useEffect } from 'react';
// store
import { useSelector } from '../../../store';
// sections
import TableRow from './TableRow';
import Form from './Form';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DateBox from 'devextreme-react/date-box';
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, SearchWrapper, Message } from '../../styles';
// apis
import { getReport, createReport, updateReport, deleteReport } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 30, 50];

// ----------------------------------------------------------------------

export default function ReportManagementPage() {

  const unit = useSelector(state => state.unit);

  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate);
  defaultStartDate.setFullYear( defaultEndDate.getFullYear() - 2 );

  const [ disabled, setDisabled ] = useState(true);

  const [ startDate, setStartDate ] = useState(defaultStartDate);
  const [ endDate, setEndDate ] = useState(defaultEndDate);

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ type, setType ] = useState('title');
  const [ keyword, setKeyword ] = useState('');
  
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(30);

  const [ message, setMessage ] = useState(null);

  const [ form, setForm ] = useState({ visible: false });
  const [ dialog, setDialog ] = useState({ visible: false });

  useEffect(() => {
    if ( unit ) {
      ( async () =>
        await getReport(setData, unit, startDate, endDate, search(), page, size)
      )();
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [unit]);

  const search = (reset) => {
    if ( reset ) {
      return { title: '', author: '' };
    } else {
      return {
        title: type==='title'? keyword : '',
        author: type==='author'? keyword : ''
      };
    }
  };

  const handleSearch = () => {
    let difference = Math.abs( startDate.getTime() - endDate.getTime() );
    difference = Math.ceil( difference / (1000 * 60 * 60 * 24) );

    if ( startDate > endDate ) return setMessage('시작일은 종료일 이전이어야 합니다.');
    if ( difference > 1094 ) return setMessage('3년 이내로 검색해주세요.');

    setPage(0);
    setMessage(null);
    if ( keyword.length > 0 && keyword.replace(/ /g, '').length === 0 ) return;
    getReport(setData, unit, startDate, endDate, search(), 0, size);
  };

  const handlePage = (value) => {
    setPage(value-1);
    getReport(setData, unit, startDate, endDate, search(), value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getReport(setData, unit, startDate, endDate, search(), 0, Number(event.target.value));
  };

  const handleAction = async (title, file) => {
    if ( form.type === '등록' ) {
      await createReport(unit, title, file)
        .then(() => {
          setForm({ visible: false });
          handleReset();
        });
    } else {
      await updateReport(form.state.reportId, title, file)
        .then(() => {
          setForm({ visible: false });
          handleReset()
        });
    }
  };

  const handleDelete = async () => {
    await deleteReport(dialog.id)
      .then(() => handleReset());
  };

  const handleReset = () => {
    setKeyword('');
    setPage(0);
    getReport(setData, unit, startDate, endDate, search(true), 0, size);
  };

  return (
    <Container>
      <Content>
        <Breadcrumbs
          heading='보고서'
          links={[
            { name: '데이터 관리' },
            { name: '보고서' }
          ]}
        />

        <Box $admin>

          <SearchWrapper>
            { message &&
              <Message $info $search>{ message }</Message>
            }

            <div>
              <DateBox
                type='date'
                value={startDate}
                onValueChanged={(event) => setStartDate(event.value)}
                displayFormat='yyyy.MM.dd'
                openOnFieldClick={true}
              />
              <DateBox
                type='date'
                value={endDate}
                onValueChanged={(event) => setEndDate(event.value)}
                displayFormat='yyyy.MM.dd'
                openOnFieldClick={true}
              />
              <select disabled={disabled} defaultValue={type} onChange={(event) => setType(event.target.value)}>
                <option value='title'>제목</option>
                <option value='author'>작성자</option>
              </select>

              <SearchField
                value={keyword}
                onChange={setKeyword}
                onKeyDown={handleSearch}
                onInitialize={handleSearch}
                disabled={ disabled }
                placeholder='검색어를 입력하세요.'
              />
            </div>
            
            <div>
              <button className='register' disabled={disabled} onClick={() => setForm({visible: true, type: '등록'})} >등록</button>
            </div>
          </SearchWrapper>

          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
            dataRowRender={({data}) => <TableRow row={data} setForm={setForm} setDialog={setDialog} />}
          >
            <Column caption='번호' dataField='number' alignment='center' />
            <Column caption='제목' dataField='title' alignment='center' />
            <Column caption='종류' dataField='reportType' alignment='center' />
            <Column caption='작성자' dataField='author' alignment='center' />
            <Column caption='수정일' dataField='modifiedAt' alignment='center' />
            <Column />
          </DataGrid>

          <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

          { form.visible &&
            <Form form={form} setOpen={setForm} handleAction={handleAction} />
          }

          { dialog.visible &&
            <Dialog
              title='보고서 삭제'
              actions={
                <>
                  <button onClick={() => {
                    setDialog({visible: false});
                    handleDelete();
                  }}>
                    삭제
                  </button>
                  <button onClick={() => setDialog({visible: false})}>
                    취소
                  </button>
                </>
              }
              type='confirm'
            >
              <p>선택하신 보고서를<br />삭제하시겠습니까?</p>
            </Dialog>
          }

        </Box>
      </Content>
    </Container>
  );
}