import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const getTempPassword = async (setTempPassword, memberId) => {
  return await axios.post('members/issuance/temp-password', {memberId})
    .then((response) => {
      setTempPassword({ visible: true, password: response.data.data.tempPassword });
    })
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'member'),
        action: null
      }) );
    });
};

export default getTempPassword;