import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const deleteMemberGroupPlant = async (id) => {
  return await axios.delete(`member-group-plants/${id}`)
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'member_group_plant'),
        action: null
      }) );
    });
};

export default deleteMemberGroupPlant;