import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getSupportType = async (setData) => {
  return await axios.get('pipe-support-types/search')
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getSupportType;