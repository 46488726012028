import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getDrawingList = async (unitId, markNumber, page) => {
  return await axios.get(`drawings/search-slice/unit/${unitId}/tree-list?markNumber=${encodeURIComponent(markNumber)}&page=${page}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getDrawingList;