import axios from '../utils/axios';
import message from '../../utils/message';

// ----------------------------------------------------------------------

const changePasswordExpiration = async () => {
  return await axios.put('accounts/change/password/next-time')
    .catch((error) => {
      return Promise.reject(
        new Error(message(error))
      );
    });
};

export default changePasswordExpiration;