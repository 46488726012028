import { useState, useEffect } from 'react';
// sections
import SearchSupports from './SearchSupports';
// components
import { Dialog } from '../../../components/dialog';
import Select from '../../../components/select-box';
import TextField from '../../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// styles
import { Check, Message } from '../../styles';
// apis
import { getPointProp, checkPointNumber } from '../../../apis';

// ----------------------------------------------------------------------
  
const schema = yup.object().shape({
  pipeSystemId: yup.number()
    .required(),
  pipeLineId: yup.number()
    .required(),
  number: yup.number()
    .required()
    .min(1, '1 - 9,223,372,036,854,775,807 사이로 입력해주세요.')
    .max(9223372036854775807, '1 - 9,223,372,036,854,775,807 사이로 입력해주세요.'),
  xAxisDesign: yup.number()
    .required(),
  yAxisDesign: yup.number()
    .required(),
  zAxisDesign: yup.number()
    .required(),
  xCoordinate: yup.number()
    .required(),
  yCoordinate: yup.number()
    .required(),
  zCoordinate: yup.number()
    .required(),
  xRotation: yup.number()
    .required(),
  yRotation: yup.number()
    .required(),
  zRotation: yup.number()
    .required()
});

// ----------------------------------------------------------------------

export default function PointForm({ form, setOpen, unit, handleAction }) {

  const {
    control,
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      pipeSystemId: form.state?.pipeSystemId || '',
      pipeLineId: form.state?.pipeLineId || '',
      number: form.state?.number || 1,
      xAxisDesign: form.state?.xaxisDesign || 0,
      yAxisDesign: form.state?.yaxisDesign || 0,
      zAxisDesign: form.state?.zaxisDesign || 0,
      xCoordinate: form.state?.xcoordinate || 0,
      yCoordinate: form.state?.ycoordinate || 0,
      zCoordinate: form.state?.zcoordinate || 0,
      xRotation: form.state?.xrotation || 0,
      yRotation: form.state?.yrotation || 0,
      zRotation: form.state?.zrotation || 0
    }
  });

  const [ state, setState ] = useState(form.state || null);

  const [ prop, setProp ] = useState({ pipeSystem: [], pipeSupportType: [] });
  const [ system, setSystem ] = useState(undefined);
  const [ line, setLine ] = useState(undefined);
  const [ support, setSupport ] = useState({ id: null, markNumber: null });

  const [ apply, setApply ] = useState(false);
  const [ auto, setAuto ] = useState(true);

  useEffect(() => {
    ( async () =>
      await getPointProp(setProp, unit.id)
    )();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( prop.pipeSystem.length > 0 ) {
      const systemId = form.state ? form.state.pipeSystemId : prop.pipeSystem[0].id;
      setSystem(systemId);
      setValue('pipeSystemId', systemId);
    }
    setValue('number', form.state ? form.state.number : prop.nextNumber);
    // eslint-disable-next-line
  }, [prop]);

  useEffect(() => {
    if ( system ) {
      const lineId = state?.pipeLineId ? state.pipeLineId : prop.pipeSystem.find((item) => item.id === Number(system)).pipeLine[0].id;
      setLine(lineId);
      setValue('pipeLineId', lineId);
      if ( state ) setState({ ...state, pipeLineId: null });
    }
    // eslint-disable-next-line
  }, [system]);

  const [ checked, setChecked ] = useState(null);
  const [ checkedNumber, setCheckedNumber ] = useState(null);

  const [ message, setMessage ] = useState({support: null, check: null});

  const onSubmit = async (data, event) => {
    if ( event ) {
      // 중복 체크
      await checkPointNumber(unit.id, data.number)
        .then((response) => {
          setChecked(response);
          setCheckedNumber(data.number);
          setMessage({...message, check: response ? '이미 등록된 일련번호입니다.' : '사용하실 수 있는 일련번호입니다.'});
        })
        .catch(() => {
          setMessage({...message, check: '나중에 다시 시도해 주세요.'});
        });
    } else {
      if ( !auto && (checked === null || checkedNumber !== data.number) ) {
        setChecked(null);
        setMessage({...message, check: '중복 체크 후 진행하실 수 있습니다.'});
      } else if ( (auto || checked === false) && ( !apply || support.id !== null ) ) {
        const fields = Object.assign({}, data);
        fields.appliedPipeSupport = apply;
        fields.pipeSupportId = support.id !== null ? support.id : 1;
        if ( form.type === '등록' ) {
          fields.autoNumber = auto;
          fields.unitId = unit.id;
        } else if ( form.type === '수정' && auto ) {
          fields.number = form.state.number;
        }
        await handleAction(fields)
          .catch((error) => setMessage({...message, support: error.message}));
      }
    }
  };

  return (
    <Dialog
      title={`변위 측정 위치 ${form.type}`}
      actions={
        <>
          <button onClick={() => handleSubmit((data, event) => onSubmit(data, event))()}>
            {form.type}
          </button>
          <button onClick={() => setOpen({ visible: false })}>
            취소
          </button>
        </>
      }
    >
        
      <form onSubmit={handleSubmit(onSubmit)} className='flex-container'>
        <p>{ unit.name }</p>

        <div className='merge'>
          <Check $pages>
            <TextField
              name='number'
              label='Number'
              type='number'
              control={control}
              disabled={auto}
            />
            <button disabled={auto}>중복 체크</button>
          </Check>

          <div>
            { form.type === '등록' ? (
              <>
                <input type='checkbox' id='checkbox' checked={auto} onChange={(event) => setAuto(event.target.checked)} />
                <label for='checkbox'>
                  <span>Auto</span>
                </label>
              </>
            ) : (
              <>
                <input type='checkbox' id='checkbox' checked={!auto} onChange={(event) => setAuto(!event.target.checked)} />
                <label for='checkbox'>
                  <span>수정</span>
                </label>
              </>
            )}
          </div>
        </div>
        { message.check &&
          <Message className={message.check === '사용하실 수 있는 일련번호입니다.' && 'check'}>{ message.check }</Message>
        }

        <div>
          <Select
            name='pipeSystemId'
            label='System'
            control={control}
            value={system}
            onChange={(event) => {
              setSystem(event.target.value);
              setValue('pipeSystemId', event.target.value);
            }}
          >
            { prop.pipeSystem.length > 0 ? (
              prop.pipeSystem.map((system) => (
                <option key={system.id} value={system.id}>{system.name}</option>
              ))
            ) : (
              <option value=''>시스템을 등록해주세요.</option>
            )}
          </Select>

          <Select
            name='pipeLineId'
            label='Line'
            control={control}
            value={line}
            onChange={(event) => {
              setLine(event.target.value);
              setValue('pipeLineId', event.target.value);
            }}
          >
            { system ? (
              prop.pipeSystem.find((item) => item.id === Number(system)).pipeLine.map((line) => (
                <option key={line.id} value={line.id}>{line.name}</option>
              ))
            ) : (
              <option value=''>라인을 등록해주세요.</option>
            )}
          </Select>
        </div>

        <div className='merge'>
          <div>
            <SearchSupports
              unitId={unit.id}
              state={state}
              setState={setState}
              type={form.type}
              system={system}
              line={line}
              disabled={!apply}
              support={support}
              setSupport={setSupport}
            />
          </div>

          <div>
            <input type='checkbox' id='checkboxApply' checked={apply} onChange={(event) => setApply(event.target.checked)} />
            <label for='checkboxApply'>
              <span>등록</span>
            </label>
          </div>
        </div>
        { message.support &&
          <Message>{ message.support }</Message>
        }

        <p className='label'>Axis Design</p>
        <div className='split'>
          <TextField
            name='xAxisDesign'
            label='X'
            type='number'
            control={control}
          />

          <TextField
            name='yAxisDesign'
            label='X'
            type='number'
            control={control}
          />

          <TextField
            name='zAxisDesign'
            label='Z'
            type='number'
            control={control}
          />
        </div>

        <p className='label'>Position</p>
        <div className='split'>
          <TextField
            name='xCoordinate'
            label='X'
            type='number'
            control={control}
          />

          <TextField
            name='yCoordinate'
            label='Y'
            type='number'
            control={control}
          />

          <TextField
            name='zCoordinate'
            label='Z'
            type='number'
            control={control}
          />
        </div>

        <div className='split'>
          <TextField
            name='xRotation'
            label='Rotation X'
            type='number'
            control={control}
          />

          <TextField
            name='yRotation'
            label='Rotation Y'
            type='number'
            control={control}
          />

          <TextField
            name='zRotation'
            label='Rotation Z'
            type='number'
            control={control}
          />
        </div>
      </form>
    </Dialog>
  );
}