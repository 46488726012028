import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// store
import { useSelector } from '../../../store';
// sections
import TreeList from './TreeList';
// components
import { Detailed } from '../../../components/dialog';
// styles
import { Container, Content, Box } from '../../styles';

// ----------------------------------------------------------------------

export default function DashboardPage() {

  const location = useLocation();

  const unit = useSelector(state => state.unit);
  const token = useSelector(state => state.token);

  const unity = useRef(null);
  const [ instance, setInstance ] = useState(null);

  const results = useRef(null);

  const [ selected, setSelected ] = useState(null);

  const [ details, setDetails ] = useState(false);
  const [ dialog, setDialog ] = useState({ visible: false });

  const [ hide, setHide ] = useState(null);
  const [ hides, setHides ] = useState([]);
  
  const [ active, setActive ] = useState(false);

  useEffect(() => {
    if ( location.state?.submit ) {
      const handleEvent = () => window.history.pushState(null, '', location.href);
      window.history.pushState( null, '', location.href );
      window.addEventListener('popstate', handleEvent);
      return () => window.removeEventListener('popstate', handleEvent);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( hide && !hides.includes(Number(hide)) ) setHides([...hides, hide]);
    // eslint-disable-next-line
  }, [hide]);

  useEffect(() => {
    if ( unit ) {
      window.addEventListener('message', handleEventListener);
      return () => window.removeEventListener('message', handleEventListener);
    }
    // eslint-disable-next-line
  }, [unit]);

  const handleSystemInfo = (info, result) => {
    const value = `${process.env.REACT_APP_API_KEY}|${token}|${unit}|${info}`;
    unity.current.contentWindow?.postMessage({response: 'ResponseChangeSystem', parameter: value}, '*');
    results.current = result;
  };

  const handleResults = (result) => {
    unity.current.contentWindow.postMessage({response: 'RequestChangeResult', parameter: result}, '*');
  };

  const handleRequest = (response, id) => {
    unity.current.contentWindow.postMessage({response: response, parameter: id}, '*');
  };

  const handleEventListener = (event) => {
    const { request, parameter } = event.data;

    if ( request === 'RequestChangeSystem' ) return setInstance(true);
    if ( request === 'ResponseChangeOK' ) return handleResults(results.current);
    if ( request === 'RequestSelectFromUnity' ) return setSelected(parameter);
    if ( request === 'RequestHide' ) return setHide(Number(parameter));
    if ( request === 'RequestInformation' ) return setDetails(parameter);
  };

  return (
    <Container className={active ? 'active' : ''} $view>
      <TreeList
        unit={unit}
        instance={instance}
        onSystemInfo={handleSystemInfo}
        onResults={handleResults}
        selected={selected}
        setSelected={setSelected}
        details={details}
        setDetails={setDetails}
        setDialog={setDialog}
        hides={hides}
        setHides={setHides}
        handleRequest={handleRequest}
        active={active}
        setActive={setActive}
      />

      <Content $unity>
        <Box>
          <iframe title='unity iframe' src='./unity.html' ref={unity} />
        </Box>
        
        { dialog.visible &&
          <Detailed
            details={dialog.details}
            actions={
              <button onClick={() => {
                setDetails(false);
                setDialog({ visible: false });
              }}>
                확인
              </button>
            }
          />
        }
      </Content>
    </Container>
  );
}