import { useState } from 'react';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { IconButton, FileIcon } from '../../styles';
// apis
import { downloadReport } from '../../../apis';

// ----------------------------------------------------------------------

export default function TableRow({ row, setForm, setDialog }){

  const [ visible, setVisible ] = useState(false);

  let name = 'ic_file_type_4';
  if ( row.reportType === 'HWP' || row.reportType === 'HWPX' ) {
    name = 'ic_file_type_1';
  } else if ( row.reportType === 'PDF' ) {
    name = 'ic_file_type_2';
  } else if ( row.reportType === 'DOC' || row.reportType === 'DOCX' ) {
    name = 'ic_file_type_3';
  }
  
  return (
    <tr>
      <td>{row.number}</td>
      <td>{row.title}</td>
      <td><FileIcon $name={name} /></td>
      <td>{row.author}</td>
      <td>{row.modifiedAt}</td>
      <td>
        <button id={`more${row.number}`} className='more' onClick={() => setVisible(true)} />

        <IconButton $table onClick={() => downloadReport(row.reportId)}><div />다운로드</IconButton>

        <Popover
          target={`#more${row.number}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button
            onClick={() => {
              setVisible(false);
              setForm({visible: true, type: '수정', state: row})
            }}
          >
            수정
          </button>

          <button
            onClick={() => {
              setVisible(false);
              setDialog({visible: true, id: row.reportId});
            }}
          >
            삭제
          </button>
        </Popover>
      </td>
    </tr>
  );
}