import { useState, useEffect } from 'react';
// components
import SearchField from '../../../components/search-field';
import { Dialog } from '../../../components/dialog';
import Pagination from '../../../components/pagination';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { TagField } from '../../styles';
// apis
import { getUnregisteredPlant } from '../../../apis';

// ----------------------------------------------------------------------

export default function Form({ setOpen, memberGroupId, handleCreate }) {
  
  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ name, setName ] = useState('');
  const [ page, setPage ] = useState(0);

  const [ memberGroupPlants, setMemberGroupPlants ] = useState([]);
  const [ selected, setSelected ] = useState([]);

  useEffect(() => {
    ( async () =>
      await getUnregisteredPlant(setData, memberGroupId, name, page)
    )();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelected(memberGroupPlants.map((item) => { return `${item.plantId}${item.unitId}` }));
  }, [memberGroupPlants]);
  
  const handleSelected = (checked, plant) => {
    if ( checked ) {
      setMemberGroupPlants((current) => [...current, plant]);
    } else {
      handleUnchecked(plant);
    }
  };
  
  const handleUnchecked = (plant) => setMemberGroupPlants(memberGroupPlants.filter((item) => JSON.stringify(item) !== JSON.stringify(plant) ));

  const handleSearch = () => {
    setPage(0);
    if ( name.length > 0 && name.replace(/ /g, '').length === 0 ) return;
    getUnregisteredPlant(setData, memberGroupId, name, 0);
  };

  const handlePage = (value) => {
    setPage(value-1);
    getUnregisteredPlant(setData, memberGroupId, name, value-1);
  };

  return (
    <Dialog
      title='발전소 권한 설정'
      actions={
        <>
          <button onClick={() => {
            setOpen(false);
            handleCreate(memberGroupPlants);
          }}>
            적용
          </button>
          <button onClick={() => setOpen(false)}>
            취소
          </button>
        </>
      }
    >
      <SearchField
        value={name}
        onChange={setName}
        onKeyDown={handleSearch}
        onInitialize={handleSearch}
        placeholder='발전소명을 입력하세요.'
      />

      <DataGrid
        dataSource={rows}
        noDataText=''
        dataRowRender={({data}) => <TableRow row={data} selected={selected} handleChecked={handleSelected} />}
        elementAttr={{class: 'dialog-data-grid'}}
      >
        <Column />
        <Column caption='발전소명' dataField='plantName' alignment='center' />
        <Column caption='호기' dataField='unitName' alignment='center' />
      </DataGrid>

      <Pagination pages={pages} page={page} handlePage={handlePage} />

      <TagField>
        { memberGroupPlants.length > 0 && memberGroupPlants.map((item) => (
          <button
            key={item.unitId}
            onClick={() => handleUnchecked(item)}
          >
            <p>{ item.plantName } { item.unitName }</p>
            <div />
          </button>
        )) }
      </TagField>
    </Dialog>
  );
}

function TableRow({ row, selected, handleChecked }){
  return (
    <tr>
      <td>
        <input
          type='checkbox'
          id={`checkbox${row.unitId}`}
          onChange={(event) => handleChecked(event.target.checked, row)}
          checked={selected.includes(`${row.plantId}${row.unitId}`)}
        />
        <label htmlFor={`checkbox${row.unitId}`} />
      </td>
      <td>{row.plantName}</td>
      <td>{row.unitName}</td>
    </tr>
  );
}