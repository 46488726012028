import { useState, useEffect, useRef } from 'react';
// store
import { useSelector } from '../../../store';
// sections
import SearchDataLists from '../SearchDataLists';
// @devextreme
import DateBox from 'devextreme-react/date-box';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
// @devextreme -- export to excel
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// styles
import { Container, Content, Box, SearchWrapper, IconButton, Message } from '../../styles';
// apis
import { getInitializeInquiry, getInquiry } from '../../../apis';

// ----------------------------------------------------------------------

const column = {
  inspection: [
    { caption: 'Design Hot Load', dataField: 'designHotLoad', alignment: 'center' },
    { caption: 'Design Cold Load', dataField: 'designColdLoad', alignment: 'center' },
    { caption: 'Total Travel', dataField: 'totalTravel', alignment: 'center' },
    { caption: 'Hot Position', dataField: 'hotPosition', alignment: 'center' },
    { caption: 'Cold Position', dataField: 'coldPosition', alignment: 'center' },
    { caption: 'Movement', dataField: 'movement', alignment: 'center' },
    { caption: 'IndicatorType', dataField: 'indicatorType', alignment: 'center' }
  ],
  measurement: [
    { caption: 'Design X Axis', dataField: 'designXAxis', alignment: 'center' },
    { caption: 'Design Y Axis', dataField: 'designYAxis', alignment: 'center' },
    { caption: 'Design Z Axis', dataField: 'designZAxis', alignment: 'center' }
  ]
};

// ----------------------------------------------------------------------

export default function InquiryPage() {
  
  const unit = useSelector(state => state.unit);

  const gridRef = useRef(null);

  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate);
  defaultStartDate.setFullYear( defaultEndDate.getFullYear() - 2 );

  const [ startDate, setStartDate ] = useState(defaultStartDate);
  const [ endDate, setEndDate ] = useState(defaultEndDate);

  const [ type, setType ] = useState('inspection');

  const [ initialize, setInitialize ] = useState(null);
  const [ data, setData ] = useState([]);

  const [ message, setMessage ] = useState(null);

  const [ form, setForm ] = useState({ visible: false });

  const [ table, setTable ] = useState('inspection');

  useEffect(() => {
    if ( unit ) {
      ( async () =>
        await getInitializeInquiry(setInitialize, unit, type, startDate, endDate)
          .catch(() => {
            setInitialize(null);
          })
      )();
    }
    // eslint-disable-next-line
  }, [unit, type]);

  useEffect(() => {
    if ( initialize ) {
      setTable(type);
      setData(type === 'inspection' ? initialize.inspectionDataList : initialize.measurementDataList);
    }
    // eslint-disable-next-line
  }, [initialize]);

  const handleSearch = () => {
    let difference = Math.abs( startDate.getTime() - endDate.getTime() );
    difference = Math.ceil( difference / (1000 * 60 * 60 * 24) );

    if ( startDate > endDate ) return setMessage('시작일은 종료일 이전이어야 합니다.');
    if ( difference > 1094 ) return setMessage('3년 이내로 검색해주세요.');

    setMessage(null);
    const state = { unitId: unit, startDate: startDate, endDate: endDate };
    setForm({visible: true, type: type, state: state });
  };

  const handleSearchResult = (id, at) => {
    getInquiry(setData, unit, type, id, at);
    setTable(form.type);
  };

  const customizeColumns = (columns) => {
    if ( data.length > 0 ) {
      for (let i = 0; i < column[table].length; i++) {
        columns.push(column[table][i]);
      }

      for (let i = 0; i < data[0].result.length; i++) {
        if ( table === 'inspection' ) {
          columns.push({
              caption: `${data[0].result[i].date} (${data[0].result[i].status})`,
              isBand: true,
              alignment: 'center'
          }, {
            caption: 'position',
            ownerBand: columns.length,
            dataField: `${data[0].result[i].date}_position`,
            alignment: 'center'
          }, {
            caption: 'remark',
            ownerBand: columns.length,
            dataField: `${data[0].result[i].date}_remark`,
            alignment: 'center'
          });
        } else {
          columns.push({
              caption: `${data[0].result[i].date} (${data[0].result[i].status})`,
              isBand: true,
              alignment: 'center'
          }, {
            caption: 'X Axis',
            ownerBand: columns.length,
            dataField: `${data[0].result[i].date}_xaxis`,
            alignment: 'center'
          }, {
            caption: 'Y Axis',
            ownerBand: columns.length,
            dataField: `${data[0].result[i].date}_yaxis`,
            alignment: 'center'
          }, {
            caption: 'Z Axis',
            ownerBand: columns.length,
            dataField: `${data[0].result[i].date}_zaxis`,
            alignment: 'center'
          }, {
            caption: 'remark',
            ownerBand: columns.length,
            dataField: `${data[0].result[i].date}_remark`,
            alignment: 'center'
          });
        }
      }
    }
  };

  const handleDownload = () => {
    const date = new Date();
    const name = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}_${String(date.getHours()).padStart(2, '0')}_${ String(date.getMinutes()).padStart(2, '0')}_${String(date.getSeconds()).padStart(2, '0')}`;

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      worksheet,
      component: gridRef.current.instance
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `data-inquiry-${name}.xlsx`);
      });
    });
  };

  return (
    <Container>
      <Content>
        <Box>

          <SearchWrapper>
            { message &&
              <Message $info $search>{ message }</Message>
            }

            <div>
              <select defaultValue={type} onChange={(event) => setType(event.target.value)}>
                <option value='inspection'>점검 결과</option>
                <option value='measurement'>측정 결과</option>
              </select>
              <DateBox
                type='date'
                value={startDate}
                onValueChanged={(event) => setStartDate(event.value)}
                displayFormat='yyyy.MM'
                calendarOptions={{
                  maxZoomLevel: 'year',
                  minZoomLevel: 'century'
                }}
                openOnFieldClick={true}
              />
              <DateBox
                type='date'
                value={endDate}
                onValueChanged={(event) => setEndDate(event.value)}
                displayFormat='yyyy.MM'
                calendarOptions={{
                  maxZoomLevel: 'year',
                  minZoomLevel: 'century'
                }}
                openOnFieldClick={true}
              />
              <button disabled={unit ? false : true} onClick={() => handleSearch()}>검색</button>
            </div>

            <IconButton onClick={() => handleDownload()}><div />다운로드</IconButton>
          </SearchWrapper>

          { form.visible &&
            <SearchDataLists form={form} setOpen={setForm} handleAction={handleSearchResult} />
          }

          <DataGrid
            ref={gridRef}
            elementAttr={{class: 'customize'}}
            dataSource={data}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
            customizeColumns={customizeColumns}
          >
            <Column caption='No.' dataField='number' alignment='center' />
            <Column caption='System' dataField='pipeSystemName' alignment='center' />
            <Column caption='Line' dataField='pipeLineName' alignment='center' />
            <Column caption='Mark Number' dataField='markNumber' alignment='center' />
          </DataGrid>
        
        </Box>
      </Content>
    </Container>
  );
}