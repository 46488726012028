import { useState, useEffect } from 'react';
// components
import { Dialog } from '../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { MoreButton } from '../styles';
// apis
import { searchDataLists } from '../../apis';

// ----------------------------------------------------------------------

export default function SearchDataLists({ form, setOpen, handleAction, target }) {

  const title = form.type === 'inspection' ? '점검 결과' : '측정 결과';

  const [ data, setData ] = useState({ content: [], number: 0, last: true, empty: true });
  
  const [ selected, setSelected ] = useState([]);

  const [ message, setMessage ] = useState(null);

  const [ movement, setMovement ] = useState(false);

  useEffect(() => {
    ( async () =>
      await handleSearch()
    )();
    // eslint-disable-next-line
  }, []);

  const handleSearch = async (more) => {
    await searchDataLists(form.type, form.state, more? data.number + 1 : 0)
      .then((response) => {
        if ( !response.first ) {
          const array = [ ...data.content, ...response.content ];
          response.content = array;
        }
        setData(response);
      })
      .catch(() => {
        setData({ content: [], number: 0, last: true, empty: true });
      });
  };

  const handleChecked = (checked, id) => {
    if ( checked ) {
      setSelected([...selected, id]);
    } else if ( !checked && selected.includes(id) ) {
      setSelected(selected.filter((item) => item !== id));
    }
  };

  const handleSubmit = () => {
    if ( selected.length === 0 ) return setMessage('선택한 목록이 없습니다.');
    const at = data.content.filter((content) => selected.includes(content[`${form.type}Id`])).map((item) => {return {date: item[`${form.type}At`], status: item.pipeStatus}});
    handleAction(selected, at, movement);
    setOpen({ visible: false });
  };

  const max = target === 'analysis' ? 5 : 3;
  const cellRender = ({data}) => {
    return (
      <>
        <input
          id={`checkbox${data[`${form.type}Id`]}`}
          type='checkbox'
          checked={selected.includes(data[`${form.type}Id`]) ? true : false}
          disabled={selected.length >= max && !selected.includes(data[`${form.type}Id`]) ? true : false }
          onChange={(event) => handleChecked(event.target.checked, data[`${form.type}Id`])}
        />
        <label htmlFor={`checkbox${data[`${form.type}Id`]}`} />
      </>
    );
  };

  return (
    <Dialog
      title={title}
      actions={
        <>
          <button onClick={() => handleSubmit()}>
            적용
          </button>
          <button onClick={() => setOpen({ visible: false })}>
            취소
          </button>
        </>
      }
      message={message}
    >

      <DataGrid
        dataSource={data.content}
        noDataText='점검 결과가 없습니다.'
        columnAutoWidth={true}
        paging={{enabled: false}}
      >
        <Column caption='' dataField={`${form.type}Id`} cellRender={cellRender} alignment='center' />
        <Column caption='번호' dataField='number' alignment='center' />
        <Column caption='배관 상태' dataField='pipeStatus' alignment='center' />
        <Column caption='점검일' dataField={`${form.type}At`} alignment='center' />
      </DataGrid>
      
      { !data.last &&
        <MoreButton>
          <button onClick={() => handleSearch(true)}>더보기</button>
        </MoreButton>
      }

      { target === 'analysis' && form.type === 'inspection' &&
        <>
          <input
            type='checkbox'
            id='checkbox'
            onChange={(event) => setMovement(event.target.checked)}
          />
          <label htmlFor='checkbox'><span>MOVEMENT</span></label>
        </>
      }

    </Dialog>
  );
}