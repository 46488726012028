import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const updateSupport = async (pipeSupportId, fields) => {
  return await axios.put(`pipe-supports/${pipeSupportId}`, fields)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateSupport;