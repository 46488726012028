import { useState } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import TextField from '../../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// styles
import { Check } from '../../styles';
// apis
import { checkPlantName, checkUnitName } from '../../../apis';

// ----------------------------------------------------------------------

export default function Form({ form, setOpen, handleAction }) {

  const length = form.target === '발전소' ? 30 : 6;
  const schema = yup.object().shape({
    name: yup.string()
      .required()
      .max(length, `${length}자 이내로 입력해 주세요.`)
  });

  const {
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: form.state?.name || ''
    }
  });

  const [ checked, setChecked ] = useState(null);
  const [ checkedName, setCheckedName ] = useState(null);

  const [ message, setMessage ] = useState(null);

  const onSubmit = async (data, event) => {
    if ( event ) {
      // 중복 체크
      if ( form.target === '발전소' ) {
        await checkPlantName(data.name)
          .then((response) => {
            setChecked(response);
            setCheckedName(data.name);
            setMessage(response ? '이미 등록된 발전소명입니다.' : '사용하실 수 있는 발전소명입니다.');
          })
          .catch(() => {
            setMessage('나중에 다시 시도해 주세요.');
          });
      } else {
        await checkUnitName(form.state.plantId, data.name)
          .then((response) => {
            setChecked(response);
            setCheckedName(data.name);
            setMessage(response ? '이미 등록된 호기명입니다.' : '사용하실 수 있는 호기명입니다.');
          })
          .catch(() => {
            setMessage('나중에 다시 시도해 주세요.');
          });
      }
    } else {
      if ( checked === null || checkedName !== data.name ) {
        setChecked(null);
        setMessage('중복 체크 후 진행하실 수 있습니다.');
      } else if ( checked === false ) {
        await handleAction(data.name);
      }
    }
  };

  return (
    <Dialog
      title={`${form.target} ${form.type}`}
      actions={
        <>
          <button onClick={() => handleSubmit((data, event) => onSubmit(data, event))()}>
            {form.type}
          </button>
          <button onClick={() => setOpen({ visible: false })}>
            취소
          </button>
        </>
      }
      message={message}
    >

      <form onSubmit={handleSubmit(onSubmit)}>
        { form.state?.plantName &&
          <p className='plantName'>{ form.state.plantName }</p>
        }

        <Check>
          <TextField
            name='name'
            type='text'
            control={control}
            placeholder={`${form.target}명을 입력해 주세요.`}
          />
          <button>중복 체크</button>
        </Check>
      </form>
    </Dialog>
  );
}