import { useState, useEffect } from 'react';
// store
import { useSelector } from '../../../store';
// components
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
// @devextreme
import DateBox from 'devextreme-react/date-box';
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, SearchWrapper, IconButton, FileIcon } from '../../styles';
// apis
import { getViewReport, downloadViewReport } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 30, 50];

// ----------------------------------------------------------------------

export default function ReportManagementPage() {

  const unit = useSelector(state => state.unit);

  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate);
  defaultStartDate.setFullYear( defaultEndDate.getFullYear() - 2 );

  const [ disabled, setDisabled ] = useState(true);

  const [ startDate, setStartDate ] = useState(defaultStartDate);
  const [ endDate, setEndDate ] = useState(defaultEndDate);

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ type, setType ] = useState('title');
  const [ keyword, setKeyword ] = useState('');
  
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(30);

  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    if ( unit ) {
      ( async () =>
        await getViewReport(setData, unit, startDate, endDate, search(), page, size)
      )();
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [unit]);

  const search = (reset) => {
    if ( reset ) {
      return { title: '', author: '' };
    } else {
      return {
        title: type==='title'? keyword : '',
        author: type==='author'? keyword : ''
      };
    }
  };

  const handleSearch = () => {
    let difference = Math.abs( startDate.getTime() - endDate.getTime() );
    difference = Math.ceil( difference / (1000 * 60 * 60 * 24) );

    if ( startDate > endDate ) return setMessage('시작일은 종료일 이전이어야 합니다.');
    if ( difference > 1094 ) return setMessage('3년 이내로 검색해주세요.');

    setPage(0);
    setMessage(null);
    if ( keyword.length > 0 && keyword.replace(/ /g, '').length === 0 ) return;
    getViewReport(setData, unit, startDate, endDate, search(), 0, size);
  };

  const handlePage = (value) => {
    setPage(value-1);
    getViewReport(setData, unit, startDate, endDate, search(), value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getViewReport(setData, unit, startDate, endDate, search(), 0, Number(event.target.value));
  };

  const cellFileRender = ({data}) => {
    let name = 'ic_file_type_4';
    if ( data.reportType === 'HWP' || data.reportType === 'HWPX' ) {
      name = 'ic_file_type_1';
    } else if ( data.reportType === 'PDF' ) {
      name = 'ic_file_type_2';
    } else if ( data.reportType === 'DOC' || data.reportType === 'DOCX' ) {
      name = 'ic_file_type_3';
    }
    return <FileIcon $name={name} />
  };

  const cellRender = ({data}) => {
    return <IconButton $table onClick={() => downloadViewReport(data.reportId)}><div />다운로드</IconButton>
  };

  return (
    <Container>
      <Content>
        <Box>
        
          <SearchWrapper>
            <div>
              <DateBox
                type='date'
                value={startDate}
                onValueChanged={(event) => setStartDate(event.value)}
                displayFormat='yyyy.MM.dd'
                openOnFieldClick={true}
              />
              <DateBox
                type='date'
                value={endDate}
                onValueChanged={(event) => setEndDate(event.value)}
                displayFormat='yyyy.MM.dd'
                openOnFieldClick={true}
              />
              <select disabled={disabled} defaultValue={type} onChange={(event) => setType(event.target.value)}>
                <option value='title'>제목</option>
                <option value='author'>작성자</option>
              </select>

              <SearchField
                value={keyword}
                onChange={setKeyword}
                onKeyDown={handleSearch}
                onInitialize={handleSearch}
                disabled={ disabled }
                placeholder='검색어를 입력하세요.'
              />
              <p>{ message }</p>
            </div>
          </SearchWrapper>

          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
          >
            <Column caption='번호' dataField='number' alignment='center' />
            <Column caption='제목' dataField='title' alignment='center' />
            <Column caption='종류' dataField='reportType' alignment='center' cellRender={cellFileRender} />
            <Column caption='작성자' dataField='author' alignment='center' />
            <Column caption='수정일' dataField='modifiedAt' alignment='center' />
            <Column caption='' dataField='reportId' cellRender={cellRender} />
          </DataGrid>

          <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

        </Box>
      </Content>
    </Container>
  );
}