import { useState } from 'react';
// components
import { Dialog } from '../../../components/dialog';
// styles
import { File, Message } from '../../styles';

// ----------------------------------------------------------------------

export default function DrawingForm({ form, setOpen, handleAction }) {

  const [ file, setFile ] = useState(null);

  const [ message, setMessage ] = useState(null);

  const handleFile = (event) => {
    if ( event.target.files.length > 0 ) {
      const file = event.target.files[0];
      const name = file.name.split('.');
      const extension = name[name.length - 1];
      const maxSize = 100 * 1024 * 1024; // 100MB
      if ( extension !== 'pdf' ) {
        setFile(false);
        return setMessage('pdf 파일을 선택해 주세요.');
      }
      if ( file.size > maxSize ) {
        setFile(false);
        return setMessage('100MB 이하의 파일을 선택해 주세요.');
      }
      setFile(file);
      setMessage(null);
    } else {
      setFile(null);
      setMessage(null);
    }
  };

  const onSubmit = async () => {
    if ( file === null ) {
      setMessage('파일을 선택해 주세요.');
    } else if ( file !== false ) {
      await handleAction(form.state.pipeSupportId, file)
        .catch(() => setMessage('도면을 수정할 수 없습니다.'));
    }
  };

  return (
    <Dialog
      title='도면 수정'
      actions={
        <>
          <button onClick={() => onSubmit()}>
            수정
          </button>
          <button onClick={() => setOpen({ visible: false })}>
            취소
          </button>
        </>
      }
    >

      <File>
        <div>
          <label htmlFor='file'><div />도면 등록</label>
          <p>{file ? file.name : '등록된 파일이 없습니다.'}</p>
        </div>
        { message &&
          <Message>{ message }</Message>
        }
        <input id='file' type='file' onChange={handleFile} />
      
        { form.state.drawingFileExists &&
          <p>
            이름 : { form.state.drawingFile }<br />
            크기 : { Math.ceil(form.state.drawingFileSize / 1024) } KB<br />
            등록일 : { form.state.createdAt }
          </p>
        }
      </File>

    </Dialog>
  );
}