import { useState, useDeferredValue, useEffect } from 'react';
// utils
import { REGEXP_NUMBER  } from '../../../utils/regexp';
// styles
import { ResultTd } from '../../styles';

// ----------------------------------------------------------------------

export default function ResultTableRow({ row, results, setResults }){

  const [ keyword, setKeyword ] = useState({
    xAxis: row.xaxis !== null ? String(row.xaxis) : '',
    yAxis: row.xaxis !== null ? String(row.yaxis) : '',
    zAxis: row.xaxis !== null ? String(row.zaxis) : '',
    remark: row.remark !== null ? row.remark : ''
  });
  const [ message, setMessage ] = useState({ xAxis: null, yAxis: null, zAxis: null, remark: null });

  const answer = useDeferredValue(keyword);

  useEffect(() => {
    const xAxis = answer.xAxis.length > 0 ? answer.xAxis : null;
    const yAxis = answer.yAxis.length > 0 ? answer.yAxis : null;
    const zAxis = answer.zAxis.length > 0 ? answer.zAxis : null;
    const remark = answer.remark.length > 0 && answer.remark.replace(/ /g, '').length !== 0 ? answer.remark : null;
    setResults({...results, [row.displacementPointId]: { xAxis: xAxis, yAxis: yAxis, zAxis: zAxis, remark: remark }});
    // eslint-disable-next-line
  }, [answer]);

  return (
    <tr>
      <td>{row.number}</td>
      <td>{row.pipeSystemName}</td>
      <td>{row.pipeLineName}</td>
      <td>{row.markNumber}</td>
      <td>{row.xaxisDesign}</td>
      <td>{row.yaxisDesign}</td>
      <td>{row.zaxisDesign}</td>
      <ResultTd>
        <input
          type='text'
          value={keyword.xAxis}
          onChange={(event) => {
            const value = event.target.value.replace(REGEXP_NUMBER, '');
            if ( value.split('.').length > 2 ) {
              setMessage({...message, xAxis: '숫자만 입력해 주세요.'});
            } else if ( value.split('.')[1] && value.split('.')[1].length > 1 ) {
              setMessage({...message, xAxis: '소수점 1자리까지만 입력 가능합니다.'});
            } else {
              setMessage({...message, xAxis: null});
              setKeyword({...keyword, xAxis: value});
            }
          }}
        />
        { message.xAxis &&
          <p>{ message.xAxis }</p>
        }
      </ResultTd>
      <ResultTd>
        <input
          type='text'
          value={keyword.yAxis}
          onChange={(event) => {
            const value = event.target.value.replace(REGEXP_NUMBER, '');
            if ( value.split('.').length > 2 ) {
              setMessage({...message, yAxis: '숫자만 입력해 주세요.'});
            } else if ( value.split('.')[1] && value.split('.')[1].length > 1 ) {
              setMessage({...message, yAxis: '소수점 1자리까지만 입력 가능합니다.'});
            } else {
              setMessage({...message, yAxis: null});
              setKeyword({...keyword, yAxis: value});
            }
          }}
        />
        { message.yAxis &&
          <p>{ message.yAxis }</p>
        }
      </ResultTd>
      <ResultTd>
        <input
          type='text'
          value={keyword.zAxis}
          onChange={(event) => {
            const value = event.target.value.replace(REGEXP_NUMBER, '');
            if ( value.split('.').length > 2 ) {
              setMessage({...message, zAxis: '숫자만 입력해 주세요.'});
            } else if ( value.split('.')[1] && value.split('.')[1].length > 1 ) {
              setMessage({...message, zAxis: '소수점 1자리까지만 입력 가능합니다.'});
            } else {
              setMessage({...message, zAxis: null});
              setKeyword({...keyword, zAxis: value});
            }
          }}
        />
        { message.zAxis &&
          <p>{ message.zAxis }</p>
        }
      </ResultTd>
      <ResultTd>
        <input
          type='text'
          value={keyword.remark}
          onChange={(event) => {
            if ( event.target.value.length > 30 ) {
              setMessage({...message, remark: '30자 이하로 입력해 주세요.'});
            } else {
              setMessage({...message, remark: null});
              setKeyword({...keyword, remark: event.target.value});
            }
          }}
        />
        { message.remark &&
          <p>{ message.remark }</p>
        }
      </ResultTd>
    </tr>
  );
}