import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

export const loggedin = createSlice({
  name: 'loggedin',
  initialState: null,
  reducers: {
    SET_LOGGEDIN: () => {
      return true;
    }
  }
});

export const { SET_LOGGEDIN } = loggedin.actions;

// ----------------------------------------------------------------------

export const token = createSlice({
  name: 'token',
  initialState: null,
  reducers: {
    SET_TOKEN: (state, action) => {
      return action.payload;
    },
    DELETE_TOKEN: () => {
      return null;
    }
  }
});

export const { SET_TOKEN, DELETE_TOKEN } = token.actions;

// ----------------------------------------------------------------------

export const account = createSlice({
  name: 'account',
  initialState: null,
  reducers: {
    SET_ACCOUNT: (state, action) => {
      return action.payload;
    },
    DELETE_ACCOUNT: () => {
      return null;
    }
  }
});

export const { SET_ACCOUNT, DELETE_ACCOUNT } = account.actions;

// ----------------------------------------------------------------------

export const unit = createSlice({
  name: 'unit',
  initialState: null,
  reducers: {
    SET_UNIT: (state, action) => {
      return action.payload;
    },
    DELETE_UNIT: () => {
      return null;
    }
  }
});

export const { SET_UNIT, DELETE_UNIT } = unit.actions;

// ----------------------------------------------------------------------

export const notify = createSlice({
  name: 'notify',
  initialState: null,
  reducers: {
    SET_NOTIFY: (state, action) => {
      return action.payload;
    },
    DELETE_NOTIFY: () => {
      return null;
    }
  }
});

export const { SET_NOTIFY, DELETE_NOTIFY } = notify.actions;

// ----------------------------------------------------------------------

export const loading = createSlice({
  name: 'loading',
  initialState: null,
  reducers: {
    SET_LOADING: () => {
      return true;
    },
    DELETE_LOADING: () => {
      return null;
    }
  }
});

export const { SET_LOADING, DELETE_LOADING } = loading.actions;