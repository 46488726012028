import PropTypes from 'prop-types';

import { Overlay, Wrapper, Title, Content, Actions } from './styles';

// ----------------------------------------------------------------------

Detailed.propTypes = {
  actions: PropTypes.element
};

// ----------------------------------------------------------------------

export default function Detailed({ details, actions, type }) {
  return (
    <Overlay>
      <Wrapper $detailed>
        <Title>상세 보기</Title>

        <Content>
          <div>
            <p>No. { details.number }</p>
            {/* <div>등록일 { details.number }</div> */}
          </div>

          <div className='fixed'>
            <div>
              <p><span>System</span><br />{ details.pipeSystemName }</p>
              <p><span>Line</span><br />{ details.pipeLineName }</p>
              { type !== 'analysis' &&
                <p><span>Type</span><br />{ details.pipeSupportType || details.pipeSupportTypeName }</p>
              }
              <p><span>Mark Number</span><br />{ details.markNumber }</p>
              <p><span>Model Size</span><br />{ details.modelSize }</p>
              <p><span>Indicator Type</span><br />{ details.indicatorType }</p>
            </div>
            <div>
              <p><span>Hot Load</span><br />{ details.designHotLoad }</p>
              <p><span>Cold Load</span><br />{ details.designColdLoad }</p>
              <p><span>Total Travel</span><br />{ details.totalTravel }</p>
              <p><span>Hot Position</span><br />{ details.hotPosition }</p>
              <p><span>Cold Position</span><br />{ details.coldPosition }</p>
              <p><span>Movement</span><br />{ details.movement }</p>
            </div>
          </div>

          { type !== 'analysis' &&
            <>
              <div>
                <div>
                  <p><span>X</span><br />{ details.xcoordinate }</p>
                  <p><span>Y</span><br />{ details.ycoordinate }</p>
                  <p><span>Z</span><br />{ details.zcoordinate }</p>
                </div>
                <div>
                  <p><span>Rotation X</span><br />{ details.xrotation }</p>
                  <p><span>Rotation Y</span><br />{ details.yrotation }</p>
                  <p><span>Rotation Z</span><br />{ details.zrotation }</p>
                </div>
              </div>
    
              { type === 'support' ? (
                <div>
                  <div>
                    <p><span>도면파일 이름</span><br />{ details.drawingFile ? details.drawingFile : '-' }{ details.drawingFile ? <span> ({ Math.ceil(details.drawingFileSize / 1024) } KB)</span> : '' }</p>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <p><span>Position</span><br />{ details.position ? details.position : '-' }</p>
                  </div>
                  <div>
                    <p><span>Remark</span><br />{ details.remark ? details.remark : '-' }</p>
                  </div>
                </div>
              )}
            </>
          }
        </Content>

        <Actions>
          <div>
            { actions }
          </div>
        </Actions>
      </Wrapper>
    </Overlay>
  );
}