import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getLoginLog = async (setData, startDate, endDate, { memberGroupId, name, email }, page, size) => {
  
  const start = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
  const end = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

  return await axios.get(`member-login-logs/search-page?start=${start} 00:00&end=${end} 23:59&memberGroupId=${memberGroupId}&name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getLoginLog;