import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const updateDrawing = async (pipeSupportId, file) => {
  const formData = new FormData();
  formData.append('drawingFile', file);
  
  return await axios.put(`pipe-supports/${pipeSupportId}/drawing`, formData)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateDrawing;