import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const createReport = async (unitId, title, file) => {
  const formData = new FormData();
  formData.append('info', new Blob([JSON.stringify({unitId, title})], {type: 'application/json'}));
  formData.append('reportFile', file);
  
  return await axios.post('report-managements', formData)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createReport;