import { useState, useEffect } from 'react';
// components
import SearchField from '../../components/search-field';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// apis
import { searchPlants } from '../../apis';

// ----------------------------------------------------------------------

export default function SearchPlants({ unit, setUnit }){

  const [ visible, setVisible ] = useState(false);

  const [ original, setOriginal ] = useState({ content: [], number: 0, last: true, empty: true });
  const [ plants, setPlants ] = useState({ content: [], number: 0, last: true, empty: true });
  const [ searchPlant, setSearchPlant ] = useState('');

  useEffect(() => {
    ( async () =>
      await searchPlants(searchPlant, plants.number)
        .then((response) => {
          setOriginal(response);
          setPlants(response);
          if (response.content.length > 0) {
            setUnit({id: response.content[0].unitId, name: `${response.content[0].plantName} ${response.content[0].unitName}`});
          }
        })
        .catch(() => {
          setOriginal({ content: [], number: 0, last: true, empty: true });
          setPlants({ content: [], number: 0, last: true, empty: true });
        })
    )();
    // eslint-disable-next-line
  }, []);

  const handleSearchPlants = async (more) => {
    await searchPlants(searchPlant, more? plants.number + 1 : 0)
      .then((response) => {
        if ( !response.first ) {
          const array = [ ...plants.content, ...response.content ];
          response.content = array;
          setOriginal(response);
        }
        setPlants(response);
      })
      .catch(() => {
        setPlants({ content: [], number: 0, last: true, empty: true });
      });
  };

  const handleInitialize = () => setPlants(original);
  
  return (
    <>
      <button id='plants' className='selected width' onClick={() => setVisible(true)} type='button' >{ unit.name ? unit.name : '발전소를 등록해주세요.' }</button>
      <Popover
        target='#plants'
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        wrapperAttr={{ class: 'search-popover' }}
      >
        <div>
          <SearchField
            value={searchPlant}
            onChange={setSearchPlant}
            onKeyDown={handleSearchPlants}
            onInitialize={handleInitialize}
          />
        </div>

        { plants.empty ? (
          <p>목록이 없습니다.</p>
        ) : (
          <>
            { plants.content.map((item) => (
              <button
                key={item.unitId}
                onClick={() => {
                  setVisible(false);
                  setUnit({id: item.unitId, name: `${item.plantName} ${item.unitName}`});
                }}
              >
                {item.plantName} {item.unitName}
              </button>
            )) }
            { !plants.last && <button onClick={() => handleSearchPlants(true)}>더보기</button>}
          </>
        )}
      </Popover>
    </>
  );
}