import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Background = styled.div(
  {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#DBF0FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: {
      fontSize: 18,
      padding: '85px 0 60px'
    },
    a: {
      display: 'block',
      backgroundColor: '#078DEE',
      width: 435,
      height: 46,
      borderRadius: 8,
      color: '#FFFFFF',
      fontSize: 16,
      textAlign: 'center',
      lineHeight: '46px'
    }
  }
);

export const Image = styled.div(
  {
    width: 320,
    height: 307,
    backgroundImage: 'url(/assets/error_404.svg)',
    backgroundSize: '100% auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
);