import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const createSystem = async (unitId, name, file) => {
  const formData = new FormData();
  formData.append('info', new Blob([JSON.stringify({unitId, name})], {type: 'application/json'}));
  formData.append('assetFile', file);

  return await axios.post('pipe-systems', formData)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default createSystem;