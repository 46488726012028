import { useState } from 'react';
// @devextreme
import { Popover } from 'devextreme-react/popover';

// ----------------------------------------------------------------------

export default function TableRow({ row, setDialog, setSupportForm, setDrawingForm, setDetailed }){

  const [ visible, setVisible ] = useState(false);

  return (
    <>
      <button id={`more${row.number}`} className='more' onClick={() => setVisible(true)} />

      <Popover
        target={`#more${row.number}`}
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        position='left'
      >
        <button onClick={() => {
          setVisible(false);
          setDetailed({visible: true, info: row});
        }}>
          상세 보기
        </button>
        <button onClick={() => {
          setVisible(false);
          setSupportForm({visible: true, type: '수정', state: row})
        }}>
          수정
        </button>
        <button onClick={() => {
          setVisible(false);
          setDialog({visible: true, type: '배관지지장치', id: row.pipeSupportId});
        }}>
          삭제
        </button>
        <button onClick={() => {
          setVisible(false);
          setDrawingForm({visible: true, state: row});
        }}>
          도면 수정
        </button>
        <button
          disabled={row.drawingFileExists ? false : true}
          onClick={() => {
            setVisible(false);
            setDialog({visible: true, type: '도면', id: row.pipeSupportId});
          }}
        >
          도면 삭제
        </button>
      </Popover>
    </>
  );
}