import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getSupport = async (setData, unitId, { system, line, type }, page, size) => {
  return await axios.get(`pipe-supports/search-page/unit/${unitId}?system=${encodeURIComponent(system)}&line=${encodeURIComponent(line)}&type=${encodeURIComponent(type)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getSupport;