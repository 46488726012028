import { useState, useEffect } from 'react';
// store
import { useSelector } from '../../../store';
// sections
import TableRow from './TableRow';
import Form from './Form';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, SearchWrapper } from '../../styles';
// apis
import { getMemberGroup, createMemberGroup, updateMemberGroup, deleteMemberGroup } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 20, 30];

// ----------------------------------------------------------------------

export default function MemberGroupPage() {

  const account = useSelector(state => state.account);

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ name, setName ] = useState('');
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);

  const [ dialog, setDialog ] = useState({ visible: false });
  const [ form, setForm ] = useState(false);

  useEffect(() => {
    ( async () =>
      await getMemberGroup(setData, name, page, size)
    )();
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    setPage(0);
    if ( name.length > 0 && name.replace(/ /g, '').length === 0 ) return;
    getMemberGroup(setData, name, 0, size);
  };

  const handlePage = (value) => {
    setPage(value-1);
    getMemberGroup(setData, name, value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getMemberGroup(setData, name, 0, Number(event.target.value));
  };

  const handleCreate = async (name) => {
    await createMemberGroup(name)
      .then(() => handleReset());
  };

  const handleUpdate = async (name) => {
    await updateMemberGroup(form.state.id, name)
      .then(() => handleReset());
  };

  const handleDelete = async () => {
    await deleteMemberGroup(dialog.id)
      .then(() => handleReset());
  };

  const handleReset = () => {
    setForm(false);
    setName('');
    setPage(0);
    getMemberGroup(setData, '', 0, size);
  };

  return (
    <Container>
      <Content>
        <Breadcrumbs
          heading='사용자 그룹 관리'
          links={[
            { name: '시스템 관리' },
            { name: '사용자 그룹 관리' }
          ]}
        />

        <Box $admin>
          <SearchWrapper>
            <SearchField
              value={name}
              onChange={setName}
              onKeyDown={handleSearch}
              onInitialize={handleSearch}
              placeholder='그룹명을 입력하세요.'
            />

            <div>
              <button className='register' onClick={() => setForm({type: 'create'})}>등록</button>
            </div>
          </SearchWrapper>

          <DataGrid
            dataSource={rows}
            noDataText=''
            paging={{enabled: false}}
            dataRowRender={({data}) => <TableRow row={data} account={account} setDialog={setDialog} setForm={setForm} />}
          >
            <Column caption='그룹명' dataField='name' alignment='center' />
            <Column caption='등록일' dataField='createdAt' alignment='center' />
            <Column />
          </DataGrid>

          <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

          { dialog.visible &&
            <Dialog
              title={'사용자 그룹 삭제'}
              actions={
                <>
                  <button onClick={() => {
                    setDialog({visible: false});
                    handleDelete();
                  }}>
                    삭제
                  </button>
                  <button onClick={() => setDialog({visible: false})}>
                    취소
                  </button>
                </>
              }
              type='confirm'
            >
              <p>선택하신 사용자 그룹에 소속된<br />모든 사용자도 삭제됩니다.</p>
              <p>삭제하시겠습니까?</p>
            </Dialog>
          }

          { form &&
            <Form data={form} setOpen={setForm} handleAction={form.state? handleUpdate : handleCreate} />
          }

        </Box>
      </Content>
    </Container>
  );
}