import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getMember = async (setData, { memberGroupId, name, email }, page, size) => {
  return await axios.get(`members/search-page?memberGroupId=${memberGroupId}&name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getMember;