import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// sections
import ResultTableRow from './ResultTableRow';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, ResultTop, ResultBottom, Message } from '../../styles';
// apis
import { getDisplacementResult, createDisplacementResult } from '../../../apis';

// ----------------------------------------------------------------------

export default function DisplacementResultPage() {

  const state = useLocation().state;
  const measurementId = state?.displacementMeasurementId;
  const text = state?.type === 'create' ? '등록' : '수정';
  const navigate  = useNavigate();

  const [ rows, setRows ] = useState([]);
  const [ results, setResults ] = useState({});

  const [ message, setMessage ] = useState(null);

  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    if ( !state ) return navigate(-1);
    ( async () =>
      await getDisplacementResult(setRows, setResults, measurementId)
    )();
    // eslint-disable-next-line
  }, []);

  const handleCreate = async () => {
    const result = Object.entries(results);
    let submit = true;
    const check = ['xAxis', 'yAxis', 'zAxis'];
    for (let i = 0; i < result.length; i++) {
      const num = [];
      for (let j = 0; j < check.length; j++) {
        if ( result[i][1][check[j]] !== null ) num.push(result[i][1][check[j]]);
      }
      if ( num.length !== 0 && num.length !== 3 ) {
        setMessage('X Axis, Y Axis, Z Axis 값은 모두 입력해주시거나, 모두 비워주셔야 합니다.');
        submit = false;
        setVisible(false);
        break;
      }
    }
    if ( submit === true ) {
      const measurementResults = result.map((item) => {
        return {displacementPointId: item[0], xAxis: item[1].xAxis, yAxis: item[1].yAxis, zAxis: item[1].zAxis, remark: item[1].remark}
      });
      await createDisplacementResult(measurementId, measurementResults)
        .then(() => navigate(-1))
        .catch((error) => {
          if ( error === 'ERR-351' ) setMessage('X Axis, Y Axis, Z Axis 값은 모두 입력해주시거나, 모두 비워주셔야 합니다.');
        });
    }
  };

  return (
    state &&
    <Container>
      <Content>

          <Breadcrumbs
            heading='변위 측정 결과'
            links={[
              { name: '데이터 관리' },
              { name: '변위 측정', link: '/data/displacement' },
              { name: '변위 측정 결과' }
            ]}
          />

          <Box $admin>
            <ResultTop>
              <p>{`${state.measurementCompany} - ${state.pipeStatus} (${state.measurementAt})`}</p>

              { message &&
                <Message $info>{ message }</Message>
              }
            </ResultTop>


            <DataGrid
              dataSource={rows}
              noDataText='변위 측정 위치를 등록해 주세요.'
              columnAutoWidth={true}
              paging={{enabled: false}}
              sorting={{mode: 'none'}}
              dataRowRender={({data}) => <ResultTableRow row={data} results={results} setResults={setResults} />}
            >
              <Column caption='No.' dataField='number' alignment='center' />
              <Column caption='System' dataField='pipeSystemName' alignment='center' />
              <Column caption='Line' dataField='pipeLineName' alignment='center' />
              <Column caption='Mark Number' dataField='markNumber' alignment='center' />
              <Column caption='X Axis Design' dataField='xaxisDesign' alignment='center' />
              <Column caption='Y Axis Design' dataField='yaxisDesign' alignment='center' />
              <Column caption='Z Axis Design' dataField='zaxisDesign' alignment='center' />
              <Column caption='X Axis' dataField='xaxis' alignment='center' />
              <Column caption='Y Axis' dataField='yaxis' alignment='center' />
              <Column caption='Z Axis' dataField='zaxis' alignment='center' />
              <Column caption='Remark' dataField='remark' alignment='center' />
            </DataGrid>
    
            <ResultBottom>
              <button disabled={measurementId ? false : true} onClick={() => setVisible(true)}>적용</button>
              <button onClick={() => navigate(-1)}>취소</button>
            </ResultBottom>

            { visible &&
              <Dialog
                actions={
                  <>
                    <button onClick={() => handleCreate()}>
                      { text }
                    </button>
                    <button onClick={() => setVisible(false)}>
                      취소
                    </button>
                  </>
                }
              >
                <p>점검 결과를<br />{ text }하시겠습니까?</p>
              </Dialog>
            }

          </Box>
        </Content>
      </Container>
  );
}