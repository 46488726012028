import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const createSupportInspection = async (unitId, inspectionCompany, pipeStatus, inspectionAt) => {
  return await axios.post('pipe-support-inspection-managements', {unitId, inspectionCompany, pipeStatus, inspectionAt})
    .then((response) => {
      const location = response.headers.location.split('/');
      return location[location.length - 1];
    })
    .catch((error) => {
      if ( error.response.data.code !== 'ERR-285' ) {
        store.dispatch( SET_NOTIFY({
          message: message(error, 'support_inspection'),
          action: null
        }) );
      }
      return Promise.reject(error.response.data.code);
    });
};

export default createSupportInspection;