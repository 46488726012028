import { useState } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import TextField from '../../../components/text-field';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// styles
import { Check, File, Message } from '../../styles';
// apis
import { checkSupportType } from '../../../apis';

// ----------------------------------------------------------------------
  
  const schema = yup.object().shape({
    type: yup.string()
      .required()
  });

// ----------------------------------------------------------------------

export default function Form({ form, setOpen, handleAction }) {

  const {
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: form.state?.type || ''
    }
  });

  const [ checked, setChecked ] = useState(null);
  const [ checkedType, setCheckedType ] = useState(null);

  const [ message, setMessage ] = useState({file: null, check: null});

  const [ file, setFile ] = useState(null);

  const handleFile = (event) => {
    if ( event.target.files.length > 0 ) {
      const file = event.target.files[0];
      const name = file.name.split('.');
      const extension = name[name.length - 1];
      const maxSize = 100 * 1024 * 1024; // 100MB
      name.pop();
      if ( name.join('.').length > 100 ) {
        setFile(false);
        return setMessage({...message, file: '파일 이름은 100자 이내로 입력해 주세요.'});
      }
      if ( extension !== 'unity3d' ) {
        setFile(false);
        return setMessage({...message, file: 'unity3d 파일을 선택해 주세요.'});
      }
      if ( file.size > maxSize ) {
        setFile(false);
        return setMessage({...message, file: '100MB 이하의 파일을 선택해 주세요.'});
      }
      setFile(file);
      setMessage({...message, file: null});
    } else {
      setFile(null);
      setMessage({...message, file: null});
    }
  };

  const onSubmit = async (data, event) => {
    if ( event ) {
      // 중복 체크
      await checkSupportType(data.type)
        .then((response) => {
          setChecked(response);
          setCheckedType(data.type);
          setMessage({...message, check: response ? '이미 등록된 타입입니다.' : '사용하실 수 있는 타입입니다.'});
        })
        .catch(() => {
          setMessage({...message, check: '나중에 다시 시도해 주세요.'});
        });
    } else {
      if ( checked === null || checkedType !== data.type ) {
        setChecked(null);
        setMessage({...message, check: '중복 체크 후 진행하실 수 있습니다.'});
      } else {
        if ( form.type === '등록' && file === null ) {
          setMessage({...message, file: '파일을 선택해 주세요.'});
        } else if ( checked === false && file !== false ) {
          await handleAction(data.type, file)
            .catch(() => setMessage(`배관지지장치 타입을 ${form.type}할 수 없습니다.`));
        }
      }
    }
  };

  return (
    <Dialog
      title={`배관지지장치 타입 ${form.type}`}
      actions={
        <>
          <button onClick={() => handleSubmit((data, event) => onSubmit(data, event))()}>
            {form.type}
          </button>
          <button onClick={() => setOpen({ visible: false })}>
            취소
          </button>
        </>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Check>
          <TextField
            name='type'
            type='text'
            control={control}
            placeholder='배관지지장치 타입을 입력해 주세요.'
          />
          <button>중복 체크</button>
        </Check>

        { message.check &&
          <Message className={message.check === '사용하실 수 있는 타입입니다.' && 'check'}>{ message.check }</Message>
        }

        <File>
          <div>
            <label htmlFor='file'>에셋 번들 등록</label>
            <p>{file ? file.name : '등록된 파일이 없습니다.'}</p>
          </div>
          <input id='file' type='file' onChange={handleFile} />
        </File>

        { message.file &&
          <Message>{ message.file }</Message>
        }

        { form.type === '수정' &&
          <p>
            이름: { form.state.file.name }<br />
            크기: { Math.ceil(form.state.file.size / 1024) } KB<br />
            날짜: { form.state.file.at }
          </p>
        }
      </form>
    </Dialog>
  );
}