import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getInitializeInquiry = async (setResult, unitId, type, startDate, endDate) => {
  
  startDate.setDate(1);
  const start = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
  const last = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
  const end = new Date(last.getTime() - (last.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

  const request = type === 'inspection' ?
    `data-inquiry/initialize/unit/${unitId}/inspection?start=${start}&end=${end}`
    : `data-inquiry/initialize/unit/${unitId}/measurement?start=${start}&end=${end}`;

  return await axios.get(request)
    .then((response) => {
      if ( response.data.data[`${type}DataList`].length > 0 ) {
        const at = response.data.data[`${type}DataList`][0][`${type}Results`].map((item) => { return {date: item[`${type}At`], status: item.pipeStatus}});
        response.data.data[`${type}DataList`] = response.data.data[`${type}DataList`].map((result) => {
          let obj = {};
          for (let i = 0; i < at.length; i++) {
            const data = result[`${type}Results`].filter((item) => item[`${type}At`] === at[i].date);
            if ( type === 'inspection' ) {
              obj[`${at[i].date}_position`] = !data.length ? null : data[0].position;
              obj[`${at[i].date}_remark`] = !data.length ? null : data[0].remark;
            } else {
              obj[`${at[i].date}_xaxis`] = !data.length ? null : data[0].xaxis;
              obj[`${at[i].date}_yaxis`] = !data.length ? null : data[0].yaxis;
              obj[`${at[i].date}_zaxis`] = !data.length ? null : data[0].zaxis;
              obj[`${at[i].date}_remark`] = !data.length ? null : data[0].remark;
            }
          }
          return {...result, ...obj, result: at};
        });
      }
      setResult(response.data.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default getInitializeInquiry;