import { useState, useEffect } from 'react';
// store
import store, { SET_UNIT } from '../store';
// components
import SearchField from '../components/search-field';
// @devextreme
import { Popover } from 'devextreme-react/popover';
// styles
import { PlantSelect } from './styles';
// apis
import { searchAccountPlants } from '../apis';

// ----------------------------------------------------------------------

export default function SearchPlants({ disabled }){

  const [ visible, setVisible ] = useState(false);

  const [ unit, setUnit ] = useState({ id: null, name: null });
  const [ original, setOriginal ] = useState({ content: [], number: 0, last: true, empty: true });
  const [ plants, setPlants ] = useState({ content: [], number: 0, last: true, empty: true });
  const [ searchPlant, setSearchPlant ] = useState('');

  useEffect(() => {
    ( async () =>
      await searchAccountPlants(searchPlant, plants.number)
        .then((response) => {
          setOriginal(response);
          setPlants(response);
          if ( response.content.length > 0 ) setUnit({id: response.content[0].unitId, name: response.content[0].plant});
        })
        .catch(() => {
          setOriginal({ content: [], number: 0, last: true, empty: true });
          setPlants({ content: [], number: 0, last: true, empty: true });
        })
    )();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    store.dispatch( SET_UNIT(unit.id) );
  }, [unit]);

  const handleSearchPlants = async (more) => {
    await searchAccountPlants(searchPlant, more? plants.number + 1 : 0)
      .then((response) => {
        if ( !response.first ) {
          const array = [ ...plants.content, ...response.content ];
          response.content = array;
          setOriginal(response);
        }
        setPlants(response);
      })
      .catch(() => {
        setPlants({ content: [], number: 0, last: true, empty: true });
      });
  };

  const handleInitialize = () => setPlants(original);
  
  return (
    <>
      <PlantSelect id='plants' disabled={disabled} onClick={() => setVisible(true)} type='button' >{ unit.name ? unit.name : '발전소 목록이 없습니다.'}</PlantSelect>

      <Popover
        target='#plants'
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        wrapperAttr={{ class: 'search-popover' }}
      >
        <div>
          <SearchField
            value={searchPlant}
            onChange={setSearchPlant}
            onKeyDown={handleSearchPlants}
            onInitialize={handleInitialize}
          />
        </div>
        
        { plants.empty ? (
          <p>발전소 목록이 없습니다.</p>
        ) : (
          <>
            { plants.content.map((item) => (
              <button
                key={item.unitId}
                onClick={() => {
                  setVisible(false);
                  setUnit({id: item.unitId, name: item.plant});
                }}
              >
                {item.plant}
              </button>
            )) }
            { !plants.last && <button onClick={() => handleSearchPlants(true)}>더보기</button>}
          </>
        )}
      </Popover>
    </>
  );
}