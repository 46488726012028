import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div(
  {
    margin: '20px 0',
    '> p': {
      fontSize: 28,
      fontWeight: 'bold'
    },
    'ul': {
      display: 'flex',
      'li': {
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
      },
      'li > *': {
        color: '#999999'
      },
      'li:last-child > *': {
        color: '#212121'
      },
      'li::after': {
        content: '""',
        display: 'block',
        width: 6,
        height: 12,
        backgroundImage: 'url(/assets/pagination/arrow.png)',
        backgroundSize: '100% auto',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        margin: '0 8px'
      },
      'li:last-child::after': {
        display: 'none'
      }
    }
  }
);