import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @devextreme
import { Popover } from 'devextreme-react/popover';

// ----------------------------------------------------------------------

export default function TableRow({ row, setForm, setDialog }){

  const navigate  = useNavigate();

  const [ visible, setVisible ] = useState(false);

  return (
    <>
      <button id={`more${row.number}`} className='more' onClick={() => setVisible(true)} />

      <Popover
        target={`#more${row.number}`}
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        position='left'
      >
        <button onClick={() => {
          setVisible(false);
          setForm({visible: true, type: '수정', state: row})
        }}>
          수정
        </button>
        <button onClick={() => {
          setVisible(false);
          setDialog({visible: true, id: row.pipeSupportInspectionId});
        }}>
          삭제
        </button>
        <button onClick={() => {
          setVisible(false);
          navigate('create', {state: {
            type: 'update',
            pipeSupportInspectionId : row.pipeSupportInspectionId,
            inspectionCompany: row.inspectionCompany,
            pipeStatus: row.pipeStatus,
            inspectionAt: row.inspectionAt
          }});
        }}>
          결과 수정
        </button>
      </Popover>
    </>
  );
}