import { useState, useEffect } from 'react';
// sections
import TreeList from './TreeList';
// @react-pdf
import { Document, Page, pdfjs } from 'react-pdf';
// styles
import { Container, Content, Box, DrawingTable, Message } from '../../styles';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

// ----------------------------------------------------------------------

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

// ----------------------------------------------------------------------

export default function DrawingPage() {

  const [ drawing, setDrawing ] = useState({data: null, download: null});
  
  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    if ( drawing.data?.drawingFile && !drawing.download ) {
      setMessage('도면 파일을 찾을 수 없습니다.');
    } else if ( !drawing.data?.drawingFile ) {
      setMessage('도면 파일이 없습니다.');
    } else {
      setMessage(null);
    }
  }, [drawing]);
  
  const [ active, setActive ] = useState(false);

  return (
    <Container className={active ? 'active' : ''} $view>
      <TreeList
        setDrawing={setDrawing}
        setMessage={setMessage}
        active={active}
        setActive={setActive}
      />

      <Content>
        <Box>

          <DrawingTable>
            <thead>
              <tr>
                <th>No.</th>
                <th>System</th>
                <th>Line</th>
                <th>Mark Number</th>
                <th>Model Size</th>
                <th>Design Hot Load</th>
                <th>Design Cold Load</th>
                <th>Total Travel</th>
                <th>Hot Position</th>
                <th>Cold Position</th>
                <th>Movement</th>
              </tr>
            </thead>
            <tbody>
              { drawing.data ? (
                <tr>
                  <td>{ drawing.data.number }</td>
                  <td>{ drawing.data.pipeSystemName }</td>
                  <td>{ drawing.data.pipeLineName }</td>
                  <td>{ drawing.data.markNumber }</td>
                  <td>{ drawing.data.modelSize }</td>
                  <td>{ drawing.data.designHotLoad }</td>
                  <td>{ drawing.data.designColdLoad }</td>
                  <td>{ drawing.data.totalTravel }</td>
                  <td>{ drawing.data.hotPosition }</td>
                  <td>{ drawing.data.coldPosition }</td>
                  <td>{ drawing.data.movement }</td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={11}></td>
                </tr>
              )}
            </tbody>
          </DrawingTable>

          { message &&
            <Message $info>{message}</Message>
          }

          { drawing.download &&
            <Document
              file={drawing.download.url}
              error={<Message $info>도면 파일을 불러오지 못 했습니다.</Message>}
            >
              <Page pageNumber={1} />
            </Document>
          }

        </Box>
      </Content>
    </Container>
  );
}