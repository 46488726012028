import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Header = styled.header(
  {
    width: '100%',
    height: 90,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 50px',
    '> div:last-child': {
      display: 'flex'
    }
  }
);

export const Logo = styled.div(
  {
    width: 154,
    height: 38,
    backgroundImage: 'url(/assets/logo.svg)',
    backgroundSize: '100% auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
);

export const Navigation = styled.nav(
  {
    display: 'flex',
    '> a, > button': {
      marginLeft: 5,
      display: 'block',
      padding: '0 16px',
      height: 40,
      lineHeight: '40px',
      borderRadius: 8,
      fontSize: 16,
    },
    '> a:first-child': {
      marginLeft: 0
    },
    '> a:hover, > button:hover, .active': {
      backgroundColor: '#EBF5FD',
      color: '#078DEE'
    }
  }
);

export const Main = styled.main(
  {
    width: '100%',
    minHeight: 'calc(100% - 90px)',
    display: 'flex',
    alignItems: 'center'
  }
);

export const PlantSelect = styled.button(
  {
    width: 205,
    height: 40,
    borderRadius: 8,
    backgroundImage: 'url(/assets/select_arrow.png)',
    backgroundSize:' 12px 8px',
    backgroundPosition: 'right 10px center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'left',
    padding: '0 22px 0 10px',
    marginRight: 15,
    fontSize: 16
  }
);

export const Account = styled.button(
  {
    width: 150,
    height: 40,
    borderRadius: 8,
    backgroundColor: '#F5F5F5',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    div: {
      width: 18,
      height: 20,
      backgroundImage: 'url(/assets/nav/user.png)',
      backgroundSize:' 100% auto',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      marginRight: 10
    }
  }
);