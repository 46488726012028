import { Container } from './styles';
//
import PuffLoader from "react-spinners/PuffLoader";

// ----------------------------------------------------------------------

export default function Loaders() {
  return (
    <Container>
      <PuffLoader color='#078DEE' size={180} />
    </Container>
  );
};