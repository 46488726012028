import { useState } from 'react';
// @devextreme
import { Popover } from 'devextreme-react/popover';

// ----------------------------------------------------------------------

export default function TableRow({ row, setForm, setDialog }){

  const [ visible, setVisible ] = useState(false);

  return (
    <tr>
      <td>{row.type}</td>
      <td>{row.createdAt}</td>
      <td>
        <button id={`more${row.id}`} className='more' onClick={() => setVisible(true)} />

        <Popover
          target={`#more${row.id}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button onClick={() => {
            setVisible(false);
            setForm({
              visible: true,
              type: '수정',
              state: {id: row.id, type: row.type, file: { name: row.file, size: row.size, at: row.fileCreatedAt }}
            });
          }}>
            수정
          </button>
          <button onClick={() => {
            setVisible(false);
            setDialog({visible: true, id: row.id});
          }}>
            삭제
          </button>
        </Popover>
      </td>
    </tr>
  );
}