import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// store
import { useSelector } from '../../../store';
// sections
import TableRow from './TableRow';
import Form from './Form';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DateBox from 'devextreme-react/date-box';
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, SearchWrapper, Message } from '../../styles';
// apis
import { getSupportInspection, createSupportInspection, updateSupportInspection, deleteSupportInspection } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 30, 50];

// ----------------------------------------------------------------------

export default function SupportInspectionPage() {

  const navigate  = useNavigate();
  const unit = useSelector(state => state.unit);

  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate);
  defaultStartDate.setFullYear( defaultEndDate.getFullYear() - 2 );

  const [ disabled, setDisabled ] = useState(true);

  const [ startDate, setStartDate ] = useState(defaultStartDate);
  const [ endDate, setEndDate ] = useState(defaultEndDate);

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ status, setStatus ] = useState('all');

  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(30);

  const [ message, setMessage ] = useState(null);

  const [ form, setForm ] = useState({ visible: false });
  const [ dialog, setDialog ] = useState({ visible: false });

  useEffect(() => {
    if ( unit ) {
      ( async () =>
        await getSupportInspection(setData, unit, startDate, endDate, status, page, size)
      )();
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [unit]);

  const handleSearch = () => {
    let difference = Math.abs( startDate.getTime() - endDate.getTime() );
    difference = Math.ceil( difference / (1000 * 60 * 60 * 24) );

    if ( startDate > endDate ) return setMessage('시작일은 종료일 이전이어야 합니다.');
    if ( difference > 1094 ) return setMessage('3년 이내로 검색해주세요.');

    setPage(0);
    setMessage(null);
    getSupportInspection(setData, unit, startDate, endDate, status, 0, size);
  };

  const handlePage = (value) => {
    setPage(value-1);
    getSupportInspection(setData, unit, startDate, endDate, status, value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getSupportInspection(setData, unit, startDate, endDate, status, 0, Number(event.target.value));
  };

  const handleAction = async (data) => {
    if ( form.type === '등록' ) {
      const inspectionAt = new Date(data.date.getTime() - (data.date.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
      await createSupportInspection(unit, data.inspectionCompany, data.pipeStatus, inspectionAt)
        .then((pipeSupportInspectionId) => {
          navigate('create', {state: {
            type: 'create',
            pipeSupportInspectionId : pipeSupportInspectionId,
            inspectionCompany: data.inspectionCompany,
            pipeStatus: data.pipeStatus,
            inspectionAt: inspectionAt
          }});
        });
    } else {
      await updateSupportInspection(form.state.pipeSupportInspectionId, data.inspectionCompany, data.pipeStatus)
        .then(() => {
          setForm({ visible: false });
          handleReset();
        });
    }
  };

  const handleDelete = async () => {
    await deleteSupportInspection(dialog.id)
      .then(() => handleReset());
  };

  const handleReset = () => {
    setPage(0);
    getSupportInspection(setData, unit, startDate, endDate, status, 0, size);
  };

  return (
    <Container>
      <Content>

        <Breadcrumbs
          heading='배관지지장치 점검'
          links={[
            { name: '데이터 관리' },
            { name: '배관지지장치 점검' }
          ]}
        />

        <Box $admin>

          <SearchWrapper>
            { message &&
              <Message $info $search>{ message }</Message>
            }
            
            <div>
              <DateBox
                type='date'
                value={startDate}
                onValueChanged={(event) => setStartDate(event.value)}
                displayFormat='yyyy.MM'
                calendarOptions={{
                  maxZoomLevel: 'year',
                  minZoomLevel: 'century'
                }}
                openOnFieldClick={true}
              />
              <DateBox
                type='date'
                value={endDate}
                onValueChanged={(event) => setEndDate(event.value)}
                displayFormat='yyyy.MM'
                calendarOptions={{
                  maxZoomLevel: 'year',
                  minZoomLevel: 'century'
                }}
                openOnFieldClick={true}
              />
              <select disabled={disabled} defaultValue={status} onChange={(event) => setStatus(event.target.value)}>
                <option value='all'>전체 선택</option>
                <option value='HOT'>HOT</option>
                <option value='COLD'>COLD</option>
              </select>
              <button disabled={disabled} onClick={() => handleSearch()}>검색</button>
            </div>
            
            <div>
              <button className='register' disabled={disabled} onClick={() => setForm({visible: true, type: '등록'})} >등록</button>
            </div>
          </SearchWrapper>

          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
            showBorders={false}
          >
            <Column caption='번호' dataField='number' alignment='center' />
            <Column caption='배관 상태' dataField='pipeStatus' alignment='center' />
            <Column caption='점검 업체' dataField='inspectionCompany' alignment='center' />
            <Column caption='점검일' dataField='inspectionAt' alignment='center' />
            <Column caption='작성자' dataField='author' alignment='center' />
            <Column caption='' dataField='pipeSupportInspectionId' allowSorting={false}
              cellRender={({data}) => <TableRow row={data} setDialog={setDialog} setForm={setForm} />}
            />
          </DataGrid>

          <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

          { form.visible &&
            <Form form={form} setOpen={setForm} handleAction={handleAction} />
          }

          { dialog.visible &&
            <Dialog
              title='배관지지장치 점검 이력 삭제'
              type='confirm'
              actions={
                <>
                  <button onClick={() => {
                    setDialog({visible: false});
                    handleDelete();
                  }}>
                    삭제
                  </button>
                  <button onClick={() => setDialog({visible: false})}>
                    취소
                  </button>
                </>
              }
            >
              <p>선택하신 점검 이력을<br />삭제하시겠습니까?</p>
            </Dialog>
          }

        </Box>
      </Content>
    </Container>
  );
}