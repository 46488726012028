import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const deleteMemberGroup = async (id) => {
  return await axios.delete(`member-groups/${id}`)
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'member_group'),
        action: null
      }) );
    });
};

export default deleteMemberGroup;