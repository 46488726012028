import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @devextreme
import { Popover } from 'devextreme-react/popover';

// ----------------------------------------------------------------------

export default function TableRow({ row, account, setDialog, setForm }){
  const navigate = useNavigate();

  const [ visible, setVisible ] = useState(false);

  return (
    <tr>
      <td>{row.name}</td>
      <td>{row.createdAt}</td>
      <td>
        <button id={`more${row.id}`} className='more' onClick={() => setVisible(true)} />

        <Popover
          target={`#more${row.id}`}
          visible={visible}
          hideOnOutsideClick={true}
          onHiding={() => setVisible(false)}
          position='left'
        >
          <button onClick={() => {
            setForm({
              type: 'update',
              state: {
                id: row.id, name: row.name
              }
            })
            setVisible(false);
          }}>
            수정
          </button>
          <button
            disabled={account.memberGroupId === row.id ? true : false}
            onClick={() => {
              setDialog({visible: true, id: row.id });
              setVisible(false);
            }}
          >
            삭제
          </button>
          <button onClick={() => navigate('/system/member-group-plant', {state: {
            memberGroupId: row.id,
            memberGroupName: row.name
          }})}>
            발전소 권한
          </button>
        </Popover>
      </td>
    </tr>
  );
}