import { useState } from 'react';
// components
import { Dialog } from '../../../components/dialog';
import TextField from '../../../components/text-field';
import Select from '../../../components/select-box';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @devextreme
import Calendar from 'devextreme-react/calendar';

// ----------------------------------------------------------------------
  
const schema = yup.object().shape({
  measurementCompany: yup.string()
    .required()
    .max(50, '50자 이내로 입력해주세요.'),
  pipeStatus: yup.string()
    .required()
});

// ----------------------------------------------------------------------

export default function Form({ form, setOpen, handleAction }) {

  const {
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      measurementCompany: form.state?.measurementCompany || '',
      pipeStatus: form.state?.pipeStatus || 'HOT'
    }
  });

  const defaultDate = new Date();
  const [ date, setDate ] = useState( form.state? new Date(form.state.measurementAt) : defaultDate);

  const [ message, setMessage ] = useState(null);

  const onSubmit = async (data) => {
    data.date = date;
    await handleAction(data)
      .catch((error) => {
        if ( error === 'ERR-294' ) setMessage('요청하신 일자에 이력이 이미 등록되어 있습니다.');
      });
  };

  return (
    <Dialog
      title={`변위 측정 이력 ${form.type}`}
      actions={
        <>
          <button onClick={() => handleSubmit((event) => onSubmit(event))()}>
            { form.type }
          </button>
          <button onClick={() => setOpen({ visible: false })}>
            취소
          </button>
        </>
      }
      message={message}
    >
      <form>
        <TextField
          name='measurementCompany'
          type='text'
          control={control}
          placeholder='점검 업체를 입력해 주세요.'
        />

        <Select
          name='pipeStatus'
          control={control}
        >
          <option value='HOT'>HOT</option>
          <option value='COLD'>COLD</option>
        </Select>

        <Calendar
          value={date}
          disabled={form.type === '수정' ? true : false}
          onValueChanged={(event) => setDate(event.value)}
        />
      </form>
    </Dialog>
  );
}