import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// store
import { useSelector } from '../../../store';
// sections
import TableRow from './TableRow';
import SearchGroups from '../SearchGroups';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog, TempPassword } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, SearchWrapper } from '../../styles';
// apis
import { getMember, deleteMember, blockMember, unblockMember, unlockMember } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 30, 50];

// ----------------------------------------------------------------------

export default function MemberPage() {

  const location = useLocation();

  const account = useSelector(state => state.account);
  const admin = account.role === '관리자' ? true : false;

  const [ group, setGroup ] = useState({id: '', name: '전체 그룹'});

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ type, setType ] = useState('name');
  const [ keyword, setKeyword ] = useState('');
  
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(30);
  
  const [ dialog, setDialog ] = useState({ visible: false });
  const [ tempPassword, setTempPassword] = useState({ visible: false });

  useEffect(() => {
    ( async () =>
      await getMember(setData, search(), page, size)
    )();
    if ( location.state?.submit ) {
      const handleEvent = () => window.history.pushState(null, '', location.href);
      window.history.pushState( null, '', location.href );
      window.addEventListener('popstate', handleEvent);
      return () => window.removeEventListener('popstate', handleEvent);
    }
    // eslint-disable-next-line
  }, []);

  const search = (reset) => {
    if ( reset ) {
      return {
        memberGroupId : admin ? group.id : account.memberGroupId,
        name: '',
        email: ''
      }
    } else {
      return {
        memberGroupId : admin ? group.id : account.memberGroupId,
        name: type === 'name' ? keyword : '',
        email: type === 'email' ? keyword : ''
      }
    }
  };

  const handlePage = (value) => {
    setPage(value-1);
    getMember(setData, search(), value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getMember(setData, search(), 0, Number(event.target.value));
  };

  const handleSearch = () => {
    if ( keyword.length > 0 && keyword.replace(/ /g, '').length === 0 ) return;
    setPage(0);
    getMember(setData, search(), 0, size);
  };

  const handleAction = async () => {
    await action()
      .then(() => handleReset())
      .catch(() => {});
  };

  const action = async () => {
    if ( dialog.type === '삭제' ) {
      await deleteMember(dialog.id);
    } else if ( dialog.type === '활성화' ) {
      await unblockMember(dialog.id);
    } else if ( dialog.type === '비활성화' ) {
      await blockMember(dialog.id);
    } else if ( dialog.type === '차단 해제' ) {
      await unlockMember(dialog.id);
    }
  };

  const handleReset = () => {
    setKeyword('');
    setPage(0);
    getMember(setData, search(true), 0, size);
  };

  return (
    <Container>
      <Content>
        <Breadcrumbs
          heading='사용자 목록'
          links={[
            { name: '사용자 관리' },
            { name: '사용자 목록' }
          ]}
        />

        <Box $admin>

          <SearchWrapper>
            <div>
              { admin &&
                <SearchGroups group={group} setGroup={setGroup} />
              }

              <select defaultValue={type} onChange={(event) => setType(event.target.value)}>
                <option value='name'>이름</option>
                <option value='email'>이메일</option>
              </select>

              <SearchField
                value={keyword}
                onChange={setKeyword}
                onKeyDown={handleSearch}
                onInitialize={handleSearch}
                placeholder='검색어를 입력하세요.'
              />
            </div>

            <div>
              <Link to='create'>등록</Link>
            </div>
          </SearchWrapper>

          <DataGrid
            dataSource={rows}
            noDataText=''
            columnAutoWidth={true}
            paging={{enabled: false}}
            dataRowRender={({data}) => <TableRow row={data} account={account} setDialog={setDialog} setTempPassword={setTempPassword} />}
          >
            { admin &&
              <Column caption='사용자 그룹' dataField='groupName' alignment='center' />
            }
            <Column caption='이름' dataField='name' alignment='center' />
            <Column caption='이메일' dataField='email' alignment='center' />
            <Column caption='소속' dataField='affiliation' alignment='center' />
            <Column caption='권한' dataField='role' alignment='center' />
            <Column caption='계정 상태' dataField='denied' alignment='center' />
            <Column caption='로그인 차단' dataField='locked' alignment='center' />
            <Column caption='등록일' dataField='createdAt' alignment='center' />
            <Column />
          </DataGrid>

          <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

          { dialog.visible &&
            <Dialog
              title={`사용자 ${ dialog.type }`}
              actions={
                <>
                  <button onClick={() => {
                    setDialog({ visible: false });
                    handleAction();
                  }}>
                    { dialog.type }
                  </button>
                  <button onClick={() => setDialog({ visible: false })} >
                    취소
                  </button>
                </>
              }
              type={dialog.type === '삭제' && 'confirm'}
              pt={dialog.type !== '삭제' && '20px'}
            >
              <p>선택하신 사용자를<br />{ dialog.type }하시겠습니까?</p>
            </Dialog>
          }

          { tempPassword.visible &&
            <TempPassword
              actions={
                <button onClick={() => setTempPassword({ visible: false })} >
                  확인
                </button>
              }
              password={tempPassword.password}
            />
          }

        </Box>
      </Content>
    </Container>
  );
}