import axios from '../utils/axios';

// ----------------------------------------------------------------------

const searchAccountPlants = async (plantName, page) => {
  return await axios.get(`accounts/search-slice/plant?plantName=${encodeURIComponent(plantName)}&page=${page}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default searchAccountPlants;