import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getDisplacementPoint = async (setData, unitId, { system, line, markNumber }, page, size) => {
  return await axios.get(`displacement-points/search-page/unit/${unitId}?system=${encodeURIComponent(system)}&line=${encodeURIComponent(line)}&markNumber=${encodeURIComponent(markNumber)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getDisplacementPoint;