import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// sections
import TableRow from './TableRow';
import Form from './Form';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, ResultTop, SearchWrapper } from '../../styles';
// apis
import { getRegisteredPlant, createMemberGroupPlant, deleteMemberGroupPlant } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 20, 30];

// ----------------------------------------------------------------------

export default function MemberGroupPlantPage() {
  const state = useLocation().state;
  const { memberGroupId, memberGroupName } = state ? state : { memberGroupId: null, memberGroupName: null };
  const navigate  = useNavigate();

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ name, setName ] = useState('');
  
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(20);

  const [ dialog, setDialog ] = useState({ visible: false });

  const [ form, setForm ] = useState(false);

  useEffect(() => {
    if ( !state ) return navigate(-1);
    ( async () =>
      await getRegisteredPlant(setData, memberGroupId, name, page, size)
    )();
    // eslint-disable-next-line
  }, []);

  const handlePage = (value) => {
    setPage(value-1);
    getRegisteredPlant(setData, memberGroupId, name, value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getRegisteredPlant(setData, memberGroupId, name, 0, Number(event.target.value));
  };

  const handleSearch = () => {
    setPage(0);
    if ( name.length > 0 && name.replace(/ /g, '').length === 0 ) return;
    getRegisteredPlant(setData, memberGroupId, name, 0, size);
  };

  const handleCreate = (memberGroupPlants) => {
    if ( memberGroupPlants.length > 0 ) {
      createMemberGroupPlant(memberGroupId, memberGroupPlants)
        .then(() => handleReset());
    }
  };

  const handleDelete = async () => {
    await deleteMemberGroupPlant(dialog.id)
      .then(() => handleReset());
  };

  const handleReset = () => {
    setForm(false);
    setName('');
    setPage(0);
    getRegisteredPlant(setData, memberGroupId, '', 0, size);
  };

  return (
    state &&
      <Container>
        <Content>
          <Breadcrumbs
            heading='발전소 권한'
            links={[
              { name: '시스템 관리' },
              { name: '사용자 그룹 관리', link: '/member-group' },
              { name: '발전소 권한' }
            ]}
          />

          <Box $admin>

            <ResultTop>
              <p>{ memberGroupName }</p>
            </ResultTop>

            <SearchWrapper>
              <SearchField
                value={name}
                onChange={setName}
                onKeyDown={handleSearch}
                onInitialize={handleSearch}
                placeholder='발전소명을 입력하세요.'
              />
              
              <div>
                <button className='register' onClick={() => setForm(true)}>추가</button>
              </div>
            </SearchWrapper>

            <DataGrid
              dataSource={rows}
              noDataText=''
              paging={{enabled: false}}
              dataRowRender={({data}) => <TableRow row={data} setDialog={setDialog} />}
            >
              <Column caption='발전소명' dataField='plantName' alignment='center' />
              <Column caption='호기' dataField='unitName' alignment='center' />
              <Column caption='등록일' dataField='createdAt' alignment='center' />
              <Column />
            </DataGrid>

            <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

            { dialog.visible &&
              <Dialog
                title={'발전소 권한 삭제'}
                actions={
                  <>
                    <button onClick={() => {
                      setDialog({visible: false});
                      handleDelete();
                    }}>
                      삭제
                    </button>
                    <button onClick={() => setDialog({visible: false})}>
                      취소
                    </button>
                  </>
                }
                type='confirm'
              >
                <p>선택하신 발전소의 권한을<br />삭제하시겠습니까?</p>
              </Dialog>
            }

            { form &&
              <Form setOpen={setForm} memberGroupId={memberGroupId} handleCreate={handleCreate} />
            }

          </Box>
        </Content>
      </Container>
  );
}