import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const deleteSupportInspection = async (pipeSupportInspectionId) => {
  return await axios.delete(`pipe-support-inspection-managements/${pipeSupportInspectionId}`)
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'support_inspection'),
        action: null
      }) );
    });
};

export default deleteSupportInspection;