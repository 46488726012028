import { createGlobalStyle } from 'styled-components';

// ----------------------------------------------------------------------

const GlobalStyles = createGlobalStyle({
  '*, :after, :before': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box'
  },
  '*': {
    color: '#212121',
    fontSize: 14,
    fontFamily: 'Spoqa Han Sans Neo, DXIcons, sans-serif'
  },
  'html, body, #root': {
    backgroundColor: '#FCFDFD',
    height: '100%'
  },
  'dl, li, menu, ol, ul': {
    listStyle: 'none'
  },
  'input, button, select, textarea': {
    fontFamily: 'inherit'
  },
  'input:focus, select:focus': {
    outline: 'none'
  },
  button: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent'
  },
  a: {
    textDecoration: 'none'
  },
  'input[type=checkbox]': {
    display: 'none'
  },
  'input[type=checkbox] + label': {
    display: 'flex'
  },
  'input[type=checkbox] + label > span': {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingLeft: 8
  },
  'input[type=checkbox] + label:before': {
    content: '""',
    display: 'block',
    width: 18,
    height: 18,
    border: '1px solid #DDDDDD',
    borderRadius: 3
  },
  'input[type=checkbox]:disabled + label:before': {
    backgroundColor: '#F6F6F6'
  },
  'input[type=checkbox]:checked + label:before': {
    borderColor: '#078DEE',
    backgroundColor: '#078DEE',
    backgroundImage: 'url(/assets/checked.png)',
    backgroundSize: '10px 7px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  },
  select: {
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    'appearance': 'none',
    backgroundImage: 'url(/assets/select_arrow.png)',
    backgroundSize: '12px 8px',
    backgroundPosition: 'right 14px center',
    backgroundRepeat: 'no-repeat',
    height: 42,
    border: '1px solid #E8E8E8',
    borderRadius: 8,
    padding: '0 26px 0 14px'
  },
  'select::-ms-expand': {
    display: 'none'
  },
  'form > div:first-child': {
    marginTop: 0
  },
  'button[type=submit]': {
    width: '100%',
    height: 42,
    borderRadius: 8,
    color: '#FFFFFF',
    backgroundColor: '#078DEE',
    fontSize: 16,
    marginTop: 30
  },
  'button.selected': {
    backgroundColor: 'transparent',
    height: 42,
    border: '1px solid #E8E8E8',
    borderRadius: 8,
    backgroundImage: 'url(/assets/select_arrow.png)',
    backgroundSize: '12px 8px',
    backgroundPosition: 'right 14px center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'left',
    padding: '0 26px 0 14px'
  },
  'button.more': {
    width: 34,
    height: 34,
    display: 'block',
    float: 'right',
    backgroundImage: 'url(/assets/more.png)',
    backgroundSize: '100% auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
});

export default GlobalStyles;