import PropTypes from 'prop-types';

import { Overlay, Wrapper, Title, Content, Actions, Icon } from './styles';

// ----------------------------------------------------------------------

TempPassword.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.element
};

// ----------------------------------------------------------------------

export default function TempPassword({ actions, password }) {
  return (
    <Overlay>
      <Wrapper>
        <Title>임시 비밀번호 발급</Title>

        <Content $password>
          <Icon $name='password' />

          <p>임시 비밀번호를 사용할 수 있습니다.</p>

          <div className='password'>{ password }</div>

          <p>이 번만 임시 비밀번호를 확인하실 수 있습니다.<br />하지만 언제든지 새 임시 비밀번호를 생성할 수 있습니다.</p>
        </Content>

        <Actions>
          <div>
            { actions }
          </div>
        </Actions>

      </Wrapper>
    </Overlay>
  );
}