import axios from '../utils/axios';
import message from '../../utils/message';

// ----------------------------------------------------------------------

const changePassword = async (currentPassword, newPassword, temp) => {
  const result = temp ? {tempPassword: currentPassword, newPassword} : {currentPassword: currentPassword, newPassword};
  return await axios.put(`accounts/change/${temp ? 'temp-' : ''}password`, result)
    .catch((error) => {
      return Promise.reject(
        new Error(message(error, 'password'))
      );
    });
};

export default changePassword;