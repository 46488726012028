import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const createDisplacementResult = async (measurementId, measurementResults) => {
  return await axios.post('displacement-measurement-result-managements', {measurementId, measurementResults})
    .catch((error) => {
      if ( error.response.data.code !== 'ERR-351' ) {
        store.dispatch( SET_NOTIFY({
          message: message(error, 'displacement'),
          action: null
        }) );
      }
      return Promise.reject(error.response.data.code);
    });
};

export default createDisplacementResult;