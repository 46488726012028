import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Main = styled.div(
  {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    backgroundImage: 'url(/assets/bg_main.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
);

export const Header = styled.header(
  {
    width: '100%',
    height: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 50px',
    div: {
      width: 146,
      height: 36,
      backgroundImage: 'url(/assets/logo_white.svg)',
      backgroundSize: '100% auto',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    },
    button: {
      width: 102,
      height: 44,
      border: '1px solid #FFFFFF',
      borderRadius: 8,
      backgroundColor: 'rgba(0, 0, 0, 0.45)',
      fontSize: 16,
      color: '#FFFFFF'
    }
  }
);

export const Text = styled.div(
  {
    width: '100%',
    textAlign: 'center',
    fontSize: 64,
    color: '#FFFFFF',
    span: {
      fontSize: 40,
      color: '#FFFFFF',
      fontWeight: 'bold'
    }
  }
);

export const Footer = styled.footer(
  {
    width: '100%',
    height: 160,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 70,
    color: '#999999',
    span: {
      color: '#666666'
    }
  }
);