import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getSupportProp = async (setProp, unitId) => {
  return await axios.get(`pipe-supports/search/unit/${unitId}/properties`)
    .then((response) => {
      setProp(response.data.data);
    })
    .catch(() => {});
};

export default getSupportProp;