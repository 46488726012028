import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '../../components/text-field';
// utils
import { REGEXP_PASSWORD  } from '../../utils/regexp';
// styles
import { Message } from '../styles';
// apis
import { changePassword, changePasswordExpiration, logout } from '../../apis';

// ----------------------------------------------------------------------

const schema = yup.object().shape({
  current: yup.string()
    .required()
    .min(8)
    .max(16)
    .matches(REGEXP_PASSWORD),
  new: yup.string()
    .required()
    .min(8, '8 - 16자 이내로 입력해주세요.')
    .max(16, '8 - 16자 이내로 입력해주세요.')
    .matches(REGEXP_PASSWORD, '비밀번호 형식을 확인해 주세요.'),
  confirm: yup.string()
    .required()
    .oneOf([yup.ref('new'), null], '비밀번호가 일치하지 않습니다.')
});

// ----------------------------------------------------------------------

export default function PasswordForm() {
  const navigate  = useNavigate();

  const account  = useLocation().pathname === '/account';
  const expiration = useLocation().state?.expiration;
  const temp = useLocation().state?.temp;

  const [ message, setMessage ] = useState(null);

  const {
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      current: '',
      new: '',
      confirm: ''
    }
  });

  const onError = (error) => {
    if ( error.current?.type === 'matches' ) setMessage('현재 비밀번호를 다시 한번 확인해 주세요.');
  };

  const onSubmit = (data) => {
    changePassword(data.current, data.new, temp)
      .then( async () => {
        alert('success');
        if ( account ) return navigate(-1);
        return await logout()
          .then(() => navigate('/login'));
      })
      .catch((error) => setMessage(error.message));
  };

  const handleNext = () => {
    changePasswordExpiration()
      .then(() => navigate('/dashboard'))
      .catch((error) => setMessage(error.message));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <TextField
        name='current'
        label='현재 비밀번호'
        type='text'
        control={control}
        $exception
      />

      <TextField
        name='new'
        label='신규 비밀번호'
        type='text'
        control={control}
      />
      
      <TextField
        name='confirm'
        label='신규 비밀번호 확인'
        type='text'
        control={control}
      />

      <Message $info>8-16자·영문·숫자·특수문자~!@#$%^&*()+|=</Message>
      
      { message &&
        <Message>{message}</Message>
      }

      <button type='submit'>확인</button>

      { expiration &&
        <button type='button' className='next' onClick={() => handleNext()}>다음에 변경</button>
      }
    </form>
  );
}