import { ThemeProvider as Theme } from 'styled-components';
import media from './media';
import GlobalStyles from './GlobalStyles';
//
import { locale } from 'devextreme/localization';
import 'devextreme/dist/css/dx.material.blue.light.css';
import './dx-styles.css';
import './font.css';

// ----------------------------------------------------------------------

export default function ThemeProvider({children}) {
  locale("ko");
  
  return (
    <Theme theme={{...media}}>
      <GlobalStyles />
      {children}
    </Theme>
  );
}