import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 40,
    '.pagination': {
      display: 'flex',
      li: {
        marginLeft: 5,
        '&:first-child': {
          marginLeft: 0
        }
      },
      a: {
        display: 'block',
        width: 36,
        height: 36,
        borderRadius: 5,
        textAlign: 'center',
        lineHeight: '34px',
        '&:hover': {
          color: '#078DEE',
          backgroundColor: '#EBF5FD'
        }
      },
      '.active a': {
        color: '#078DEE',
        border: '1px solid #078DEE'
      },
      '.arrow a': {
        backgroundImage: 'url(/assets/pagination/arrow.png)',
        backgroundSize: '8px 14px',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
      },
      '.double a': {
        backgroundImage: 'url(/assets/pagination/double_arrow.png)',
        backgroundSize: '16px 14px'
      },
      '.rotate a': {
        transform: 'rotate(180deg)' 
      }
    }
  },
  (props) => props.$center && ({
    justifyContent: 'center'
  })
);

export const RowsPerPage = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    p: {
      color: '#666666'
    },
    select: {
      width: 80,
      height: 36,
      marginLeft: 10
    }
  }
);