import axios from '../utils/axios';
import store, { persistor, DELETE_TOKEN, DELETE_ACCOUNT, DELETE_UNIT } from '../../store';

// ----------------------------------------------------------------------

const logout = async () => {
  return await axios.post('auth/logout')
    .then(() => {
      store.dispatch( DELETE_TOKEN() );
      store.dispatch( DELETE_ACCOUNT() );
      store.dispatch( DELETE_UNIT() );
      persistor.purge();
    })
    .catch(() => {});
};

export default logout;