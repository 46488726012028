import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getReport = async (setData, unitId, startDate, endDate, { title, author }, page, size) => {
  
  const start = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
  const end = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

  return await axios.get(`report-managements/search-page/${unitId}?start=${start} 00:00&end=${end} 23:59&title=${encodeURIComponent(title)}&author=${encodeURIComponent(author)}&page=${page}&size=${size}`)
    .then((response) => {
      setData(response.data.data);
    })
    .catch(() => {});
};

export default getReport;