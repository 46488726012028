import axios from 'axios';
import message from '../../utils/message';
import store, { SET_LOGGEDIN, SET_TOKEN } from '../../store';

// ----------------------------------------------------------------------

const login = async (email, password) => {
  return await axios.post('/api/auth/login', {email, password})
    .then((response) => {
      const token = response.data.data.token.accessToken;
      store.dispatch( SET_LOGGEDIN(true) );
      store.dispatch( SET_TOKEN(token) );
      
      return response.data.data;
    })
    .catch((error) => {
      return Promise.reject(
        new Error(message(error, 'login'))
      );
    });
};

export default login;