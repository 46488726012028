import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getPointProp = async (setProp, unitId) => {
  return await axios.get(`displacement-points/search/unit/${unitId}/properties`)
    .then((response) => {
      setProp(response.data.data);
    })
    .catch(() => {});
};

export default getPointProp;