import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const createMemberGroupPlant = async (memberGroupId, memberGroupPlants) => {
  return await axios.post('member-group-plants', {memberGroupId, memberGroupPlants})
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'member_group_plant'),
        action: null
      }) );
    });
};

export default createMemberGroupPlant;