import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div({
  width: '100%'
});

export const Label = styled.p(
  {
    fontSize: 13.5,
    paddingBottom: 3
  }
);

export const SelectBox = styled.select(
  {
    width: '100%',
    textOverflow: 'ellipsis'
  },
  (props) => props.$error && ({
    borderColor: '#FF5630'
  })
);