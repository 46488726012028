import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Wrapper = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    height: 42,
    border: '1px solid #E8E8E8',
    borderRadius: 8,
    backgroundImage: 'url(/assets/search.png)',
    backgroundSize: '24px 24px',
    backgroundPosition: 'right 10px center',
    backgroundRepeat: 'no-repeat',
    padding: '0 40px 0 14px',
    input: {
      backgroundColor: 'transparent',
      border: 'none',
      height: '100%',
      width: '100%',
      '&::placeholder': {
        color: '#CCCCCC'
      }
    },
    button: {
      backgroundColor: 'transparent',
      width: 24,
      height: 24,
      backgroundImage: 'url(/assets/input_delete.png)',
      backgroundSize: '24px 24px',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    }
  }
);