import axios from '../../utils/axios';
import message from '../../../utils/message';
import store, { SET_NOTIFY } from '../../../store';

// ----------------------------------------------------------------------

const deleteDisplacement = async (displacementMeasurementId) => {
  return await axios.delete(`displacement-measurement-managements/${displacementMeasurementId}`)
    .catch((error) => {
      store.dispatch( SET_NOTIFY({
        message: message(error, 'displacement'),
        action: null
      }) );
    });
};

export default deleteDisplacement;