import { useState, useEffect } from 'react';
// sections
import SearchPlants from '../SearchPlants';
import TableRow from './TableRow';
import SupportForm from './SupportForm';
import DrawingForm from './DrawingForm';
// components
import Breadcrumbs from '../../../components/breadcrumbs';
import SearchField from '../../../components/search-field';
import Pagination from '../../../components/pagination';
import { Dialog, Detailed } from '../../../components/dialog';
// @devextreme
import DataGrid, { Column } from 'devextreme-react/data-grid';
// styles
import { Container, Content, Box, SearchWrapper } from '../../styles';
// apis
import { getSupport, createSupport, updateSupport, updateDrawing, deleteDrawing, deleteSupport } from '../../../apis';

// ----------------------------------------------------------------------

const sizes = [10, 30, 50];

// ----------------------------------------------------------------------

export default function SupportPage() {

  const [ unit, setUnit ] = useState({id: null, name: ''});

  const [ disabled, setDisabled ] = useState(true);

  const [ data, setData ] = useState({ content: [], totalPages: 0 });
  const { content: rows, totalPages: pages } = data;

  const [ type, setType ] = useState('system');
  const [ keyword, setKeyword ] = useState('');
  
  const [ page, setPage ] = useState(0);
  const [ size, setSize ] = useState(30);

  const [ dialog, setDialog ] = useState({ visible: false });
  const [ detailed, setDetailed ] = useState({ visible: false });
  const [ supportForm, setSupportForm ] = useState({ visible: false });
  const [ drawingForm, setDrawingForm ] = useState({ visible: false });

  const search = (reset) => {
    if ( reset ) {
      return { system: '', line: '', type: '' };
    } else {
      return {
        system: type==='system'? keyword : '',
        line: type==='line'? keyword : '',
        type: type==='type'? keyword : ''
      };
    }
  };

  useEffect(() => {
    if ( unit.id ) {
      setPage(0);
      setKeyword('');
      setDisabled(false);
      ( async () =>
        await getSupport(setData, unit.id, search(true), 0, size)
      )();
    } else {
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [unit]);

  const handleSearch = async () => {
    setPage(0);
    if ( keyword.length > 0 && keyword.replace(/ /g, '').length === 0 ) return;
    await getSupport(setData, unit.id, search(), 0, size);
  };

  const handlePage = (value) => {
    setPage(value-1);
    getSupport(setData, unit.id, search(), value-1, size);
  };

  const handleSize = (event) => {
    setPage(0);
    setSize(Number(event.target.value));
    getSupport(setData, unit.id, search(), 0, Number(event.target.value));
  };

  const handleAction = async (fields, file) => {
    if ( supportForm.type === '등록' ) {
      await createSupport(fields, file)
        .then(() => {
          setSupportForm({ visible: false });
          handleReset()
        });
    } else {
      await updateSupport(supportForm.state.pipeSupportId, fields)
        .then(() => {
          setSupportForm({ visible: false });
          handleReset()
        });
    }
  };

  const handleUpdateDrawing = async (pipeSupportId, file) => {
    await updateDrawing(pipeSupportId, file)
      .then(() => {
        setDrawingForm({ visible: false });
        handleReset()
      });
  };

  const handleDelete = async () => {
    if ( dialog.type === '도면' ) {
      await deleteDrawing(dialog.id)
        .then(() => {
          setDrawingForm({ visible: false });
          setDialog({ visible: false });
          handleReset()
        });
    } else {
      await deleteSupport(dialog.id)
        .then(() => {
          setDialog({ visible: false });
          handleReset()
        });
    }
  };

  const handleReset = () => {
    setKeyword('');
    setPage(0);
    getSupport(setData, unit.id, search(true), 0, size);
  };

  return (
    <Container>
      <Content>
        <Breadcrumbs
          heading='배관지지장치 관리'
          links={[
            { name: '시스템 관리' },
            { name: '배관지지장치 관리' }
          ]}
        />

        <Box $admin>

          <SearchWrapper>
            <div>
              <SearchPlants unit={unit} setUnit={setUnit} />

              <select disabled={disabled} defaultValue={type} onChange={(event) => setType(event.target.value)}>
                <option value='system'>배관 시스템</option>
                <option value='line'>배관 라인</option>
                <option value='type'>배관지지장치 타입</option>
              </select>
              <SearchField
                value={keyword}
                onChange={setKeyword}
                onKeyDown={handleSearch}
                onInitialize={handleSearch}
                disabled={disabled}
                placeholder='검색어를 입력하세요.'
              />
            </div>
              
            <div>
              <button className='register' disabled={disabled} onClick={() => setSupportForm({visible: true, type: '등록'})}>등록</button>
            </div>
          </SearchWrapper>

        <DataGrid
          dataSource={rows}
          noDataText=''
          columnAutoWidth={true}
          paging={{enabled: false}}
        >
          <Column caption='NO.' dataField='number' alignment='center' />
          <Column caption='System' dataField='pipeSystemName' alignment='center' />
          <Column caption='Line' dataField='pipeLineName' alignment='center' />
          <Column caption='Type' dataField='pipeSupportTypeName' alignment='center' />
          <Column caption='Mark Number' dataField='markNumber' alignment='center' />
          <Column caption='Model Size' dataField='modelSize' alignment='center' />
          <Column caption='Hot Load' dataField='designHotLoad' alignment='center' />
          <Column caption='Cold Load' dataField='designColdLoad' alignment='center' />
          <Column caption='Total Travel' dataField='totalTravel' alignment='center' />
          <Column caption='Hot Position' dataField='hotPosition' alignment='center' />
          <Column caption='Cold Position' dataField='coldPosition' alignment='center' />
          <Column caption='Movement' dataField='movement' alignment='center' />
          <Column caption='Indicator Type' dataField='indicatorType' alignment='center' />
          <Column caption='도면' dataField='drawingFileExists' alignment='center' />
          <Column caption='등록일' dataField='createdAt' alignment='center' />
          <Column caption='' dataField='pipeSupportId' allowSorting={false}
            cellRender={({data}) => <TableRow row={data} setDialog={setDialog} setSupportForm={setSupportForm} setDrawingForm={setDrawingForm} setDetailed={setDetailed} />}
          />
        </DataGrid>

        <Pagination pages={pages} page={page} handlePage={handlePage} sizes={sizes} size={size} handleSize={handleSize} />

        { supportForm.visible &&
          <SupportForm form={supportForm} setOpen={setSupportForm} unit={unit} handleAction={handleAction} />
        }

        { drawingForm.visible &&
          <DrawingForm form={drawingForm} setOpen={setDrawingForm} handleAction={handleUpdateDrawing} />
        }

        { detailed.visible &&
          <Detailed
            details={detailed.info}
            actions={
              <button onClick={() => setDetailed({visible: false})}>
                확인
              </button>
            }
            type='support'
          />
        }

        { dialog.visible &&
          <Dialog
            title={`${dialog.type} 삭제`}
            actions={
              <>
                <button onClick={() => handleDelete()}>
                  삭제
                </button>
                <button onClick={() => setDialog({visible: false})}>
                  취소
                </button>
              </>
            }
            type='confirm'
          >
            <p>선택하신 { dialog.type === '도면' ? '배관지지장치의 도면을' : '배관지지장치를' }<br />삭제하시겠습니까?</p>
          </Dialog>
        }

        </Box>
      </Content>
    </Container>
  );
}