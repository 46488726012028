import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const updateSystem = async (id, name, file) => {
  const formData = new FormData();
  formData.append('info', new Blob([JSON.stringify({name})], {type: 'application/json'}));
  if ( file ) formData.append('assetFile', file);
  
  return await axios.put(`pipe-systems/${id}`, formData)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default updateSystem;