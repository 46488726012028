import { useState } from 'react';
// @devextreme
import { Popover } from 'devextreme-react/popover';

// ----------------------------------------------------------------------

export default function TableRow({ row, setForm, setDialog }){

  const line = row.pipeLineId ? true : false;
  const type = !line ? '시스템' : '라인';
  const id = !line ? 'pipeSystemId' : 'pipeLineId';

  const [ visible, setVisible ] = useState(false);

  return (
    <>
      <button id={`more${row.id}`} className='more' onClick={() => setVisible(true)} />

      <Popover
        target={`#more${row.id}`}
        visible={visible}
        hideOnOutsideClick={true}
        onHiding={() => setVisible(false)}
        position='left'
      >
        <button onClick={() => {
          setVisible(false);
          setForm({
            visible: true,
            target: type,
            type: '수정',
            state: type === '시스템'? {
              id: row[id], name: row.name, file: { name: row.file, size: row.size, at: row.fileCreatedAt }
            } : {
              id: row[id], name: row.name, systemId: row.systemId, systemName: row.systemName
            }
          });
        }}>
          수정
        </button>
        <button onClick={() => {
          setVisible(false);
          setDialog({visible: true, id: row[id], type: type });
        }}>
          삭제
        </button>
        { !line &&
          <button onClick={() => {
            setVisible(false);
            setForm({
              visible: true,
              target: '라인',
              type: '등록',
              state: {systemId: row.pipeSystemId, systemName: row.pipeSystemName}
            });
          }}>
            라인 등록
          </button>
        }
      </Popover>
    </>
  );
}