import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// store
import store, { useSelector, DELETE_NOTIFY } from '../store';
// @devextreme
import { Dialog } from '../components/dialog';
// apis
import { getAccount } from '../apis';

// ----------------------------------------------------------------------

const forbid = { 'data': 1, 'member': 2, 'system': 3 };
const role = { '사용자': 0, '매니저': 1, '마스터': 2, '관리자': 3 };

// ----------------------------------------------------------------------

export default function Boundary({ children }) {

  const navigate  = useNavigate();
  const pathname = useLocation().pathname.split('/')[1];

  const loggedin = useSelector(state => state.loggedin);
  const account = useSelector(state => state.account);
  const notify = useSelector(state => state.notify);

  useEffect(() => {
    if ( !loggedin ) return navigate('/');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( loggedin ) {
      if ( !account ) {
        ( async () =>
          await getAccount()
        )();
      } else if ( Object.keys(forbid).includes(pathname) && account && (forbid[pathname] > role[account.role] ) ) {
        return navigate(-1);
      }
    }
    // eslint-disable-next-line
  }, [account, pathname]);

  return (
    loggedin &&
    <>
      { notify && 
        <Dialog
          actions={
            <button onClick={() => {
              notify.action && navigate(notify.action);
              store.dispatch(DELETE_NOTIFY());
            }}>
              확인
            </button>
          }
        >
          { notify.message }
        </Dialog> 
      }

      { children }
    </>
  );
}