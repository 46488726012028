import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { Wrapper, Label, SelectBox } from './styles';

// ----------------------------------------------------------------------

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object
};

// ----------------------------------------------------------------------

export default function Select({ name, label, control, children, ...other }) {
  const {
    field,
    fieldState: {error}
  } = useController({
    name,
    control
  });

  return (
    <Wrapper>
      { label &&
        <Label>{label}</Label>
      }
      <SelectBox
        id={name}
        {...field}
        {...other}
        $error={ error? true : false }
      >
        { children }
      </SelectBox>
    </Wrapper>
  );
};