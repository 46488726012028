import { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
// store
import { useSelector } from '../../../store';
// sections
import TreeList from './TreeList';
import SearchDataLists from '../SearchDataLists';
// components
import { Detailed } from '../../../components/dialog';
// @devextreme
import DateBox from 'devextreme-react/date-box';
import Chart, { Series, CommonSeriesSettings, Point, ValueAxis, VisualRange, ConstantLine, Label, ArgumentAxis, Grid, Legend, Tooltip, Crosshair, HorizontalLine } from 'devextreme-react/chart';
import { exportWidgets } from 'devextreme/viz/export';
// styles
import { Container, Content, Box, SearchWrapper, IconButton, Message } from '../../styles';
// apis
import { getAnalysisResult } from '../../../apis';

// ----------------------------------------------------------------------

export default function AnalysisPage() {

  const location = useLocation();
  const [ state, setState ] = useState(location.state);

  const unit = useSelector(state => state.unit);

  const inspectionRef = useRef(null);
  const movementRef = useRef(null);
  const measurementRef = useRef(null);

  const defaultEndDate = new Date();
  const defaultStartDate = new Date(defaultEndDate);
  defaultStartDate.setFullYear( defaultEndDate.getFullYear() - 2 );

  const [ type, setType ] = useState(state ? state.type : 'inspection');
  const [ selected, setSelected ] = useState(null); // 선택한 지지장치 or 측정위치
  
  const [ data, setData ] = useState([]); // 이력

  const [ startDate, setStartDate ] = useState(defaultStartDate);
  const [ endDate, setEndDate ] = useState(defaultEndDate);

  const [ message, setMessage ] = useState(null);

  const [ form, setForm ] = useState({ visible: false });

  const [ search, setSearch ] = useState({ type: null, movement: null });
  const [ detailed, setDetailed ] = useState(null);
  const [ result, setResult ] = useState(null);
  const [ dataSource, setDataSource ] = useState(null);

  const [ dialog, setDialog ] = useState(false);
  
  const [ active, setActive ] = useState(false);
  
  const [ baseline, setBaseline ] = useState(true);

  useEffect(() => {
    let results = null;
    if ( search.type === 'inspection' ) {
      results = result.inspectionResults.map((item) => { return {...item, at: `${item.inspectionAt} (${item.pipeStatus})`} });
      let movement = null;
      if ( search.movement ) movement = [{ argument: 'movement', movement: detailed.movement }];
      if ( result.movementResults ) movement = [ ...movement, ...result.movementResults ];
      setDataSource({ result: results, movement: movement });
    } else if ( search.type === 'measurement' ) {
      results = result.map((item) => { return {...item, at: `${item.measurementAt} (${item.pipeStatus})`} });
      setDataSource(results);
    }
    // eslint-disable-next-line
  }, [result]);

  const handleSearch = () => {
    let difference = Math.abs( startDate.getTime() - endDate.getTime() );
    difference = Math.ceil( difference / (1000 * 60 * 60 * 24) );

    if ( startDate > endDate ) return setMessage('시작일은 종료일 이전이어야 합니다.');
    if ( difference > 1094 ) return setMessage('3년 이내로 검색해주세요.');

    setMessage(null);
    const state = { unitId: unit, startDate: startDate, endDate: endDate };
    setForm({visible: true, type: type, state: state });
  };

  const handleDoubleClick = () => handleSearchResult(search.id, '', search.movement);

  const handleSearchResult = async (id, at, movement) => {
    const typeId = type === 'inspection' ? 'pipeSupportId' : 'displacementPointId';
    setDetailed(data.filter((item) => item[typeId] === selected )[0]);
    await getAnalysisResult(setResult, type, selected, id, movement)
      .then(() => {
        setSearch({ type: type, movement: movement, id: id });
      })
      .catch(() => {
        setResult(null);
        setSearch({ type: null, movement: null, id: null });
      });
  };

  const customizeTooltip = (info, type) => {
    const data = info.point.data;
    if ( type === 'measurement' ) {
      return (
        <div style={{color: '#FFFFFF', fontSize: '12px'}}>
          at: {data.at}<br />
          xaxis: {data.xaxis}<br />
          yaxis: {data.yaxis}<br />
          zaxis: {data.zaxis}<br />
          remark: {data.remark ? data.remark : '-'}
        </div>
      );
    } else {
      return (
        <div style={{color: '#FFFFFF', fontSize: '12px'}}>
          at: {data.at}<br />
          position: {data.position}<br />
          remark: {data.remark ? data.remark : '-'}
        </div>
      );
    }
  };

  const customizePoint = useCallback(({data}) => {
    if ( data.argument === 'movement' ) {
      return { color: '#E8E8E8', hoverStyle: { color: '#E8E8E8' } };
    }
    return null;
  }, []);

  const handleDownload = () => {
    const date = new Date();
    const name = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}_${String(date.getHours()).padStart(2, '0')}_${ String(date.getMinutes()).padStart(2, '0')}_${String(date.getSeconds()).padStart(2, '0')}`;
    let data = [];
    if ( search.type === 'inspection' ) {
      data.push(inspectionRef.current.instance);
      if ( search.movement ) data.push(movementRef.current.instance);
    } else {
      data.push(measurementRef.current.instance);
    }
    exportWidgets([data], {
      fileName: `data-analysis-${name}`,
      format: 'PNG',
    });
  };

  return (
    <Container className={active ? 'active' : ''} $view>
      <TreeList
        state={state}
        setState={setState}
        unit={unit}
        type={type}
        selected={selected}
        setSelected={setSelected}
        setDetailedList={setData}
        setDetailed={setDetailed}
        startDate={startDate}
        endDate={endDate}
        setResult={setResult}
        setSearch={setSearch}
        handleDoubleClick={handleDoubleClick}
        setDialog={setDialog}
        active={active}
        setActive={setActive}
      />
      
      <Content>
        <Box>
          <SearchWrapper>
            { message &&
              <Message $info $search>{ message }</Message>
            }

            <div>
              <select value={type} onChange={(event) => setType(event.target.value)}>
                <option value='inspection'>점검 결과</option>
                <option value='measurement'>측정 결과</option>
              </select>

              <DateBox
                type='date'
                value={startDate}
                onValueChanged={(event) => setStartDate(event.value)}
                displayFormat='yyyy.MM'
                calendarOptions={{
                  maxZoomLevel: 'year',
                  minZoomLevel: 'century'
                }}
                openOnFieldClick={true}
              />
              <DateBox
                type='date'
                value={endDate}
                onValueChanged={(event) => setEndDate(event.value)}
                displayFormat='yyyy.MM'
                calendarOptions={{
                  maxZoomLevel: 'year',
                  minZoomLevel: 'century'
                }}
                openOnFieldClick={true}
              />
              <button disabled={selected ? false : true} onClick={() => handleSearch()}>검색</button>
            </div>

            <div>
              { search.type === 'inspection' &&
                <IconButton onClick={() => setBaseline(!baseline)} $baseline><div />설계 변위 기준</IconButton>
              }
              <IconButton onClick={() => handleDownload()}><div />다운로드</IconButton>
            </div>
          </SearchWrapper>

          { dataSource && search.type === 'inspection' &&
            <Chart ref={inspectionRef} dataSource={dataSource.result} >
              <Series type='bar' argumentField='at' valueField='position' cornerRadius={5} color='#F9B934' />
              <CommonSeriesSettings barWidth={40} hoverMode='none' cornerRadius={50} />
              <ValueAxis>
                <VisualRange startValue={detailed.coldPosition} endValue={detailed.hotPosition} />
                { baseline &&
                  <ConstantLine value={detailed.hotPosition} width={1} color='#FF5630' dashStyle='dash' paddingTopBottom={5}>
                    <Label text='HOT' font={{color: '#FF5630', weight: 600}} />
                  </ConstantLine>
                }
                { baseline &&
                  <ConstantLine value={detailed.coldPosition} width={1} color='#00B8D9' dashStyle='dash' paddingTopBottom={5}>
                    <Label text='COLD' font={{color: '#00B8D9', weight: 600}} />
                  </ConstantLine>
                }
              </ValueAxis>
              <Legend visible={false} />
              <Tooltip enabled={true} contentRender={(data) => customizeTooltip(data, 'inspection')} />
            </Chart>
          }

          { dataSource && search.type === 'inspection' && search.movement &&
            <>
              <p style={{padding: '30px 0 20px'}}>Movement</p>
              <Chart ref={movementRef} dataSource={dataSource.movement} customizePoint={customizePoint}>
                <Series type='bar' argumentField='argument' valueField='movement' cornerRadius={5} color='#F9B934' />
                <CommonSeriesSettings barWidth={40} hoverMode='none' />
                <Legend visible={false} />
                <Tooltip enabled={true} />
              </Chart>
            </>
          }

          { dataSource && search.type === 'measurement' &&
            <Chart ref={measurementRef} dataSource={dataSource}>
              <Series valueField='xaxis' name='X Axis' />
              <Series valueField='yaxis' name='Y Axis' />
              <Series valueField='zaxis' name='Z Axis' />
              <CommonSeriesSettings type='line' argumentField='at' hoverMode='none'>
                <Point visible={false} />
              </CommonSeriesSettings>
              <ArgumentAxis valueMarginsEnabled={false}>
                <Grid visible={true} />
              </ArgumentAxis>
              <Legend orientation='horizontal' hoverMode='none' />
              <Tooltip enabled={true} contentRender={(data) => customizeTooltip(data, 'measurement')} />
              <Crosshair enabled={true}>
                <HorizontalLine visible={false} />
              </Crosshair>
            </Chart>
          }

        </Box>
      </Content>
        
      { dialog &&
        <Detailed
          details={dialog}
          actions={
            <button onClick={() => {
              setDialog(false);
            }}>
              확인
            </button>
          }
          type='analysis'
        />
      }

      { form.visible &&
        <SearchDataLists form={form} setOpen={setForm} handleAction={handleSearchResult} target={'analysis'} />
      }

    </Container>
  );
}