import { useRoutes } from 'react-router-dom';
//
import Layout from '../layouts';
//
import MainPage from '../pages/MainPage';

import LoginPage from '../pages/auth-account/LoginPage';
import ChangePasswordPage from '../pages/auth-account/ChangePasswordPage';
import AccountPage from '../pages/auth-account/AccountPage';

// VIEW
import DashboardPage from '../pages/view/dashboard/DashboardPage';
import AnalysisPage from '../pages/view/data-analysis/AnalysisPage';
import InquiryPage from '../pages/view/data-inquiry/InquiryPage';
import DrawingPage from '../pages/view/drawing/DrawingPage';
import ReportPage from '../pages/view/report/ReportPage';

import SupportInspectionPage from '../pages/data/support-inspection/SupportInspectionPage';
import SupportInspectionResultPage from '../pages/data/support-inspection/SupportInspectionResultPage';
import DisplacementPage from '../pages/data/displacement/DisplacementPage';
import DisplacementResultPage from '../pages/data/displacement/DisplacementResultPage';
import ReportManagementPage from '../pages/data/report/ReportManagementPage';

import MemberPage from '../pages/member/member-list/MemberPage';
import MemberFormPage from '../pages/member/member-list/MemberFormPage';
import LoginLogPage from '../pages/member/login-log/LoginLogPage';

import MemberGroupPage from '../pages/system/member-group/MemberGroupPage';
import MemberGroupPlantPage from '../pages/system/member-group-plant/MemberGroupPlantPage';
import PlantPage from '../pages/system/plant/PlantPage';
import PipePage from '../pages/system/pipe/PipePage';
import SupportTypePage from '../pages/system/support-type/SupportTypePage';
import SupportPage from '../pages/system/support/SupportPage';
import DisplacementPointPage from '../pages/system/displacement-point/DisplacementPointPage';

//
import NotFound from '../pages/error/NotFound';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    { index: true, element: <MainPage /> },

    { path: 'login', element: <LoginPage /> },
    { path: 'change-password', element: <ChangePasswordPage /> },

    { element: <Layout />,
      children: [
        { path: 'account', element: <AccountPage /> },

        // VIEW
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'data-analysis', element: <AnalysisPage /> },
        { path: '/data-inquiry', element: <InquiryPage /> },
        { path: 'drawing', element: <DrawingPage /> },
        { path: 'report', element: <ReportPage /> },

        // 데이터 관리
        { path: 'data',
          children: [
            { path: 'support-inspection',
              children: [
                { index: true, element: <SupportInspectionPage /> },
                { path: 'create', element: <SupportInspectionResultPage /> }
              ]
            },
            { path: 'displacement',
              children: [
                { index: true, element: <DisplacementPage /> },
                { path: 'create', element: <DisplacementResultPage /> }
              ]
            },
            { path: 'report', element: <ReportManagementPage /> }
          ]
        },
        // 사용자 관리
        { path: 'member',
          children: [
            { path: 'member-list',
              children: [
                { index: true, element: <MemberPage /> },
                { path: 'create', element: <MemberFormPage /> },
                { path: 'update', element: <MemberFormPage /> },
              ]
            },
            { path: 'login-log', element: <LoginLogPage /> }
          ]
        },
        // 시스템 관리
        { path: 'system',
          children: [
            { path: 'member-group', element: <MemberGroupPage /> },
            { path: 'member-group-plant', element: <MemberGroupPlantPage /> },
            { path: 'plant', element: <PlantPage /> },
            { path: 'pipe', element: <PipePage /> },
            { path: 'support-type', element: <SupportTypePage /> },
            { path: 'support', element: <SupportPage /> },
            { path: 'displacement-point', element: <DisplacementPointPage /> }
          ]
        }
      ]
    },
    
    { path: '*', element: <NotFound /> }
  ]);

  return routes;
}