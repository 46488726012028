import { css } from 'styled-components';

// ----------------------------------------------------------------------

export const breakpoints = {
  sm: 767,
  md: 1199
};

// ----------------------------------------------------------------------

export default Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});