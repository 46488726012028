import styled from 'styled-components';

// ----------------------------------------------------------------------

export const Container = styled.div(
  {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  (props) => props.$view && ({
    [`${Content}`]: {
      transition: 'all .2s ease-in-out',
      paddingLeft: 430
    },
    '&.active': {
      [`${TreeWrapper}`]: {
        left: '-373px',
      },
      [`${Content}`]: {
        paddingLeft: 50
      },
    },
  }),
  (props) => props.$align && ({
    flexDirection: 'column',
    alignItems: 'center',
    [`${Box}`]: {
      width: 590,
      minHeight: 'auto',
      padding: '75px 125px'
    },
    [`${Logo}`]: {
      width: 200,
      height: 50,
      marginBottom: 60
    },
    [`${Title}`]: {
      textAlign: 'center',
      marginBottom: 50,
      '> p:first-child': {
        marginBottom: 10
      }
    },
    'button.next': {
      width: '100%',
      height: 42,
      color: '#078DEE',
      fontSize: 16,
      marginTop: 20
    }
  })
);

export const Content = styled.div(
  {
    width: '100%',
    minHeight: 'calc(100vh - 90px)',
    padding: '0 50px 50px'
  },
  (props) => props.$unity && ({
    [`${Box}`]: {
      overflow: 'hidden',
      height: '100%',
      padding: 0,
      iframe: {
        width: '100%',
        height: '100%',
        border: 'none'
      }
    }
  })
);

export const Box = styled.div(
  {
    width: '100%',
    minHeight: '100%',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 8px 18px rgba(0, 0, 0, 0.05)',
    padding: 40,
    border: '1px solid #F5F5F5',
    borderRadius: 30
  },
  (props) => props.$admin && ({
    minHeight: 'calc(100% - 99px)',
  }),
  (props) => props.$mb && ({
    'form > div': {
      marginTop: 20
    },
    'form > div:first-child': {
      marginTop: 0
    }
  })
);

export const Logo = styled.div(
  {
    width: 154,
    height: 38,
    backgroundImage: 'url(/assets/logo.svg)',
    backgroundSize: '100% auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
);

export const Title = styled.div(
  {
    marginBottom: 20,
    '> p': {
      fontSize: 16,
      color: '#666666'
    },
    '> p:first-child': {
      fontSize: 28,
      fontWeight: 'bold',
      color: '#212121'
    }
  }
);

export const Message = styled.p(
  {
    color: '#FF5630',
    fontSize: 13,
    lineHeight: '18px',
    marginTop: 10,
    '&:not(.check):before': {
      content: '""',
      display: 'inline-block',
      verticalAlign: 'top',
      width: 16,
      height: 18,
      marginRight: 5,
      backgroundImage: 'url(/assets/message_error.png)',
      backgroundSize: '16px 16px',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    },
    '&.check': {
      color: '#078DEE'
    }
  },
  (props) => props.$info && ({
    color: '#078DEE',
    '&:not(.check):before': {
      backgroundImage: 'url(/assets/message_info.png)',
    }
  }),
  (props) => props.$search && ({
    position: 'absolute',
    top: -22,
    margin: 0
  })
);

export const ScrollBar = styled.div`
  width: 100%;
  height: 100%;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(99, 115, 129, 0.3);
    border-radius: 10px;
    background-clip: padding-box;
    border: 8px solid transparent;
  },
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const TreeWrapper = styled.div(
  {
    transition: 'all .2s ease-in-out',
    width: 380,
    height: 'calc(100vh - 90px)',
    position: 'fixed',
    left: 0,
    bottom: 0,
    
    '> div > div:first-child': {
      width: '100%',
      padding: '0 12px',
      '> div:first-child': {
        display: 'flex',
        p: {
          padding: '0 5px',
          lineHeight: '42px'
        }
      },
      '> select, > button, > div:not(.dx-overlay)': {
        width: '100%',
        marginBottom: 10,
        backgroundColor: 'transparent'
      },
      '> button:not(#history)': {
        backgroundColor: '#078DEE',
        height: 42,
        borderRadius: 8,
        color: '#FFFFFF',
        fontSize: 16
      }
    },
    '> div:last-child': {
      width: 1,
      height: 'calc(100% - 50px)',
      backgroundColor: '#E8E8E8',
      position: 'absolute',
      top: 0,
      right: 0,
      button: {
        width: 10,
        height: 36,
        backgroundColor: '#fcfdfd',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundImage: 'url(/assets/drawer.png)',
        backgroundSize: '10px 18px',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
      }
    }
  }
);

export const SearchWrapper = styled.div(
  {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    position: 'relative',
    '> div': {
      display: 'flex',
      '> *:not(.dx-popover)': {
        marginLeft: 10
      },
      '> *:first-child': {
        marginLeft: 0
      },
      'select, .selected': {
        width: 130
      },
      '.width': {
        width: 190
      },
      '.dx-datebox': {
        width: 145
      },
      '.dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-filled .dx-texteditor-input, .dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-filled .dx-texteditor-input': {
        padding: '11px 16px 10px'
      },
      '&:nth-last-child(2) button:not(.selected, .delete), button.register, a': {
        width: 78,
        height: 42,
        borderRadius: 8,
        backgroundColor: '#078DEE',
        color: '#FFFFFF',
        fontSize: 16,
        textAlign: 'center',
        lineHeight: '42px'
      }
    }
  }
);

export const IconButton = styled.button(
  {
    display: 'flex',
    alignItems: 'center',
    color: '#078DEE',
    fontSize: 16,
    height: 42,
    padding: '0 20px',
    borderRadius: 8,
    border: '1px solid #078DEE',
    div: {
      width: 14,
      height: 14,
      backgroundImage: 'url(/assets/download.png)',
      backgroundSize: '100% auto',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      marginRight: 5
    }
  },
  (props) => props.$baseline && ({
    border: 'none',
    div: {
      width: 25,
      height: 8,
      backgroundImage: 'url(/assets/baseline.png)'
    }
  }),
  (props) => props.$table && ({
    float: 'right',
    padding: '0 15px',
    border: 'none',
    height: 34,
    fontSize: 14,
    backgroundColor: '#E3EDFF'
  })
);

export const MoreButton = styled.div(
  {
    width: '100%',
    textAlign: 'center',
    button: {
      width: 100,
      height: 42,
      backgroundColor: '#F1F3F6',
      borderRadius: 21,
      margin: '15px 0 20px'
    }
  }
);

export const DrawingIcon = styled.button(
  {
    width: 16,
    height: 16,
    backgroundImage: 'url(/assets/link.png)',
    backgroundSize: '100% auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
);

export const DrawingTable = styled.table(
  {
    width: '100%',
    borderSpacing: 0,
    marginBottom: 20,
    th: {
      height: 44,
      backgroundColor: '#F1F3F6',
      color: '#8CA0AE',
      fontSize: 13,
      fontWeight: 'normal',
      borderRight: '1px solid #D9E0E5',
      '&:first-child': {
        borderRadius: '8px 0 0 8px'
      },
      '&:last-child': {
        borderRadius: '0 8px 8px 0',
        borderRight: 'none'
      }
    },
    td: {
      height: 50,
      borderBottom: '1px solid #F0F0F0',
      textAlign: 'center'
    }
  }
);

export const ResultTop = styled.div(
  {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    'p:first-child': {
      fontSize: 20
    }
  }
);

export const ResultTd = styled.td(
  {
    position: 'relative',
    overflow: 'unset !important',
    input: {
      border: '1px solid #E8E8E8',
      borderRadius: 8,
      height: 36,
      textAlign: 'center'
    },
    p: {
      position: 'absolute',
      color: '#FF5630',
      fontSize: 12,
      backgroundColor: '#FFE7E1',
      borderRadius: 8,
      padding: '6px 10px',
      zIndex: 1,
      bottom: -25,
      left: '50%',
      transform: 'translate(-50%, 0)'
    }
  }
);

export const ResultBottom = styled.div(
  {
    marginTop: 30,
    textAlign: 'center',
    'button': {
      width: 100,
      height: 42,
      borderRadius: 8,
      backgroundColor: '#078DEE',
      color: '#FFFFFF',
      fontSize: 16
    },
    'button:last-child' : {
      backgroundColor: '#EBF5FD',
      color: '#078DEE',
      marginLeft: 10
    }
  }
);

export const TagField = styled.div(
  {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20,
    button: {
      margin: 2,
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      height: 32,
      borderRadius: 16,
      backgroundColor: '#EBF5FD',
      p: {
        color: '#078DEE',
        fontSize: 12
      },
      div: {
        width: 16,
        height: 17,
        backgroundImage: 'url(/assets/tag_delete.png)',
        backgroundSize: '100% auto',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        marginLeft: 10
      }
    }
  }
);

export const FileIcon = styled.div(
  {
    width: 30,
    height: 30,
    backgroundSize: '100% auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block'
  },
  (props) => ({
    backgroundImage: `url(/assets/file/${props.$name}.png)`
  })
);